import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import {Header} from "../header/Header";
import {faArrowLeft, faDownload, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AssessmentForm} from "../ui/assessments/AssessmentForm";
import {useForm} from "../hooks/useForm";
import axios from "axios";
import {zynity_api} from "../../config/external-routes";
import {useSelector} from "react-redux";
import {Loading} from "../ui/loading/Loading";
import Swal from "sweetalert2";
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer'
import {TeamAssessmentPdf} from "./pdf/TeamAssessmentPDF";
import moment from "moment";

export const TomorrowAssessment= () => {
    const authenticated_user = useSelector((state: any) => state.auth);
    const [loading, setLoading] = useState(true);
    const [assessmentData, setAssessmentData] = useState(null);
    const [totalScore, setTotalScore] = useState(0);
    const [pdfData, setPdfData] = useState({
        'user_name': "",
        "assessment": {
            "title": "Are You Ready For Tomorrow? - Assessment",
            "subtitle": "A brief assessment to provide insight about the strength of your team.",
            "instructions": "(Respond to each question using a 0 to 5 scale, with 10 being the highest rating).",
            "assessment_title": "",
            "total_score": totalScore,
            "data": [],
        }
    });

    const [formData, setFormDatum, setFormData]: any = useForm({})

    const fetchData = async () => {
        setLoading(true);
        console.log(authenticated_user);
        let assessment_url = zynity_api.users_assessments_tomorrow.replace('{user_id}', authenticated_user.id)
        const response = await axios.get(`${assessment_url}?team_id=0`);
        setTotalScore(response.data.assessment.score);
        setAssessmentData(response.data.assessment);

        let assessmentDataFromAPI = response.data.assessment.data;
        let initialFormAssessmentData = {};
        Object.keys(assessmentDataFromAPI).map((index: any) => {
            // @ts-ignore
            initialFormAssessmentData[assessmentDataFromAPI[index].field] = assessmentDataFromAPI[index].answer;
        })

        setFormData(initialFormAssessmentData);
        preparePdfData();
        setLoading(false);
    };

    useEffect(() => {
        fetchData()
    }, []);

    useEffect(() => {
        let total = 0;
        Object.keys(formData).map((field: any) => {
            if(field.substring(0,5) == 'reply') {
                total += parseInt(formData[field])
            }
        })
        setTotalScore(total);
        preparePdfData();
    }, [formData]);

    const submitAssessment = () => {
        let form_params = {};
        Object.keys(formData).map((field: any) => {
            if(field.substring(0,5) == 'reply') {
                // @ts-ignore
                form_params[field] = parseInt(formData[field]);
            }
        })
        // @ts-ignore
        form_params['notes'] = formData.notes;
        // @ts-ignore
        form_params['score'] = totalScore;
        axios.defaults.headers.common = {'Authorization': `Bearer ${authenticated_user.access_token}`}
        axios.put(`${zynity_api.assessments}/${assessmentData.id}`, form_params)
            .then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: response.data.message,
                })
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'We have problems updating the Coach!',
                })
            })
    }

    const preparePdfData = () => {
        setPdfData({...pdfData, ['user_name']: `${authenticated_user.first_name} ${authenticated_user.last_name}`, assessment: {...pdfData.assessment, ['total_score']: totalScore, ['data']: assessmentData}});
    }

    return (
        <>
            <Header/>
            <div className="container mt-4">
                <div className="row">
                    <div className="col-md-7 col-12">
                        <Link to="/leadership-assessment" className="linkComunity">
                            <button type="button" className="btn btn-success"><FontAwesomeIcon
                                icon={faArrowLeft}/> Assessments
                            </button>
                        </Link>

                        <h2 style={{fontWeight: '400'}} className='mt-2'>Are You Ready For <u>Tomorrow?</u></h2>
                        <p style={{color: 'teal'}}>Respond to each statement using a 0 to represent totally disagree and
                            5 to signify totally agree.</p>
                    </div>

                    <div className="col-md-5 col-12">
                        <div className="card">
                            <div className="card-body">
                                Assessment by: <span
                                style={{fontWeight: '500', fontSize: '20px'}}>{authenticated_user.first_name} {authenticated_user.last_name}</span>
                                <br/>
                                Team: (PERSONAL - not team related)
                            </div>
                        </div>

                        <div className='text-end mt-2'>
                            {
                                loading === false &&
                                <PDFDownloadLink className='zy-a' document={<TeamAssessmentPdf data={pdfData} formData={formData} totalScore={totalScore}/>}
                                                 fileName="zynity_assessment_tomorrow.pdf">
                                    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : (<><FontAwesomeIcon icon={faDownload}/> Download PDF</>))}
                                </PDFDownloadLink>
                            }
                        </div>
                    </div>
                </div>

                {
                    loading ? <Loading/> :
                        (
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-9">
                                                </div>
                                                <div className="col-3">
                                                    <div className='text-end'>
                                                        <button type="button" className="btn btn-success" onClick={submitAssessment}>Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                <AssessmentForm onChange={setFormDatum} values={formData}
                                                                data={assessmentData.data}/>
                                            }

                                            <div className="row mt-3">
                                                <div className="col-9">
                                                    TOTAL SCORE
                                                    <div className='mx-3 div-rounded'>{totalScore}</div>
                                                </div>
                                                <div className="col-3">
                                                    <div className='text-end'>
                                                        <button type="button" className="btn btn-success" onClick={submitAssessment}>Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    Questionnaire © Copyright 2018-{moment().year()} by Dwaine Canova All Rights Reserved
                                </div>
                                <div className="col-12 mt-3">
                                    <h5>All features are needed to create and sustain Exceptional Results!</h5>
                                </div>
                                <div className="col-6 mt-3">
                                    <h5>Instructions for use </h5>
                                    <div>1. <FontAwesomeIcon icon={faThumbsUp}/> Individuals should make notes about their responses.</div>
                                    <div>2. <FontAwesomeIcon icon={faThumbsUp}/> Low scored items are opportunities.</div>
                                    <div>3. <FontAwesomeIcon icon={faThumbsUp}/> High scored items are cause for shared celebrations.</div>
                                    <div>4. <FontAwesomeIcon icon={faThumbsUp}/> Individuals should answer these questions on their own.</div>
                                    <div>5. <FontAwesomeIcon icon={faThumbsUp}/> Teams should share their numbers (anonymously if desired) and discuss them.</div>
                                </div>
                                <div className="col-6 mt-3">
                                    <h5>Actions:</h5>
                                    <div>1. <FontAwesomeIcon icon={faThumbsUp}/> Jointly create Strategic Initiatives for opportunities to make needed improvements happen.</div>
                                    <div>2. <FontAwesomeIcon icon={faThumbsUp}/> Complete the assessment on a schedule basis of every 3 or 6 months.</div>
                                    <div>3. <FontAwesomeIcon icon={faThumbsUp}/> Chart the results for each individual question.</div>
                                    <div>4. <FontAwesomeIcon icon={faThumbsUp}/> Make and compare notes about your individual responses each time the assessments are made.</div>
                                    <div>5. <FontAwesomeIcon icon={faThumbsUp}/> Assess and make notes about how the ratings impact your personal career planning.</div>
                                </div>
                                <div className="col-12 pb-5">
                                    <h5>Expected Results:</h5>
                                    <p>It is another way of measuring and tracking accomplishments deserving of celebration. When an executive team engages with a Zynity certified coach and its web-based suites of apps, the ratings in the assessments improve over time. An essential outcome is an organization-wide environment committed to ongoing refining and improvement. When leadership concepts and principles are structured, adequately communicated, and understood,<b> individuals flourish.</b></p>
                                </div>
                            </div>
                        )
                }

            </div>
        </>
    )
}
