import React, {useState} from 'react'
// @ts-ignore
import CnAIcon from '../../../../../assets/components_icons/CnAIcon';
import {useSelector} from "react-redux";
import {Loading} from "../../../../ui/loading/Loading";
import {Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import {HeadlineModal} from "../../../../ui/tools/HeadlineModal";
import {zynity_api} from "../../../../../config/external-routes";
import Swal from "sweetalert2";
import axios from "axios";
import {getCnAData} from "../../../../../store/user_organizations/teams/tools/connect_and_align";
import {MonthSelector} from "./MonthSelector";
import {faEllipsisH, faEllipsisV, faQuestion} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {Select} from "../../../../ui/Select";
import Select from 'react-select';
import {SearchModal} from "../search/SearchModal";
import {SearchResults} from "../search/SearchResults";
import {GuideIframeModal} from "./GuideIframeModal";

interface TooltipProps {
    content: string;
    style?: React.CSSProperties; // Define style prop as optional
}

const Tooltip: React.FC<TooltipProps> = ({ content, style }) => {
    const defaultStyle: React.CSSProperties = {
        borderRadius: "8px",
        position: "absolute",
        zIndex: 1 // Update value to be a number instead of a string
    };

    if (window.innerWidth < 600) {
        defaultStyle.left = 0;
    }

    // Merge defaultStyle with style prop using spread operator
    const tooltipStyle: React.CSSProperties = { ...defaultStyle, ...style };

    return (
        <div className="card" style={tooltipStyle}>
            <div className="card-body"
                 dangerouslySetInnerHTML={{ __html: content }}
            ></div>
        </div>
    );
};

export const CnAHeader = ({setSelectedMember, selectedMember}:any) => {
    const authenticated_user = useSelector((state:any) => state.auth)
    const [showCreatingToMIsTooltip, setShowCreatingToMIsTooltip] = useState(false);
    const [showCnATooltip, setShowCnATooltip] = useState(false);
    const [showMoreSubItem, setShowMoreSubItem] = useState(false);
    const team_obj = useSelector((state: any) => state.organization_team);
    const [showCreateItemModal, setShowCreateItemModal] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [showGuideModal, setShowGuideModal] = useState(false);

    const [headlineModalSettings, setHeadlineModalSettings] = useState({
        title: 'Broadcast Message - to all team members',
        is_comment: false,
        new_record: true,
        labels: {
            title: 'Message',
            description: 'Story'
        }
    });

    const [headlineData, setHeadlineData] = useState({
        content_id: '',
        title: '',
        description: '',
        links: [],
        uploads: [],
    });

    if(team_obj.isLoading === true) {
        return <Loading />;
    }

    const handleCloseCreateCoachNoteModal = () => {
        setShowCreateItemModal(false)
    };

    const handleCloseSearchModal = () => {
        setShowSearchModal(false)
    };

    const headlineSubmit = async () => {
        const organization_id = team_obj.data.idOrganization;
        const team_id = team_obj.data.id;
        let url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);
        url += `/tools/CnA/create_broadcast`;
        let formData:any = headlineData;

        if(formData.title.trim() == '') {
            Swal.fire({
                icon: 'warning',
                text: 'Please add a note name'
            })
            return false;
        }

        await axios.post(`${url}`, formData)
            .then((response) => {
                setShowCreateItemModal(false)
                Swal.fire({
                    icon: 'success',
                    title: 'Message sent to all the members',
                })
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `We have problems sending your message, please try again!`,
                })
                console.log(error?.response?.data)
                throw error;
            })
    }


    let creatingToMIsHTML = `
                <p style="text-align:left; padding:4px; font-size:18px;"><b style="color:cadetblue;">Creating ToMIs™</b></p>
				<p style="margin-left:20px; color:#222; margin-bottom:8px;">
					<b style="color:#222;">Collaborating</b> means thinking/working together. Executive Teams must share a common
                                                    vision and clarify the big picture in which different opinions agree to work together for
                                                    the common good. In this way, ideas become innovations, and the company grows.
				</p>
				<p style="margin-left:20px; color:#222; margin-bottom:8px;">
					<b style="color:#222;">Change</b> is constant and ongoing. Collaboration is essential. Items needing fixing and new
                                                opportunities get identified daily. Staying current with what others are thinking about and
                                                knowing how others think makes working together easier, fun, and more effective.
                                                Embracing change is foundational for growing organizations.
				</p>
                <p style="margin-left:20px; color:#222; margin-bottom:8px;">
					<b style="color:#222;">Innovation</b> is essential to grow, adapt, and evolve. It begins with thinking in the box and
                                                        out of the box. Sharing your thinking and collaborating within an executive team is
                                                        essential.
				</p>
				<p style="text-align:center">
					<em style="color:#222;">
						He knows what I'm thinking.<br>
						I know what he's thinking.<br>
						It's really nice.<br>
					</em>
				</p>
				<p style="margin-left:20px; text-align:center">
					̶	<span style="font-size:10px; color:#000;">Tom Brady, NFL Quarterback</span>
				</p>
				<p style="text-align:right; margin:0 10px 10px;">
					<a href="${process.env.REACT_APP_ZYNITY_YII_URL}/resources/ExecutiveSuiteGuidance.pdf?v=4" target="_blank"><button class="btn btn-teal btn-sm rounded-pill">learn more</button></a>
				</p>`;

    let CnAToolTipHTML = `<b>"How one thinks, and what one thinks about, shapes and refines, what one does, and how well, they do it."</b><br>
					        <div style="text-align:right;">─Dwaine Canova</div>`;

    const handleSubMenuToggle = () => {
        setShowMoreSubItem(!showMoreSubItem);
    };

    const getAuthorInitials = (people_id: number) => {
        let author = team_obj?.data?.members?.find((member: { id: number; }) => (member.id === people_id))
        if(author === undefined) {
            return '--';
        }

        if(author.FirstName == '' || author.FirstName == undefined) {
            return author.EmailAddress.substring(0, 2).toUpperCase()
        }

        return `${author.FirstName.substring(0, 1)}${author.LastName.substring(0, 1)}`;
    }

    const getSelectMembersOption = () => {
        let team_options = team_obj.data.members.map((member:any) => {

            let know_as = member.KnownAs;
            if(know_as == '' || know_as == undefined) {
                know_as = member.EmailAddress.includes('@') ? member.EmailAddress.split('@')[0] : member.EmailAddress;
            }

            return {
                value: member.id,
                label: know_as,
                initials: getAuthorInitials(member.id),
            }
        })

        return [
            {
                value: 'team',
                label: 'Team',
                initials: 'T',
            },
            ...team_options
        ]
    }

    const formatMemberOptionLabel = ({ value, label, initials }:any) => (
        <div className='d-flex align-items-center' style={{width: '150px'}}>
            <div className="rounded-circle" style={{backgroundColor: 'teal', height: '25px', padding: '3px', width: '26px', color: 'white', display: 'inline', textAlign: 'center', fontSize: '12px', fontFamily: 'sans-serif'}}
            >
                {initials}
            </div>
            <div className='mx-1'>{label}</div>
        </div>
    );

    const handleSelectMemberChange = (option:any) => {
        setSelectedMember(option.value)
    };

    const getSelectMemberValue = () => {
        return getSelectMembersOption().find((option:any) => option.value == selectedMember)
    }

    const handleCloseGuideModal = () => {
        setShowGuideModal(false)
    }
    return (
        <div className="row">
            <div className="col-12">
                <nav className="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-center">
                    <ul className="navbar-nav d-flex align-items-center">
                        {/*<div onMouseOver={() =>  setShowCreatingToMIsTooltip(true)}
                             onMouseLeave={() => setShowCreatingToMIsTooltip(false)}>
                            <div className="nav-item mx-2 mt-2 mt-lg-0 badge bg-teal">
                                Creating ToMIs™
                            </div>
                            {showCreatingToMIsTooltip && (
                                <Tooltip style={{top: '20px', maxWidth: '400px'}} content={creatingToMIsHTML} />
                            )}
                        </div>*/}
                        <div className="nav-item  fw-bold text-muted" style={{marginTop: '-5px'}}>
                            Alignment
                        </div>

                        <div onMouseOver={() =>  setShowCnATooltip(true)}
                             onMouseLeave={() => setShowCnATooltip(false)}>
                            <div className="nav-item mx-2 mt-2 mt-lg-0 fw-bold text-muted">
                                <CnAIcon style={{ width: '30px', height: '100%'}} />
                                The Team Collab App<span>™</span>
                            </div>
                            {showCnATooltip && (
                                <Tooltip style={{top: '20px', maxWidth: '400px', backgroundColor: '#e4fdf7'}} content={CnAToolTipHTML} />
                            )}
                        </div>

                        <li className="nav-item mx-2 mt-2 mt-lg-0">
                            <Select
                                value={getSelectMemberValue()}
                                options={getSelectMembersOption()}
                                formatOptionLabel={formatMemberOptionLabel}
                                onChange={handleSelectMemberChange}
                                isSearchable={false}
                            />
                        </li>


                        <li className="nav-item mx-2 mt-2 mt-lg-0 rounded-circle alias_circle bg-teal">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdownMenuButton" className='remove-arrow'
                                                 style={{
                                                     background: 'none',
                                                     border: 'none',
                                                     boxShadow: 'none',
                                                     color: 'inherit',
                                                     padding: 0,
                                                     fontSize: 'inherit',
                                                 }}
                                >
                                    <FontAwesomeIcon className='clickable mt-1' style={{width: '27px', fontSize: '18px'}} icon={faEllipsisH}/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{width: '220px'}}>
                                    <Dropdown.Item onClick={() => setShowSearchModal(true)}>Search</Dropdown.Item>

                                    <SearchModal
                                        showCreateModal={showSearchModal}
                                        handleCloseModal={handleCloseSearchModal}
                                    />

                                    <MonthSelector />
                                    <Dropdown.Item onClick={() => setShowCreateItemModal(true)}>Broadcast Msg</Dropdown.Item>

                                    <HeadlineModal
                                        showCreateModal={showCreateItemModal}
                                        handleCloseModal={handleCloseCreateCoachNoteModal}
                                        data={headlineData}
                                        setData={setHeadlineData}
                                        submitHandled={headlineSubmit}
                                        settings={headlineModalSettings}
                                        showEmailMembers={false}
                                    />

                                    <Dropdown
                                        show={showMoreSubItem}
                                        onMouseEnter={handleSubMenuToggle}
                                        onMouseLeave={handleSubMenuToggle}
                                    >
                                        <Dropdown.Toggle
                                            as={Dropdown.Item}
                                            href="#"
                                            id="dropdownSubMenu"
                                        >
                                            Assessments
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item as={Link} to='/leadership-assessment/team'>Team</Dropdown.Item>
                                            <Dropdown.Item as={Link} to='/leadership-assessment/today'>Today</Dropdown.Item>
                                            <Dropdown.Item as={Link} to='/leadership-assessment/tomorrow'>Tomorrow</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>

                        {
                            [255, 579, 604].includes(authenticated_user.id) &&
                            <li className="nav-item mt-2 mt-lg-0">
                                <button style={{border: 'none', background:'none', backgroundColor: 'none'}}
                                        onClick={() => setShowGuideModal(true)}>
                                    <FontAwesomeIcon className='clickable mt-1' style={{width: '27px', fontSize: '18px'}} icon={faQuestion}/>
                                </button>

                                <GuideIframeModal showGuideModal={showGuideModal} handleCloseGuideModal={handleCloseGuideModal} />
                            </li>
                        }


                    </ul>
                </nav>
            </div>

            <div className="col-lg-10 offset-lg-1 col-12">
                <SearchResults />
            </div>
        </div>
    )
}
