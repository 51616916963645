export function getAuthorInitials(member_id:any, team: any)
{
    let author = team?.data?.members?.find((member: { id: number; }) => (member.id === member_id))
    if(author === undefined) {
        return '--';
    }

    if(author.FirstName == '' || author.FirstName == undefined) {
        return author.EmailAddress.substring(0, 2).toUpperCase()
    }

    return `${author.FirstName.substring(0, 1)}${author.LastName.substring(0, 1)}`;
}

export function getAuthorKnowAs(member_id: number, team: any)
{
    let author = team?.data?.members?.find((member: { id: number; }) => (member.id === member_id))
    if(author === undefined) {
        return 'Not Found';
    }

    let know_as = author.KnownAs;
    if(know_as == '' || know_as == undefined) {
        know_as = author.EmailAddress.includes('@') ? author.EmailAddress.split('@')[0] : author.EmailAddress;
    }

    return know_as;
}

export function cutText(text: string, maxLength: number = 40): string {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
}

// @ts-ignore
export function findDatumByDatumId(data:any, datum_id:number)
{
    for (let datum in data) {
        if (data[datum].id == datum_id) {
            return data[datum];
        } else if ((data[datum]?.sub_data != undefined && data[datum].sub_data.length > 0) || (data[datum]?.sub_data_archived != undefined && data[datum].sub_data_archived.length > 0)) {
            let sub_found:any = findDatumByDatumId(data[datum].sub_data, datum_id);
            if(sub_found != undefined) {
                return sub_found;
            } else if(data[datum].sub_data_archived.length > 0) {
                let sub_archived_found:any = findDatumByDatumId(data[datum].sub_data_archived, datum_id);
                if(sub_archived_found != undefined) {
                    return sub_archived_found;
                }
            }
        }
    }
    return undefined;
}

export function datumIsSubDatum(data:any, datum_id:number) {
    for (let datum in data) {
        if (data[datum].id === datum_id) {
            return false;
        } else if (data[datum]?.sub_data?.length > 0) {
            let sub_found:any = findDatumByDatumId(data[datum].sub_data, datum_id);
            if(sub_found != undefined) {
                return true;
            }
        }
    }
    return false;
}


// @ts-ignore
export function findDatumByDatumIdInAllTools(data_by_user:any, datum_id:number)
{
    let supported_tools = ['tomis', 'deliverables', 'activities', 'messages']
    for (let user_data in data_by_user) {
        for (let tool in supported_tools) {
            let user_id = data_by_user[user_data].user_id;
            let selected_tool = supported_tools[tool];
            let cna_data = data_by_user[user_data][supported_tools[tool]].data;
            let datum_found = findDatumByDatumId(cna_data, datum_id)

            if(datum_found != undefined) {
                return {
                    user_id: user_id,
                    selected_tool: selected_tool,
                    datum_found: datum_found,
                    is_sub_datum: datumIsSubDatum(cna_data, datum_id),
                }
            }
        }
    }
    return undefined;
}
