import React from 'react'
import {Header} from "../header/Header";
import {Link, useParams} from "react-router-dom";
import {zynity_website} from "../../config/external-routes";
export const Assessment = () => {
    const {organization_id} = useParams()
    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-4 d-flex justify-content-between align-items-center">
                    <div className="col-12 text-center">
                        <h2 style={{fontFamily: 'Segoe UI",Arial,sans-serif', fontWeight: '400'}}>Leadership Assessments</h2>
                        <div style={{width: '50%', height: '4px', backgroundColor: 'lightgreen', marginLeft: 'auto', marginRight: 'auto'}} />
                        <h5 style={{textAlign: 'left'}} className='mt-4'><b>Is it time to assess your Leadership team?</b></h5>
                        <h5 style={{textAlign: 'left', color: 'teal'}} className='mt-3'><em>Gain insight through these three brief assessments…</em></h5>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12 col-lg-4">
                        <div className="card" style={{backgroundColor: '#35b274', height: '100%'}}>
                            <div className="card-body my-4 mx-4 text-white d-flex align-items-center justify-content-center">
                                <div className='text-center'>
                                    <h4>How strong is your leadership TEAM?</h4>
                                    <Link to='/leadership-assessment/team' ><button type="button" className="btn btn-success" style={{fontWeight: 'bold'}}>START</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 mt-lg-0 mt-4">
                        <div className="card" style={{backgroundColor: '#35b274', height: '100%'}}>
                            <div className="card-body my-4 mx-4 text-white d-flex align-items-center justify-content-center">
                                <div className='text-center'>
                                    <h4>How are things TODAY?</h4>
                                    <Link to='/leadership-assessment/today' ><button type="button" className="btn btn-success" style={{fontWeight: 'bold'}}>START</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 mt-lg-0 mt-4">
                        <div className="card" style={{backgroundColor: '#35b274', height: '100%'}}>
                            <div className="card-body my-4 mx-4 text-white d-flex align-items-center justify-content-center">
                                <div className='text-center'>
                                    <h4>Are you ready for TOMORROW?</h4>
                                    <Link to='/leadership-assessment/tomorrow' ><button type="button" className="btn btn-success" style={{fontWeight: 'bold'}}>START</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-12">Assessing the alignment of the full team is a key first step.</div>
                    <div className="col-12 mt-2">Identifying the differences is enlightening.</div>
                    <div className="col-12 mt-2">Establishing a plan to minimize differences is essential.</div>
                </div>

                <div className="row mt-3">
                    <div className="col-12 text-center" style={{fontSize: '19px'}}>
                        Zynity professionals can help you through the complete process.
                        <a href={`${zynity_website.apps_site}/site/helpRequest`} className='zy-a' target='_blank'><b> Contact Us</b></a>
                    </div>
                </div>
            </div>
        </>
    )
}
