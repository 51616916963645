import React, {useContext} from 'react'
import {useAccordionButton} from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faPencilAlt, faBars} from "@fortawesome/free-solid-svg-icons";
import {faCommentDots} from "@fortawesome/free-regular-svg-icons";
import {collabSetRemember} from "../../../../store/user_organizations/teams/tools/connect_and_align";

export const _AccordionButton = ({ children, eventKey, coach_note, total_coach_notes, handleEditItem, handleSelectItem, handleDeleteItem, handleArchiveItem, handleActiveItem, handleMarkPrivateItem, handleAddComment, handleChangeListOrder, handleOpenReminder, readOnly, isSubItem, handleMarKNotificationsAsRead, clickNewItem, clickMoveItem, is_author}:any) => {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => {});
    const isOpened = activeEventKey.includes(eventKey);

    const clickOpenItemHandled = (event:any, itemId:any) => {
        decoratedOnClick(event)
        if(typeof handleSelectItem == 'function') {
            handleSelectItem(coach_note)
        }
    }

    const selectOptions = () => {

        const options = [];
        for (let i = 1; i <= total_coach_notes; i++) {
            options.push(<option value={i}>{i}</option>);
        }
        return <>{options}</>
    }

    return (
        <div className='d-flex justify-content-between pt-2 pb-2 mx-2'>
            <div className='d-flex align-items-start align-items-center'>
                {
                    (typeof handleChangeListOrder == 'function' && coach_note.isPrivate != 'Private') &&
                    (
                        readOnly ?
                            <span className='badge bg-secondary'>
                            {coach_note.listOrder}
                        </span>
                            :
                            <select aria-label="Default select example" style={{width: '100px;'}} onChange={(e) => handleChangeListOrder(coach_note.id, e.target.value )}>
                                {[...Array(total_coach_notes)].map((x, i) =>
                                    <option value={i+1} selected={i + 1 === coach_note.listOrder }>{i+1}</option>
                                )}
                            </select>
                    )
                }

                <span
                    className="custom-accordion-button"
                    onClick={(event) => clickOpenItemHandled(event, coach_note.id)}
                    style={{marginLeft: '5px'}}
                >
                    <span className='clickable'>{children}</span>
                    <FontAwesomeIcon className='mx-1 color-teal clickable' icon={isOpened ? faChevronUp : faChevronDown}/>
                </span>

                {
                    ((!readOnly || is_author == true) && typeof handleEditItem == 'function')  &&
                    <i className='mx-1 color-teal clickable' onClick={(event) => {handleEditItem( coach_note.id ); clickOpenItemHandled(event, coach_note.id)}} title='Edit'><FontAwesomeIcon icon={faPencilAlt}/></i>
                }

                {
                    typeof handleAddComment == 'function' &&
                    <i className='mx-1 color-teal clickable' onClick={(event) => {handleAddComment( coach_note.id ); clickOpenItemHandled(event, coach_note.id)}} title='Add Comment'><FontAwesomeIcon icon={faCommentDots}/></i>
                }

            </div>

            <div className='d-flex align-items-start align-items-center'>

                {
                    coach_note.isPrivate == 'Private' &&
                    <span
                          title={`Private ${coach_note.type1}`}
                          className="text-muted mx-2 p-0 mt-0 mb-0"
                    >
                        <i className="fa fa-lock"></i>
                    </span>
                }

                {
                    (!readOnly && coach_note.datumNotificationReminderComments != null) &&
                    <span data-toggle="tooltip"
                          onClick={() => {handleOpenReminder( coach_note.id )}}
                          title="See reminder"
                          className="badge badge-secondary"
                          style={{backgroundColor: 'teal', marginRight: '10px', cursor: 'pointer'}}>
                        <i className="fa fa-bell"></i>
                    </span>
                }

                {
                    (coach_note.total_notifications > 0) &&
                    <span data-toggle="tooltip"
                          className="badge badge-secondary"
                          style={{backgroundColor: 'rgb(178, 34, 34)', marginRight: '10px'}}>
                        {coach_note.total_notifications}
                    </span>
                }

                <div className="dropdown">
                    <span className="dropdown-toggle remove-arrow color-teal clickable" id="dropdownMenuButton1"
                          style={{border: 'solid 1px teal', padding: '2px', cursor: 'pointer'}}
                            data-bs-toggle="dropdown" aria-expanded="false">
                        <i className='mx-1'><FontAwesomeIcon icon={faBars}/></i>
                    </span>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        {
                            ((!readOnly || is_author == true) && typeof handleEditItem === 'function') && <li onClick={(event) => {handleEditItem( coach_note.id ); clickOpenItemHandled(event, coach_note.id)}}><a className="dropdown-item">Edit</a></li>
                        }
                        {
                            typeof handleAddComment === 'function' && <li onClick={(event) => {handleAddComment( coach_note.id ); clickOpenItemHandled(event, coach_note.id)}}><a className="dropdown-item">Comment</a></li>
                        }
                        {
                            (coach_note.total_notifications > 0 && typeof handleMarKNotificationsAsRead === 'function') && <li onClick={() => {handleMarKNotificationsAsRead( coach_note.id, 'newChange' )}}><a className="dropdown-item">Read</a></li>
                        }
                        {
                            (!readOnly && typeof handleOpenReminder === 'function') && <li onClick={() => {handleOpenReminder( coach_note.id )}}><a className="dropdown-item">Reminder</a></li>
                        }
                        {
                            (!readOnly && typeof clickMoveItem === 'function') && <li onClick={() => {clickMoveItem( coach_note.id )}}><a className="dropdown-item">Move</a></li>
                        }
                        {
                            (!readOnly && typeof clickNewItem === 'function' && coach_note.type1 != 'Message' && coach_note.type1 != 'CoachNote') && <li onClick={(event) => {clickNewItem( coach_note.type1, coach_note.id ); clickOpenItemHandled(event, coach_note.id)}}><a className="dropdown-item">Sub Item</a></li>
                        }
                        {
                            (!readOnly && typeof handleMarkPrivateItem === 'function') && <li onClick={() => {handleMarkPrivateItem( coach_note.id )}}><a className="dropdown-item">{coach_note.isPrivate == 'Private' ? 'Share' : 'Private'}</a></li>
                        }
                        {
                            (!readOnly && typeof handleArchiveItem === 'function') && <li onClick={() => {handleArchiveItem( coach_note.id )}}><a className="dropdown-item">Archive</a></li>
                        }
                        {
                            ( !readOnly && typeof handleActiveItem === 'function' ) && <li onClick={() => {handleActiveItem( coach_note.id )}}><a className="dropdown-item">Active</a></li>
                        }
                        {
                            ((!readOnly || is_author == true) && typeof handleDeleteItem === 'function') && <li onClick={() => {handleDeleteItem( coach_note.id )}}><a className="dropdown-item">Remove</a></li>
                        }
                    </ul>
                </div>
            </div>

        </div>
    );
}
