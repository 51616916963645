import React, {useEffect, useState} from 'react'
import axios from "axios";
import {zynity_api} from "../../../config/external-routes";
import FilterComponent from "../../ui/FilterComponent";
import {ActionDropdown} from "../ActionDropdown";
import {Header} from "../../header/Header";
import DataTable from "react-data-table-component";

export const CoachesManage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(15);
    const [filterText, setFilterText] = React.useState(null);

    const fetchData = async page => {
        setLoading(true);
        setCurrentPage(page);
        let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
        const response = await axios.get(`${zynity_api.admin_coaches}?page=${page}&limit=${perPage}${filter}`);

        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchData(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        setCurrentPage(page);
        let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
        const response = await axios.get(`${zynity_api.admin_coaches}?page=${page}&limit=${newPerPage}${filter}`);

        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            setFilterText('');
        };

        const handleFilter = (e) => {
            setFilterText(e.target.value)
        }

        return (
            <FilterComponent onFilter={handleFilter} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText]);

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            maxWidth: '10px'
        },
        {
            name: 'Date',
            selector: row => row.Date,
            maxWidth: '200px'
        },
        {
            name: 'Name',
            selector: row => `${row.FirstName} ${row.LastName}`,
        },
        {
            name: 'Digital Signature',
            selector: row => row.DigitalSignature,
            maxWidth: '200px'
        },
        {
            name: 'Agreement',
            selector: row => row.Agreement,
            maxWidth: '100px'
        },
        {
            name: 'Type',
            selector: row => row.Type,
            maxWidth: '400px'
        },
        {
            name: 'Actions',
            cell: row => <ActionDropdown
                linkView={`/admin/coaches/${row.id}`}
                linkEdit={`/admin/coaches-update/${row.id}`}
                linkDelete={`${zynity_api.admin_coaches}/${row.id}`}
                messageBeforeToDelete={`Do you want to delete the Coach: ${row.FirstName} (ID: ${row.id})?`}
                fetchData={fetchData}
                currentPage={currentPage} />,
            allowOverflow: true,
            button: true

        }
    ];

    useEffect(() => {
        fetchData(1)
    }, []);

    useEffect(() => {
        if(filterText !== null) {
            const timeOutId = setTimeout(() => fetchData(1), 500);
            return () => clearTimeout(timeOutId);
        }
    }, [filterText]);

    return (
        <>
            <Header/>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-3 user-main-tittle">
                        <h4>
                            Coaches Manage
                        </h4>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <DataTable
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationPerPage={perPage}
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
