import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import axios from 'axios'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faPen, faUser} from '@fortawesome/free-solid-svg-icons'
import {zynity_api, zynity_website} from '../../../config/external-routes';
import {Header} from '../../header/Header'
import {constants} from '../../../config/constants';
import './userProfile.scss'
import {time_zones} from '../../../config/timeZones';
import {useForm} from '../../hooks/useForm';
import Swal from 'sweetalert2';
import {getRequest} from "../../../helpers/getRequest";
import {Loading} from "../../ui/loading/Loading";
import {useParams} from "react-router-dom";

export const UserProfile = ({external_user = false, read_only = false}) => {
	const dispatch = useDispatch();
	const {user_id:param_user_id} = useParams();
	const authenticated_user = useSelector(state => state.auth)
	const [loading, setLoading] = useState(true);
	const [user_id, setUserId] = useState(param_user_id);
	const [readonly, setReadonly] = useState(read_only);
	const [user, setUser] = useState(authenticated_user);
	const yii_url = zynity_website.yii_website_url;

	let page_title = 'My Profile'
	if(external_user === true) {
		if(readonly) {
			page_title = 'User Details';
		} else {
			page_title = 'Edit User';
		}
	}
	document.title = `${page_title} | ${constants.website_title}`

	useEffect(() => {
		if (external_user === false) {
			setUserId(user.id)
			setLoading(false)
		} else {
			setLoading(true)
			getRequest(`${zynity_api.admin_users}/${param_user_id}`, user.access_token).then((response) => {
				setPersonalDataAllValues({
					"FirstName": response.FirstName,
					"LastName": response.LastName,
					"KnownAs": response.KnownAs,
					"LoginID": response.LoginID,
					"OrganizationName": response.OrganizationName,
					"EmailAddress": response.EmailAddress,
					"Phone": response.Phone,
					"CellPhone": response.CellPhone,
					"Address": response.Address,
					"Address2": response.Address2,
					"City": response.City,
					"State": response.State,
					"Country": response.Country,
					"TimeZone": response.TimeZone
				})
				setUserId(response.id)
				setLoading(false)
			})
		}
	}, [])

	console.log(user);

	const [formPersonalDataValues, setFormPersonalDataValues, setPersonalDataAllValues] = useForm({
		"FirstName": user.first_name,
		"LastName": user.last_name,
		"KnownAs": user.know_as,
		"LoginID": user.login_id,
		"OrganizationName": user.organization_name,
		"EmailAddress": user.email,
		"Phone": user.phone,
		"CellPhone": user.mobile_phone,
		"Address": user.address,
		"Address2": user.address_2,
		"City": user.city,
		"State": user.state,
		"Country": user.country,
		"TimeZone": user.time_zone
	})

	const [formChangePasswordValues, setFormChangePasswordValues, setFormChangesAllValues] = useForm({
		"password": "",
		"password_confirmation": ""
	})

	const {
		FirstName, LastName, KnownAs, LoginID, OrganizationName, EmailAddress, Phone, CellPhone, Address, Address2,
		City, State, Country, TimeZone
	} = formPersonalDataValues;
	const {password, password_confirmation} = formChangePasswordValues;


	const handleSubmitPersonalData = (event) => {
		event.preventDefault();

		let form = document.getElementById('personalDataForm');
		if (!form.checkValidity()) {
			form.classList.add('was-validated');
			return false;
		}

		sendUpdateProfileApi(formPersonalDataValues).then((data) => {
			Swal.fire({
				icon: 'success',
				title: 'Success!',
				text: 'Data updated',
			})

			form.classList.remove('was-validated');

		}).catch((error) => {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'We have problems updating your personal data!',
			})
		});
	}

	const handleSubmitPasswordChange = (event) => {
		event.preventDefault();

		let form = document.getElementById('changePasswordForm');
		if (!form.checkValidity()) {
			form.classList.add('was-validated');
			return false;
		}

		if (password.length < 6) {
			Swal.fire({
				icon: 'warning',
				title: 'Warning...',
				text: 'The password must be at least 6 characters.',
			})
			return false;
		}

		if (password_confirmation != password) {
			Swal.fire({
				icon: 'warning',
				title: 'Warning...',
				text: 'The confirmation password is different that your new password!',
			})
			return false;
		}

		sendUpdateProfileApi(formChangePasswordValues).then((data) => {
			Swal.fire({
				icon: 'success',
				title: 'Success!',
				text: data.message,
			})

			setFormChangesAllValues({
				"password": "",
				"password_confirmation": ""
			});

		}).catch((error) => {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'We have problems updating your password!',
			})
		});

	}

	const sendUpdateProfileApi = async (formData) => {
		axios.defaults.headers.common = {'Authorization': `Bearer ${user.access_token}`}

		let result = [];
		await axios.post(`${zynity_api.user_update_profile.replace('{user_id}', user_id)}`, formData)
			.then((response) => {
				console.log("data received", response)
				result = response.data;
			}).catch((error) => {
				throw error;
			})

		return result;
	}


	return (
		<>
			<Header/>
			<div className="container">
				<div className="row">
					<div className="col-12 col-md-3 user-main-tittle">
						<h4>
							{page_title} <i style={{color: '#0D7BAC'}}><FontAwesomeIcon icon={faUser}/></i>
						</h4>
					</div>
					<div className="col-12 col-md-9 mt-md-3">
						<a href={`${yii_url}/`}>
							<button className="theme-btn btn-style-one w-auto m-1">back to My Community</button>
						</a>
						{/*
						<a href={`${yii_url}/mystory/index`}>
							<button className="theme-btn btn-style-one w-auto m-1">My Story</button>
						</a>
						<a href={`${yii_url}/Development/index`}>
							<button className="theme-btn btn-style-one w-auto m-1">My Development</button>
						</a>
						*/}
					</div>
				</div>

				{
					loading ?
						(<Loading centerPage={true}/>)
						:
						<div className="row mt-4 d-flex justify-content-center">
							<div className="col-12 col-md-6">
								<div className="card">
									<div className="card-body">
										<div className="card-title row">
											<div className="col-6">
												<h5 className="card-title">Personal data</h5>
											</div>
											{
												readonly &&
												<div className="col-6 text-end">
													<FontAwesomeIcon onClick={() => setReadonly(false)}
																	 title="Edit User" icon={faPen}
																	 style={{
																		 fontSize: "16px",
																		 color: "#4c4c4c",
																		 cursor: "pointer"
																	 }}/>
												</div>
											}

										</div>

										<form id="personalDataForm" onSubmit={handleSubmitPersonalData} noValidate>
											<div className="row">
												<div className="col-12 col-md-6">
													<div className="mb-3">
														<label htmlFor="FirstName" className="form-label">First
															Name <span style={{color: 'red'}}>*</span></label>
														<input
															type="text"
															className="form-control"
															id="FirstName"
															name="FirstName"
															placeholder="Your Name"
															autoComplete="off"
															required
															value={FirstName}
															onChange={setFormPersonalDataValues}
															readOnly={readonly}
															disabled={readonly}
														/>
														<div className="invalid-feedback">
															Please add your First Name.
														</div>
													</div>
												</div>
												<div className="col-12 col-md-6">
													<div className="mb-3">
														<label htmlFor="LastName" className="form-label">Last Name <span
															style={{color: 'red'}}>*</span></label>
														<input
															type="text"
															className="form-control"
															id="LastName"
															name="LastName"
															placeholder="Your Last Name"
															autoComplete="off"
															required
															value={LastName}
															onChange={setFormPersonalDataValues}
															readOnly={readonly}
															disabled={readonly}
														/>
														<div className="invalid-feedback">
															Please add your Last Name.
														</div>
													</div>
												</div>
												<div className="col-12 col-md-6">
													<div className="mb-3">
														<label htmlFor="LastName" className="form-label">Screen Name<span
															style={{color: 'red'}}>*</span></label>
														<input
															type="text"
															className="form-control"
															id="KnownAs"
															name="KnownAs"
															placeholder="Your Last Name"
															autoComplete="off"
															required
															value={KnownAs}
															onChange={setFormPersonalDataValues}
															readOnly={readonly}
															disabled={readonly}
														/>
														<div className="invalid-feedback">
															Please add your Last Name.
														</div>
													</div>
												</div>
												<div className="col-12 col-md-6">
													<div className="mb-3">
														<label htmlFor="LastName" className="form-label">Username</label>
														<input
															type="text"
															className="form-control"
															id="LoginID"
															name="LoginID"
															placeholder="Your Last Name"
															autoComplete="off"
															value={LoginID}
															onChange={setFormPersonalDataValues}
															readOnly={readonly}
															disabled={readonly}
														/>
														<div className="invalid-feedback">
															Please add your Last Name.
														</div>
													</div>
												</div>
												<div className="col-12 col-md-6">
													<div className="mb-3">
														<label htmlFor="OrganizationName" className="form-label">Organization
															Name</label>
														<input
															type="text"
															className="form-control"
															id="OrganizationName"
															name="OrganizationName"
															placeholder="Organization Name"
															autoComplete="off"
															value={OrganizationName}
															onChange={setFormPersonalDataValues}
															readOnly={readonly}
															disabled={readonly}
														/>
													</div>
												</div>
												<div className="col-12 col-md-6">
													<div className="mb-3">
														<label htmlFor="EmailAddress" className="form-label">Email <span
															style={{color: 'red'}}>*</span></label>
														<input
															type="email"
															className="form-control"
															id="EmailAddress"
															name="EmailAddress"
															placeholder="e-mail"
															autoComplete="off"
															required
															value={EmailAddress}
															onChange={setFormPersonalDataValues}
															readOnly={readonly}
															disabled={readonly}
														/>
														<div className="invalid-feedback">
															Please add a valid E-mail.
														</div>
													</div>
												</div>
												<div className="col-12 col-md-6">
													<div className="mb-3">
														<label htmlFor="Phone" className="form-label">Phone</label>
														<input
															type="tel"
															className="form-control"
															id="Phone"
															name="Phone"
															placeholder="Phone Number"
															autoComplete="off"
															value={Phone}
															onChange={setFormPersonalDataValues}
															readOnly={readonly}
															disabled={readonly}
														/>
													</div>
												</div>
												<div className="col-12 col-md-6">
													<div className="mb-3">
														<label htmlFor="CellPhone" className="form-label">Mobile</label>
														<input
															type="tel"
															className="form-control"
															id="CellPhone"
															name="CellPhone"
															placeholder="Mobile Number"
															autoComplete="off"
															value={CellPhone}
															onChange={setFormPersonalDataValues}
															readOnly={readonly}
															disabled={readonly}
														/>
													</div>
												</div>
												<div className="col-12 col-md-6">
													<div className="mb-3">
														<label htmlFor="Address" className="form-label">Address</label>
														<input
															type="text"
															className="form-control"
															id="Address"
															name="Address"
															placeholder="Your Address"
															autoComplete="off"
															value={Address}
															onChange={setFormPersonalDataValues}
															readOnly={readonly}
															disabled={readonly}
														/>
													</div>
												</div>
												<div className="col-12 col-md-12">
													<div className="mb-3">
														<label htmlFor="Address2" className="form-label">Address
															2</label>
														<input
															type="text"
															className="form-control"
															id="Address2"
															name="Address2"
															placeholder="Another Address"
															autoComplete="off"
															value={Address2}
															onChange={setFormPersonalDataValues}
															readOnly={readonly}
															disabled={readonly}
														/>
													</div>
												</div>
												<div className="col-12 col-md-4">
													<div className="mb-3">
														<label htmlFor="City" className="form-label">City</label>
														<input
															type="text"
															className="form-control"
															id="City"
															name="City"
															placeholder="City"
															autoComplete="off"
															value={City}
															onChange={setFormPersonalDataValues}
															readOnly={readonly}
															disabled={readonly}
														/>
													</div>
												</div>
												<div className="col-12 col-md-4">
													<div className="mb-3">
														<label htmlFor="State" className="form-label">State</label>
														<input
															type="text"
															className="form-control"
															id="State"
															name="State"
															placeholder="State"
															autoComplete="off"
															value={State}
															onChange={setFormPersonalDataValues}
															readOnly={readonly}
															disabled={readonly}
														/>
													</div>
												</div>
												<div className="col-12 col-md-4">
													<div className="mb-3">
														<label htmlFor="Country" className="form-label">Country</label>
														<input
															type="text"
															className="form-control"
															id="Country"
															name="Country"
															placeholder="Country"
															autoComplete="off"
															value={Country}
															onChange={setFormPersonalDataValues}
															readOnly={readonly}
															disabled={readonly}
														/>
													</div>
												</div>
												<div className="col-12 col-md-8">
													<div className="mb-3">
														<label htmlFor="TimeZone" className="form-label">Time
															Zone</label>
														<select
															className="form-select"
															name="TimeZone"
															id="TimeZone"
															autoComplete="off"
															value={TimeZone}
															onChange={setFormPersonalDataValues}
															disabled={readonly}
														>
															<option value="">Select Time Zone</option>
															{
																Object.keys(time_zones).map(row => (
																	<option value={row}
																			key={row}>{time_zones[row]}</option>
																))
															}
														</select>
													</div>
												</div>
											</div>
										</form>
									</div>
									{
										readonly === false &&
										<div className="card-footer bg-transparent d-flex flex-row-reverse">
											<button type="submit" form="personalDataForm"
													className="btn btn-primary">Update
												Profile
											</button>
										</div>
									}
								</div>
							</div>

							{
								readonly === false &&
								<div className="col-12 col-md-6 mt-5 mt-md-0">
									<div className="card">
										<div className="card-body">
											<h5 className="card-title">Change Password</h5>
											<form id="changePasswordForm" onSubmit={handleSubmitPasswordChange}
												  noValidate>
												<div className="row">
													<div className="col-12 col-md-6">
														<div className="mb-3">
															<label htmlFor="password" className="form-label">New
																Password <span style={{color: 'red'}}>*</span></label>
															<input
																type="password"
																className="form-control"
																id="password"
																name="password"
																placeholder="Your New Password"
																required
																value={password}
																onChange={setFormChangePasswordValues}
															/>
															<div className="invalid-feedback">
																Please add a valid password.
															</div>
														</div>
													</div>
													<div className="col-12 col-md-6">
														<div className="mb-3">
															<label htmlFor="password_confirmation"
																   className="form-label">Confirm
																New Password <span
																	style={{color: 'red'}}>*</span></label>
															<input
																type="password"
																className="form-control"
																id="password_confirmation"
																name="password_confirmation"
																placeholder="Confirm New Password"
																required
																value={password_confirmation}
																onChange={setFormChangePasswordValues}
															/>
															<div className="invalid-feedback">
																Please add a valid password confirmation.
															</div>
														</div>
													</div>
												</div>
											</form>
										</div>
										<div className="card-footer bg-transparent d-flex flex-row-reverse">
											<button type="submit" form="changePasswordForm"
													className="btn btn-primary">Change Password
											</button>
										</div>
									</div>
								</div>
							}


						</div>
				}
			</div>
		</>
	)
}
