import * as React from "react"

const ProjectIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 10.583 10.583"
    {...props}
  >
    <defs>
      <filter
        style={{
          colorInterpolationFilters: "sRGB",
        }}
        id="b"
        x={-0.266}
        width={1.532}
        y={-0.266}
        height={1.532}
      >
        <feGaussianBlur stdDeviation={0.864} />
      </filter>
      <filter
        style={{
          colorInterpolationFilters: "sRGB",
        }}
        id="a"
        x={-0.012}
        width={1.024}
        y={-0.012}
        height={1.024}
      >
        <feGaussianBlur stdDeviation={0.053} />
      </filter>
    </defs>
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        style={{
          display: "inline",
          fill: "#d8d8d8",
          fillOpacity: 1,
          stroke: "#4d4d4d",
          strokeWidth: 0.264469,
          strokeLinecap: "round",
          strokeLinejoin: "bevel",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
          filter: "url(#a)",
        }}
        d="M8.6 2.125c2.97-.926 3.735 9.933-2.11 7.793-4.876 2.46-7.764-.906-5.26-4.358C-3.096-.783 8.875-.95 8.6 2.125Z"
        transform="matrix(.94855 0 0 .94856 0 .103)"
      />
      <circle
        style={{
          opacity: 1,
          fill: "#e2feff",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "none",
          strokeWidth: 0.147484,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          paintOrder: "fill markers stroke",
          filter: "url(#b)",
        }}
        cx={5.211}
        cy={5.147}
        transform="matrix(1.10814 0 0 1.10814 -.54 -.187)"
        r={3.895}
      />
    </g>
    <path
      d="M8.066 4.924a.14.14 0 0 1 .07-.11l.756-.379a.11.11 0 0 0 .056-.109l-.174-.803a.115.115 0 0 0-.096-.078l-.86-.056a.148.148 0 0 1-.11-.072l-.117-.215a.143.143 0 0 1 0-.131l.435-.733a.112.112 0 0 0-.012-.122L7.43 1.52a.113.113 0 0 0-.122-.014l-.749.416a.145.145 0 0 1-.13-.003l-.203-.115a.146.146 0 0 1-.07-.111l-.03-.858a.114.114 0 0 0-.076-.098L5.24.542a.111.111 0 0 0-.11.053l-.404.75c-.02.036-.053.066-.074.066h-.019c-.117 0-.27.011-.27.011a.147.147 0 0 1-.12-.056L3.752.657a.115.115 0 0 0-.117-.042L2.86.876a.11.11 0 0 0-.064.104l.066.843a.14.14 0 0 1-.058.116l-.246.17a.149.149 0 0 1-.131.017l-.797-.33a.115.115 0 0 0-.121.027l-.497.616a.109.109 0 0 0 .002.122l.508.67a.139.139 0 0 1 .014.129l-.138.33a.148.148 0 0 1-.101.085l-.846.153a.114.114 0 0 0-.087.088s-.083.52-.083.83v.003a.5.5 0 0 0 .072.029l.79.288a.14.14 0 0 1 .08.101l.075.404a.146.146 0 0 1-.039.125l-.625.582A.113.113 0 0 0 .61 6.5l.34.661a.11.11 0 0 0 .113.049l.823-.185c.041-.01.097.009.124.04l.304.316c.03.028.046.085.036.125l-.21.822c-.01.04.01.092.046.114l.643.362c.037.019.091.01.12-.02l.597-.604a.144.144 0 0 1 .126-.035l.424.095a.14.14 0 0 1 .099.085l.266.799c.013.04.058.073.1.074h.071c.256 0 .675-.054.675-.054a.115.115 0 0 0 .09-.084l.177-.837a.147.147 0 0 1 .087-.098l.373-.142A.14.14 0 0 1 6.163 8l.654.527a.109.109 0 0 0 .122.005l.614-.466a.115.115 0 0 0 .031-.12l-.306-.804a.148.148 0 0 1 .02-.13l.202-.277a.14.14 0 0 1 .118-.054l.838.09a.11.11 0 0 0 .105-.062l.274-.75a.115.115 0 0 0-.039-.117l-.695-.513a.149.149 0 0 1-.053-.12ZM4.62 7.399a2.535 2.535 0 1 1 0-5.071 2.535 2.535 0 0 1 0 5.07z"
      fill="#1e1b1b"
      style={{
        strokeWidth: 0.0252458,
        fill: "#04a",
      }}
    />
    <g
      style={{
        display: "inline",
        opacity: 1,
        fill: "#fd5",
        fillOpacity: 1,
        stroke: "#000",
        strokeWidth: 0.90945,
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
    >
      <path
        d="M8.457 12.559c2.08 0 3.648-1.569 3.648-3.648 0-2.078-1.569-3.647-3.648-3.647-2.078 0-3.647 1.569-3.647 3.647 0 2.08 1.569 3.648 3.647 3.648zm1.043.988H7.415a5.216 5.216 0 0 0-5.21 5.21v1.042H14.71v-1.042a5.216 5.216 0 0 0-5.21-5.21z"
        style={{
          fill: "#fd5",
          fillOpacity: 1,
          stroke: "#000",
          strokeWidth: 0.586922,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="matrix(.37366 0 0 .4097 3.27 -.498)"
      />
    </g>
    <text
      xmlSpace="preserve"
      style={{
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: 700,
        fontStretch: "normal",
        fontSize: "7.79271px",
        lineHeight: 1.25,
        fontFamily: "Helvetica-Narrow",
        InkscapeFontSpecification: "'Helvetica-Narrow, Bold'",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        letterSpacing: 0,
        wordSpacing: 0,
        opacity: 1,
        fill: "#0f0",
        stroke: "#000",
        strokeWidth: 0.246944,
        strokeMiterlimit: 4,
        strokeDasharray: "none",
      }}
      x={0.637}
      y={9.351}
      transform="scale(1.00017 .99983)"
    >
      <tspan
        x={0.637}
        y={9.351}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 700,
          fontStretch: "normal",
          fontSize: "7.79271px",
          fontFamily: "Helvetica-Narrow",
          InkscapeFontSpecification: "'Helvetica-Narrow, Bold'",
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#0f0",
          stroke: "#000",
          strokeWidth: 0.246944,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
        }}
      >
        {"$"}
      </tspan>
    </text>
  </svg>
)

export default ProjectIcon
