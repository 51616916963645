import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

export const OrganizationAdminHeader = ({page_name}: any) => {
    const {organization_id} = useParams();
    const user_organizations_obj = useSelector((state:any) => state.user_organizations);
    let user_organizations = user_organizations_obj.data?.data
    let organization = user_organizations.filter((item:any) => item.id == organization_id)[0];
    return (
        <div className="col-12 user-main-tittle d-flex align-items-center align-self-start">
            <span style={{fontSize: '40px', color: 'SaddleBrown'}}>☸</span>
            <span style={{fontSize: '24px'}}>{page_name} <span style={{color: 'purple'}}><b>{organization.name}</b></span></span>
            <div className="dropdown d-inline-block mx-3">
                <button className="btn btn-teal dropdown-toggle remove-arrow" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><Link to={`/organizations/${organization_id}`} className="dropdown-item">Administrator Home</Link></li>
                    <li><Link to={`/organizations/${organization_id}/profile`} className="dropdown-item">Organization Profile</Link></li>
                    <li><Link to={`/organizations/${organization_id}/members`} className="dropdown-item">Manage Members</Link></li>
                    <li><Link to={`/organizations/${organization_id}/teams`} className="dropdown-item">Manage Teams</Link></li>
                    <li><Link to={`/organizations/${organization_id}/coaches`} className="dropdown-item">Assign Coaches</Link></li>
                    <li><a className="dropdown-item" href="#">Review Invoices</a></li>
                    <li><Link to={`/organizations/${organization_id}/administrators`} className="dropdown-item">Assign Administrators</Link></li>
                    <li><Link to={`/organizations/${organization_id}/remove`} className="dropdown-item">Remove Organization</Link></li>
                </ul>
            </div>
        </div>
    )
}
