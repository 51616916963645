import React, {useState} from 'react'
import {useSelector} from "react-redux";

export const SummaryCell = ({row}:any) => {
    const [isHovered, setIsHovered] = useState(false);
    const router = useSelector((state:any) => state.router);
    let organization_id = router.selected_organization_id;
    let team_id = router.selected_team_id;
    const organization_team = useSelector((state:any) => state.organization_team);


    const members = organization_team.data.members.concat(organization_team.data.viewers)
    members.sort((a: { id: number; }, b: { id: number; }) => a.id - b.id);

    const total_members = members.length

    let member_fee;
    let fee;
    let total;
    if(row.BillingCycle == 'Yearly') {
        member_fee = parseFloat(row.FeeMemberY)
        fee = parseFloat(row.FeeY)
        total = ((fee + (total_members * member_fee)) * 12)
    } else {
        member_fee = parseFloat(row.FeeMember)
        fee = parseFloat(row.Fee)
        total = ((fee + (total_members * member_fee)))
    }

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <>
            <div className="d-flex justify-content-between">
                <div>
                    <div>Base App Fee:</div>
                    <div style={{cursor: 'pointer'}} onMouseEnter={handleMouseEnter}
                         onMouseLeave={handleMouseLeave}>Members ({total_members} ▼):</div>
                    {isHovered && (
                        <ul>
                            {
                                members.map((members: { KnownAs: string; }) => (
                                <li>{members.KnownAs}</li>
                                ))
                            }
                        </ul>
                    )}

                    <div>Total ( {row.BillingCycle} ):</div>
                </div>
                <div className={'mx-5'}>
                    <div>${fee.toFixed(2)}</div>
                    <div>${(member_fee * total_members).toFixed(2)}</div>
                    <div>${total.toFixed(2)}</div>
                </div>
            </div>
        </>
    )
}
