import {combineReducers, configureStore} from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import {authSlice} from "./auth";
import {userOrganizationSlice} from "./user_organizations";
import {coachNotesSlice} from "./coaches/teams/coach_notes";
import {organizationTeamSlice} from "./user_organizations/teams/team";
import {routerSlice} from "./routes";
import {connectAndAlignSlice} from "./user_organizations/teams/tools/connect_and_align";
import {connectAndAlignRememberSlice} from "./user_organizations/teams/tools/connect_and_align/cnARememberSlice";
import {connectAndAlignSearchSlice} from "./user_organizations/teams/tools/connect_and_align/cnASearchSlice";
import {servicesSlice} from "./global/services";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['router', 'organization_team', 'services', 'tool_cna_remember']
}

const rootReducer = combineReducers({
    auth: authSlice.reducer,
    user_organizations: userOrganizationSlice.reducer,
    coach_notes: coachNotesSlice.reducer,
    organization_team: organizationTeamSlice.reducer,
    tool_cna: connectAndAlignSlice.reducer,
    tool_cna_remember: connectAndAlignRememberSlice.reducer,
    tool_cna_search: connectAndAlignSearchSlice.reducer,
    router: routerSlice.reducer,
    services: servicesSlice.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
})

export const persistor = persistStore(store)
