import React, {useState} from 'react'
import {Header} from "../../../../header/Header";
import {FacilitatorHeader} from "../FacilitatorHeader";
import {useDispatch, useSelector} from "react-redux";
import {TextAreaInput} from "../../../../user/menu/how_to_lead/TextAreaInput";
import axios from "axios";
import {zynity_api} from "../../../../../config/external-routes";
import Swal from "sweetalert2";
import {getUserOrganizations} from "../../../../../store/user_organizations";

export const TeamProfile = () => {
    const dispatch = useDispatch();
    const router = useSelector((state:any) => state.router);
    let organization_id = router.selected_organization_id;
    let team_id = router.selected_team_id;
    const user_organizations_obj = useSelector((state:any) => state.user_organizations);
    const user_organizations = user_organizations_obj.data?.data
    let organization = user_organizations.find((item:any) => item.id == organization_id)
    let team = organization.teams.find((item:any) => item.id == team_id)

    const [formData, setFormData] = useState({
        TeamName: team.TeamName,
        Suite: team.Suite,
        Purpose: team.Purpose,
        CoreValues: team.CoreValues
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const submitFormTeamProfile = (event:any) => {
        event.preventDefault();
        console.log("to submit:", formData)

        let url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);

        axios.put(`${url}/facilitator_dashboard/team_profile`, formData)
            .then((response) => {
                dispatch(getUserOrganizations());
                Swal.fire({
                    icon: 'success',
                    title: 'Team updated!',
                    text: response.data.message,
                })
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'We have problems updating the team profile!',
                })
            })
    }

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-2 mb-5">
                    <div className="col-12 col-md-9">
                        <div className="row">
                            <FacilitatorHeader page_name={'Team Profile'} />
                        </div>
                    </div>

                    <div className="col-12 col-md-9">
                        <div className="card">
                            <div className="card-body">
                                <form id="dataFormDetails" onSubmit={submitFormTeamProfile} noValidate>
                                    <div className="row">
                                        <div className='col-12 col-md-6'>
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="date"
                                                    className="form-label"
                                                >Team Name
                                                    <span style={{color: 'red'}}>*</span>
                                                </label>
                                                <input
                                                    type='text'
                                                    className="form-control"
                                                    name={'TeamName'}
                                                    placeholder={'Enter Team Name'}
                                                    autoComplete="off"
                                                    required={true}
                                                    value={formData.TeamName}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="date"
                                                    className="form-label"
                                                >Suite
                                                </label>
                                                <input
                                                    type='text'
                                                    className="form-control"
                                                    name={'Suite'}
                                                    autoComplete="off"
                                                    readOnly={true}
                                                    disabled={true}
                                                    value={formData.Suite}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="date"
                                                    className="form-label"
                                                >Purpose
                                                </label>
                                                <TextAreaInput
                                                    placeholder='Purpose'
                                                    key_name='Purpose'
                                                    value={formData.Purpose}
                                                    setFormDatum={handleChange} />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="date"
                                                    className="form-label"
                                                >Core Values
                                                </label>
                                                <TextAreaInput
                                                    placeholder='Core Values'
                                                    key_name='CoreValues'
                                                    value={formData.CoreValues}
                                                    setFormDatum={handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="card-footer bg-transparent d-flex flex-row-reverse">
                                <button type="submit"
                                        form="dataFormDetails"
                                        className="btn btn-primary">Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
