import React, {useEffect} from 'react'
import {useSelector} from "react-redux";
import {Navigate, useNavigate} from "react-router-dom";

export const RedirectRouter = () => {
    const router = useSelector((state:any) => state.router)
    const navigate = useNavigate();

    useEffect(() => {
        if(router?.specific_route == null) {
            navigate('/')
        }
        navigate(router.specific_route)
    }, [])
    return (
        <>
        </>
    )
}
