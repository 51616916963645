import React, { useEffect } from 'react'
import { Header } from '../header/Header'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import './userHome.scss'
import { zynity_website } from '../../config/external-routes'
import { Body } from './body/Body'
import { constants } from '../../config/constants'
import {getUserOrganizations} from "../../store/user_organizations";
// @ts-ignore
import Cookies from 'js-cookie'

export const UserHome = () => {
    let access_token = Cookies.get(constants.zynity_api_access_token_cookie_name);
    if(access_token == undefined) {
        window.location.replace(zynity_website.yii_website_logout_url);
    }
    document.title = `My Dashboard - Transforming Life in Organizations™ | ${constants.website_title}`
    
    const dispatch = useDispatch();
    const yii_url = zynity_website.yii_website_url;
    const user = useSelector(state => state.auth)

    useEffect( () => {
        dispatch( getUserOrganizations() );
    }, [])

    function profileCheckMsg(user) {
        let url = `${zynity_website.yii_website_url}/people/update/id/${user.id}`;
        let msg = `Your profile is incomplete of basic information. <a href="${url}">Click Here</a> to complete now.`;
        if (user.first_name == '' || user.last_name == '' || user.know_as == '' || user.time_zone == '') {
            return msg;
        }
        return false;
    }

    function coachProfileCheckMsg(user) {
        const coach = user.coach;
        if (!coach)
            return false;

        let url = `${zynity_website.yii_website_url}/CertifiedCoach/ccUpdate/id/${coach.id}`;
        let msg = `Please review your Coaches profile for basic current information. <a href="${url}">Click Here</a> to complete now.`;
        if (coach.FirstName == '' || coach.LastName == '' || coach.Type == '') {
            return msg;
        }
        return false;
    }

    return (
        <>
            <Header />

            <div className="container">
                {
                    profileCheckMsg(user) &&
                    <div className="row">
                        <div className="col userHomeMessage" dangerouslySetInnerHTML={{ __html: profileCheckMsg(user) }}></div>
                    </div>
                }

                {
                    coachProfileCheckMsg(user) &&
                    <div className="row">
                        <div className="col userHomeMessage" dangerouslySetInnerHTML={{ __html: coachProfileCheckMsg(user) }}></div>
                    </div>
                }
                <div className="row">
                    <div className="col-12 col-md-3 user-main-tittle">
                        <h4>
                            Dashboard&nbsp;<img src={require('../../assets/images/dashboard-icon.png')} width="30"></img>
                        </h4>
                    </div>
                    <div className="col-12 col-md-9 mt-md-3">
                        <a href={`${yii_url}/mystory/index`} ><button className="theme-btn btn-style-one w-auto m-1">My Story</button></a>
                        <a href={`${yii_url}/Development/index`} ><button className="theme-btn btn-style-one w-auto m-1">My Development</button></a>
                        <Link to="/how-lead"><button className="theme-btn btn-style-one w-auto m-1">How You Lead</button></Link>
                    </div>
                </div>

                <Body/>
                
            </div>
        </>
    )
}
