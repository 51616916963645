import axios from 'axios'
import { zynity_api } from '../config/external-routes';

export const loadAdminContent = async (contentName, access_token) => {
    axios.defaults.headers.common = {'Authorization': `Bearer ${access_token}`}

    let result = [];
    
    await axios.get(`${zynity_api.admin_editor_content}?content_name=${contentName}`)
        .then((response) => {
            result = response.data.content;
        })

        return result;
}