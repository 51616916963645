import React from 'react'

export const FormCoachDetails = ({data, setFormDatum, onSubmit, readonly, formId = 'dataFormDetails', formParsedValues = null}) => {
    let default_col_size = 'col-12 col-md-6';
    const formDefaultParsedValues = {
        "Date": {
            "value": data.Date,
            "type": "text",
            "name": "Date",
            "human_name": "Date",
            "required": true,
            "invalid_feedback": "Please add a valid Date"
        },
        "FirstName": {
            "value": data.FirstName,
            "type": "text",
            "name": "FirstName",
            "human_name": "FirstName",
            "required": true,
            "invalid_feedback": "Please add the First Name"
        },
        "LastName": {
            "value": data.LastName,
            "type": "text",
            "name": "LastName",
            "human_name": "LastName",
            "required": true,
            "invalid_feedback": "Please add the Last Name"
        },
        "Type": {
            "value": data.Type,
            "type": "checkboxes_comma_separator",
            "name": "Type",
            "human_name": "Type",
            "required": false
        },
        "TypeOther": {
            "value": data.TypeOther,
            "type": "text",
            "name": "TypeOther",
            "human_name": "TypeOther",
            "required": false
        },
        "OrgName": {
            "value": data.OrgName,
            "type": "text",
            "name": "OrgName",
            "human_name": "OrgName",
            "required": false
        },
        "Address": {
            "value": data.Address,
            "type": "text",
            "name": "Address",
            "human_name": "Address",
            "required": false,
            "col_size": "col-6 col-md-4"
        },
        "Address2": {
            "value": data.Address2,
            "type": "text",
            "name": "Address2",
            "human_name": "Address 2",
            "required": false,
            "col_size": "col-6 col-md-4"
        },
        "City": {
            "value": data.City,
            "type": "text",
            "name": "City",
            "human_name": "City",
            "required": false,
            "col_size": "col-6 col-md-4"
        },
        "State": {
            "value": data.State,
            "type": "text",
            "name": "State",
            "human_name": "State",
            "required": false,
            "col_size": "col-6 col-md-4"
        },
        "Zip": {
            "value": data.Zip,
            "type": "text",
            "name": "Zip",
            "human_name": "Zip",
            "required": false,
            "col_size": "col-6 col-md-4"
        },
        "Country": {
            "value": data.Country,
            "type": "text",
            "name": "Country",
            "human_name": "Country",
            "required": false,
            "col_size": "col-6 col-md-4"
        },
        "Phone": {
            "value": data.Phone,
            "type": "text",
            "name": "Phone",
            "human_name": "Phone",
            "required": false,
        },
        "Email": {
            "value": data.Email,
            "type": "text",
            "name": "Email",
            "human_name": "Email",
            "required": false,
        },
        "WebAddress": {
            "value": data.WebAddress,
            "type": "text",
            "name": "WebAddress",
            "human_name": "Web Address",
            "required": false
        },
        "Activity": {
            "value": data.Activity,
            "type": "text",
            "name": "Activity",
            "human_name": "Activity",
            "required": false
        },
        "WorkWith": {
            "value": data.WorkWith,
            "type": "text",
            "name": "WorkWith",
            "human_name": "Work With",
            "required": false
        },
        "YearsExperience": {
            "value": data.YearsExperience,
            "type": "number",
            "name": "YearsExperience",
            "human_name": "Years of Experience",
            "required": false
        },
        "Explain": {
            "value": data.Description,
            "type": "text",
            "name": "Description",
            "human_name": "Description",
            "required": false
        },
        "Welcome": {
            "value": data.Welcome,
            "type": "text",
            "name": "Welcome",
            "human_name": "Welcome",
            "required": false
        },
        "Description": {
            "value": data.Description,
            "type": "text",
            "name": "Description",
            "human_name": "Description",
            "required": false
        },
        "Resume": {
            "value": data.Resume,
            "type": "text",
            "name": "Resume",
            "human_name": "Resume",
            "required": false
        },
        "Agreement": {
            "value": data.Agreement,
            "type": "dropdown",
            "name": "Agreement",
            "human_name": "Agreement",
            "required": false,
            "invalid_feedback": null,
            "dropdownOptions": [
                {
                    "value": "Yes",
                    "name": "Yes"
                },
                {
                    "value": "No",
                    "name": "No"
                }
            ]
        },
        "DigitalSignature": {
            "value": data.DigitalSignature,
            "type": "text",
            "name": "DigitalSignature",
            "human_name": "Digital Signature",
            "required": false
        },
        "Status": {
            "value": data.Status,
            "type": "dropdown",
            "name": "Status",
            "human_name": "Status",
            "required": false,
            "invalid_feedback": null,
            "dropdownOptions": [
                {
                    "value": "Active",
                    "name": "Active"
                },
                {
                    "value": "Inactive",
                    "name": "Inactive"
                }
            ]
        },
        "Notes": {
            "value": data.Notes,
            "type": "text",
            "name": "Notes",
            "human_name": "Notes",
            "required": false
        },
    }
    formParsedValues = formParsedValues !== null ? formParsedValues : formDefaultParsedValues;

    const handleCheckboxChange = (value, selectedValues, field_name) => {
        // Toggle the selectedValues based on the checkbox selection
        let valuesArray = selectedValues.split(", ").map((value) => value.trim());

        if (valuesArray.includes(value)) {
            valuesArray = valuesArray.filter((item) => item !== value);
        } else {
            valuesArray = [...valuesArray, value];
        }
        const nonEmptyValues = valuesArray.filter(value => value.trim() !== '')
        const commaSeparatedString = nonEmptyValues.join(", ");

        setFormDatum({
            target: {
                name: field_name,
                value: commaSeparatedString
            }
        })
    };

    const renderInput = (form_value) => {

        const options = ["Consultant", "Mentor", "Coach", "Counselor", "Other"];

        switch (form_value.type) {
            case 'dropdown':
                return (
                    <select
                        className="form-select"
                        id={form_value.name}
                        name={form_value.name}
                        placeholder={form_value.human_name}
                        autoComplete="off"
                        value={form_value.value}
                        onChange={setFormDatum}
                        disabled={readonly}
                    >
                        {
                            form_value.dropdownOptions.map((option) => (
                                <option value={option.value}>{option.name}</option>
                            ))
                        }
                    </select>
                )
            case 'checkboxes_comma_separator':
                return (
                    <>
                        {
                            options.map((option) => (
                                <label key={option} style={{display: 'block'}}>
                                    <input
                                        style={{margin: '5px'}}
                                        type="checkbox"
                                        value={option}
                                        checked={form_value?.value?.includes(option)}
                                        onChange={() => handleCheckboxChange(option, form_value.value, form_value.name)}
                                    />
                                    {option}
                                </label>
                            ))
                        }
                    </>
                )
            case 'textarea':
                return (
                    <textarea
                        className="form-control"
                        id={form_value.name}
                        name={form_value.name}
                        placeholder={form_value.human_name}
                        autoComplete="off"
                        required={form_value.required}
                        value={form_value.value}
                        onChange={setFormDatum}
                        readOnly={readonly}
                        disabled={readonly}
                        rows={4}
                    />
                )
            default:
                return (
                    <input
                        type={form_value.type}
                        className="form-control"
                        id={form_value.name}
                        name={form_value.name}
                        placeholder={form_value.human_name}
                        autoComplete="off"
                        required={form_value.required}
                        value={form_value.value}
                        onChange={setFormDatum}
                        readOnly={readonly}
                        disabled={readonly}
                    />
                )
        }
    }

    return (
        <form id={formId} onSubmit={onSubmit} noValidate>
            <div className="row">
                {
                    Object.keys(formParsedValues).map((row) => (
                        <div className={formParsedValues[row].hasOwnProperty('col_size') ? formParsedValues[row].col_size : default_col_size}>
                            <div className="mb-3">
                                <label
                                    htmlFor="date"
                                    className="form-label"
                                ><div dangerouslySetInnerHTML={{__html: formParsedValues[row].human_name}} style={{display: 'inline'}}/>
                                    {formParsedValues[row].required && <span style={{color: 'red'}}>*</span>}
                                </label>
                                {
                                    renderInput(formParsedValues[row])
                                }
                                {
                                    formParsedValues[row].invalid_feedback &&
                                    <div className="invalid-feedback">
                                        {formParsedValues[row].invalid_feedback}
                                    </div>
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </form>
    )
}
