import React, {useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import {Header} from "../../header/Header";
import {FormCoachDetails} from "../../admin/coaches/details/FormCoachDetails";
import {useForm} from "../../hooks/useForm";
import {getRequest} from "../../../helpers/getRequest";
import {zynity_api} from "../../../config/external-routes";
import {ImageUploader} from "../../ui/uploader/image/ImageUploader";
import {publicUrl} from "../../../helpers/files";
import {Loading} from "../../ui/loading/Loading";
import {FileUploader} from "../../ui/uploader/file/fileUploader";
import {Link, useParams} from "react-router-dom";

export const CoachProfile = ({show_logged_coach = true}: any) => {
    const authenticated_user = useSelector(state => state.auth)
    const [tempImgLogo, setTempImgLogo] = useState(null)
    const [tempImgPhoto, setTempImgPhoto] = useState(null)
    const [tempResumeFile, setTempResumeFile] = useState(null)
    const [loading, setLoading] = useState(true)
    const [coachData, setFormCoachDatum, setCoachData] = useForm({})
    let {coach_id} = useParams();

    let read_only = true;
    if(show_logged_coach === true) {
        coach_id = authenticated_user.coach.id
        read_only = false;
    }


    useEffect(() => {
        getRequest(`${zynity_api.admin_coaches}/${coach_id}`, authenticated_user.access_token).then((response) => {
            setCoachData(response)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        setCoachData({
            ...coachData,
            ['Photo']: tempImgPhoto
        })
    }, [tempImgPhoto])

    useEffect(() => {
        setCoachData({
            ...coachData,
            ['Logo']: tempImgLogo
        })
    }, [tempImgLogo])

    useEffect(() => {
        setCoachData({
            ...coachData,
            ['Resume']: tempResumeFile
        })
    }, [tempResumeFile])

    const handleSubmitForm = (event) => {
        event.preventDefault();

        let form = document.getElementById('coachDataForm');
        if (!form.checkValidity()) {
            form.classList.add('was-validated');
            return false;
        }

        axios.defaults.headers.common = {'Authorization': `Bearer ${authenticated_user.access_token}`}
        axios.put(`${zynity_api.admin_coaches}/${coach_id}`, coachData)
            .then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: response.data.message,
                })
                console.log("from server", response.data.coach.Resume)
                console.log(publicUrl(response.data.coach.Resume))
                setTempResumeFile(publicUrl(response.data.coach.Resume))
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'We have problems updating the Coach!',
                })
            })
    }

    const formDefaultParsedValues = {
        "FirstName": {
            "value": coachData.FirstName,
            "type": "text",
            "name": "FirstName",
            "human_name": "First Name",
            "required": true,
            "invalid_feedback": "Please add the First Name",
            "col_size": "col-6 col-md-6"
        },
        "LastName": {
            "value": coachData.LastName,
            "type": "text",
            "name": "LastName",
            "human_name": "Last Name",
            "required": true,
            "invalid_feedback": "Please add the Last Name",
            "col_size": "col-6 col-md-6"
        },
        "Type": {
            "value": coachData.Type,
            "type": "checkboxes_comma_separator",
            "name": "Type",
            "human_name": "<b>You are:</b> (check all that apply)",
            "required": false,
            "col_size": "col-6 col-md-4"
        },
        "TypeOther": {
            "value": coachData.TypeOther,
            "type": "textarea",
            "name": "TypeOther",
            "human_name": "Other Explain below",
            "required": false,
            "col_size": "col-6 col-md-8"
        },
        "OrgName": {
            "value": coachData.OrgName,
            "type": "text",
            "name": "OrgName",
            "human_name": "Organization Name",
            "required": false,
            "col_size": "col-6 col-md-7"
        },
        "Address": {
            "value": coachData.Address,
            "type": "text",
            "name": "Address",
            "human_name": "Address",
            "required": false,
            "col_size": "col-6 col-md-3"
        },
        "Address2": {
            "value": coachData.Address2,
            "type": "text",
            "name": "Address2",
            "human_name": "Address 2",
            "required": false,
            "col_size": "col-6 col-md-3"
        },
        "City": {
            "value": coachData.City,
            "type": "text",
            "name": "City",
            "human_name": "City",
            "required": false,
            "col_size": "col-6 col-md-3"
        },
        "State": {
            "value": coachData.State,
            "type": "text",
            "name": "State",
            "human_name": "State",
            "required": false,
            "col_size": "col-6 col-md-3"
        },
        "Zip": {
            "value": coachData.Zip,
            "type": "text",
            "name": "Zip",
            "human_name": "Zip",
            "required": false,
            "col_size": "col-6 col-md-2"
        },
        "Country": {
            "value": coachData.Country,
            "type": "text",
            "name": "Country",
            "human_name": "Country",
            "required": false,
            "col_size": "col-6 col-md-3"
        },
        "Phone": {
            "value": coachData.Phone,
            "type": "text",
            "name": "Phone",
            "human_name": "Phone",
            "required": false,
            "col_size": "col-6 col-md-3"
        },
        "Email": {
            "value": coachData.Email,
            "type": "text",
            "name": "Email",
            "human_name": "Email",
            "required": false,
            "col_size": "col-6 col-md-4"
        },
        "WebAddress": {
            "value": coachData.WebAddress,
            "type": "text",
            "name": "WebAddress",
            "human_name": "Web Address",
            "required": false,
            "col_size": "col-6 col-md-4"
        },
        "LinkedinUrl": {
            "value": coachData.LinkedinUrl,
            "type": "text",
            "name": "LinkedinUrl",
            "human_name": "Linkedin Url",
            "required": false,
            "col_size": "col-6 col-md-6"
        },
        "Activity": {
            "value": coachData.Activity,
            "type": "text",
            "name": "Activity",
            "human_name": "Primary Business Activity",
            "required": false,
            "col_size": "col-6 col-md-8"
        },
        "WorkWith": {
            "value": coachData.WorkWith,
            "type": "text",
            "name": "WorkWith",
            "human_name": "Work With",
            "required": false,
            "col_size": "col-6 col-md-8"
        },
        "YearsExperience": {
            "value": coachData.YearsExperience,
            "type": "number",
            "name": "YearsExperience",
            "human_name": "Years of Experience",
            "required": false,
            "col_size": "col-6 col-md-4"
        },
        "Explain": {
            "value": coachData.Description,
            "type": "text",
            "name": "Description",
            "human_name": "Description",
            "required": false
        },
        "Welcome": {
            "value": coachData.Welcome,
            "type": "text",
            "name": "Welcome",
            "human_name": "Welcome",
            "required": false
        },
        "Agreement": {
            "value": coachData.Agreement,
            "type": "dropdown",
            "name": "Agreement",
            "human_name": "Agreement",
            "required": false,
            "invalid_feedback": null,
            "dropdownOptions": [
                {
                    "value": "Yes",
                    "name": "Yes"
                },
                {
                    "value": "No",
                    "name": "No"
                }
            ],
            "col_size": "col-6 col-md-2"
        },
        "Status": {
            "value": coachData.Status,
            "type": "dropdown",
            "name": "Status",
            "human_name": "Status",
            "required": false,
            "invalid_feedback": null,
            "dropdownOptions": [
                {
                    "value": "Active",
                    "name": "Active"
                },
                {
                    "value": "Inactive",
                    "name": "Inactive"
                }
            ],
            "col_size": "col-6 col-md-2"
        },
        "DigitalSignature": {
            "value": coachData.DigitalSignature,
            "type": "text",
            "name": "DigitalSignature",
            "human_name": "Digital Signature",
            "required": false,
            "col_size": "col-6 col-md-8"
        },
        "Notes": {
            "value": coachData.Notes,
            "type": "text",
            "name": "Notes",
            "human_name": "Notes",
            "required": false,
            "col_size": "col-6 col-md-12"
        },
    }

    const logoCropSettings = 20 / 9;
    const profileImgCropSettings = 12 / 12;

    if(loading === false && tempResumeFile == null && coachData.Resume != null) {
        setTempResumeFile(publicUrl(coachData.Resume))
    }

    const handleCheckboxViewResumeChange = (event:any) => {
        // @ts-ignore
        setFormCoachDatum({
            target: {
                name: 'ShowResume',
                value: event.target.checked ? 'Yes' : 'No'
            }
        })
    };

    return (
        <>
            <Header/>
            <div className="container">
                <div className="row mt-4 d-flex justify-content-between align-items-center">
                    <div className="col-12 col-md-6 submenu-title">
                        <h4>
                            Coach Profile Update <img className='d-inline' src={require('../../../assets/images/coach_notes_icon.png')} width="65" alt=""/>
                        </h4>
                    </div>
                    {
                        read_only === false &&
                        <div className="col-12 col-md-6 text-end mb-3">
                            <div className="d-flex justify-content-end">
                                <div><Link to='/coach/agreement' ><button type="button" className="btn btn-sm btn-outline-secondary">Certified Coach Agreement</button></Link></div>
                                <div><Link to='/coach' ><button type="button" className="btn btn-sm btn-outline-secondary mx-3">Coach Dashboard</button></Link></div>
                                <div><button type="button" className="btn btn-sm btn-primary" onClick={handleSubmitForm}>Update</button></div>
                            </div>
                        </div>
                    }

                </div>

                <div className="row d-flex justify-content-between mb-5">
                    <div className="col-12">
                        The fields used in this section are part of your public profile.
                    </div>

                    <div className="col-md-9">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Basic information</h5>
                                <FormCoachDetails
                                    formId='coachDataForm'
                                    data={coachData}
                                    formParsedValues={formDefaultParsedValues}
                                    readonly={read_only}
                                    setFormDatum={setFormCoachDatum}
                                    onSubmit={handleSubmitForm}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="card-subtitle text-muted">Your logo</h6>
                                {
                                    loading ? <Loading /> :
                                    <ImageUploader
                                        src={coachData.Logo !== null ? publicUrl(coachData.Logo) : 'https://zynity.com/assets/img/ZynityLogo.jpg'}
                                        readonly={read_only}
                                        setTempImgUrl={setTempImgLogo}
                                        aspect={logoCropSettings} />
                                }

                            </div>
                        </div>

                        <div className="card mt-3">
                            <div className="card-body">
                                <h6 className="card-subtitle text-muted">Your Photo</h6>
                                <div className="text-center">
                                    {
                                        loading ? <Loading /> :
                                        <ImageUploader
                                            src={coachData.Photo !== null ? publicUrl(coachData.Photo) : 'https://www.pavilionweb.com/wp-content/uploads/2017/03/man-300x300.png'}
                                            readonly={read_only}
                                            setTempImgUrl={setTempImgPhoto}
                                            aspect={profileImgCropSettings} />
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <div className="card-body">
                                <h6 className="card-subtitle text-muted">Your Resume</h6>
                                <span className={''}>(Must be PDF)</span>
                                {
                                    loading ? <Loading /> :
                                        <FileUploader
                                            download_url={tempResumeFile}
                                            readonly={read_only}
                                            setUrl={setTempResumeFile}
                                            accept={'application/pdf'}
                                        />
                                }

                                <label style={{display: 'block'}}>
                                    <input
                                        style={{margin: '5px'}}
                                        type="checkbox"
                                        value={'checked'}
                                        checked={coachData?.ShowResume == 'Yes'}
                                        onChange={handleCheckboxViewResumeChange}
                                    />
                                    Make resume public
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-9">
                        <div className="card mt-3">
                            <div className="card-body">
                                <h5 className="card-title">References</h5>
                                <form>
                                    <h6 className="card-subtitle mb-2 text-muted">Please provide 3 professional references</h6>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label">Name</label>
                                                <input
                                                    id='ProRef1Name'
                                                    name='ProRef1Name'
                                                    type='text'
                                                    className="form-control"
                                                    placeholder="Name"
                                                    autoComplete="off"
                                                    value={coachData.ProRef1Name}
                                                    onChange={setFormCoachDatum}
                                                    readOnly={read_only}
                                                    disabled={read_only}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label">Phone</label>
                                                <input
                                                    id='ProRef1Phone'
                                                    name='ProRef1Phone'
                                                    type='text'
                                                    className="form-control"
                                                    placeholder="Phone"
                                                    autoComplete="off"
                                                    value={coachData.ProRef1Phone}
                                                    onChange={setFormCoachDatum}
                                                    readOnly={read_only}
                                                    disabled={read_only}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label">Email</label>
                                                <input
                                                    id='ProRef1Email'
                                                    name='ProRef1Email'
                                                    type='email'
                                                    className="form-control"
                                                    placeholder="Email"
                                                    autoComplete="off"
                                                    value={coachData.ProRef1Email}
                                                    onChange={setFormCoachDatum}
                                                    readOnly={read_only}
                                                    disabled={read_only}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <input
                                                    id='ProRef2Name'
                                                    name='ProRef2Name'
                                                    type='text'
                                                    className="form-control"
                                                    placeholder="Name"
                                                    autoComplete="off"
                                                    value={coachData.ProRef2Name}
                                                    onChange={setFormCoachDatum}
                                                    readOnly={read_only}
                                                    disabled={read_only}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <input
                                                    id='ProRef2Phone'
                                                    name='ProRef2Phone'
                                                    type='text'
                                                    className="form-control"
                                                    placeholder="Phone"
                                                    autoComplete="off"
                                                    value={coachData.ProRef2Phone}
                                                    onChange={setFormCoachDatum}
                                                    readOnly={read_only}
                                                    disabled={read_only}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <input
                                                    id='ProRef2Email'
                                                    name='ProRef2Email'
                                                    type='email'
                                                    className="form-control"
                                                    placeholder="Email"
                                                    autoComplete="off"
                                                    value={coachData.ProRef2Email}
                                                    onChange={setFormCoachDatum}
                                                    readOnly={read_only}
                                                    disabled={read_only}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <input
                                                    id='ProRef3Name'
                                                    name='ProRef3Name'
                                                    type='text'
                                                    className="form-control"
                                                    placeholder="Name"
                                                    autoComplete="off"
                                                    value={coachData.ProRef3Name}
                                                    onChange={setFormCoachDatum}
                                                    readOnly={read_only}
                                                    disabled={read_only}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <input
                                                    id='ProRef3Phone'
                                                    name='ProRef3Phone'
                                                    type='text'
                                                    className="form-control"
                                                    placeholder="Phone"
                                                    autoComplete="off"
                                                    value={coachData.ProRef3Phone}
                                                    onChange={setFormCoachDatum}
                                                    readOnly={read_only}
                                                    disabled={read_only}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <input
                                                    id='ProRef3Email'
                                                    name='ProRef3Email'
                                                    type='email'
                                                    className="form-control"
                                                    placeholder="Email"
                                                    autoComplete="off"
                                                    value={coachData.ProRef3Email}
                                                    onChange={setFormCoachDatum}
                                                    readOnly={read_only}
                                                    disabled={read_only}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <h6 className="card-subtitle mb-2 text-muted">Please provide 1 personal reference</h6>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label">Name</label>
                                                <input
                                                    id='PerRefName'
                                                    name='PerRefName'
                                                    type='text'
                                                    className="form-control"
                                                    placeholder="Name"
                                                    autoComplete="off"
                                                    value={coachData.PerRefName}
                                                    onChange={setFormCoachDatum}
                                                    readOnly={read_only}
                                                    disabled={read_only}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label">Phone</label>
                                                <input
                                                    id='PerRefPhone'
                                                    name='PerRefPhone'
                                                    type='text'
                                                    className="form-control"
                                                    placeholder="Phone"
                                                    autoComplete="off"
                                                    value={coachData.PerRefPhone}
                                                    onChange={setFormCoachDatum}
                                                    readOnly={read_only}
                                                    disabled={read_only}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label">Email</label>
                                                <input
                                                    id='PerRefEmail'
                                                    name='PerRefEmail'
                                                    type='text'
                                                    className="form-control"
                                                    placeholder="Email"
                                                    autoComplete="off"
                                                    value={coachData.PerRefEmail}
                                                    onChange={setFormCoachDatum}
                                                    readOnly={read_only}
                                                    disabled={read_only}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <h6 className="card-subtitle mb-2 text-muted">Please explain why you feel being a Certified Coach will help you, your organization and the organizations you serve?</h6>
                                    <textarea
                                        id='Explain'
                                        name='Explain'
                                        className="form-control"
                                        placeholder="Explain..."
                                        autoComplete="off"
                                        value={coachData.Explain}
                                        onChange={setFormCoachDatum}
                                        readOnly={read_only}
                                                    disabled={read_only}
                                    />
                                </form>

                            </div>
                            {
                                read_only === false ??
                                <div className="card-footer bg-transparent d-flex flex-row-reverse">
                                    <button type="submit"
                                            className="btn btn-primary"
                                            onClick={handleSubmitForm}
                                    >Update
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
