import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';
import {constants} from "../config/constants";
import Cookies from "js-cookie";
import {zynity_website} from "../config/external-routes";

export const CoachRoute = ({children}) => {
    let access_token = Cookies.get(constants.zynity_api_access_token_cookie_name);
    if(access_token == undefined) {
        window.location.replace(zynity_website.yii_website_logout_url);
    }
    document.title = `Coach Dashboard | ${constants.website_title}`
    const user = useSelector(state => state.auth);

    return user.is_coach === true
        ? children
        : <Navigate to="/" />
}
