import React, {useEffect, useState} from 'react'
import {useSelector} from "react-redux";

export const AlreadyWithSuite = ({submitSuiteSelectionHandler}:any) => {
    const organization_team = useSelector((state:any) => state.organization_team);
    const team = organization_team.data
    const [selectedOptionApplication, setSelectedOptionApplication] = useState<string | null>(null);
    const [showAdvanceOptions, setShowAdvanceOptions] = useState<boolean>(false);

    const suite = team.Suite

    const styleOriginalAppOption = {
        backgroundColor: 'teal',
        color: 'white',
        padding: '4px 7px 7px 7px',
        borderRadius: '10px'
    };

    useEffect(() => {
        setSelectedOptionApplication(suite);
    }, [])

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOptionApplication(event.target.value);
    };

    return (
        <>
            <div className="col-12 d-flex align-items-center">
                <h2 className='d-inline'>Suite Selection</h2>
                <button className='btn btn-teal mx-4' onClick={() => {setShowAdvanceOptions(!showAdvanceOptions)}}>Advanced</button>
            </div>

            {
                showAdvanceOptions &&
                <div className="mx-5">
                    <h5 className='text-muted'>Primary Applications</h5>
                    <label>
                        <input
                            type="radio"
                            value="Executive"
                            checked={selectedOptionApplication === "Executive"}
                            onChange={handleOptionChange}
                            className='mx-1'
                        />
                        <span style={suite === "Executive" ? styleOriginalAppOption : {}}>The Team Collab App</span>
                    </label>

                    <label className="mx-4">
                        <input
                            type="radio"
                            value="Projects"
                            checked={selectedOptionApplication === "Projects"}
                            onChange={handleOptionChange}
                            className='mx-1'
                        />
                        <span style={suite === "Projects" ? styleOriginalAppOption : {}}>The Meeting Collab App</span>
                    </label>

                    <label className='mx-4'>
                        <input
                            type="radio"
                            value="Strategies"
                            checked={selectedOptionApplication === "Strategies"}
                            onChange={handleOptionChange}
                            className='mx-1'
                        />
                        <span style={suite === "Strategies" ? styleOriginalAppOption : {}}>Strategies App</span>
                    </label>

                    <label className='mx-4'>
                        <input
                            type="radio"
                            value="Stakeholders"
                            checked={selectedOptionApplication === "Stakeholders"}
                            onChange={handleOptionChange}
                            className='mx-1'
                        />
                        <span style={suite === "Stakeholders" ? styleOriginalAppOption : {}}>Stakeholders App</span>
                    </label>

                    <br/>
                    <button className='btn btn-teal mt-4' onClick={() => submitSuiteSelectionHandler(selectedOptionApplication)}>Save</button>
                    <div className='mt-2'>
                        <span style={{color: 'red'}}>Typically, once selected, the App will not change.  Each Team uses one App.  For use of additional Apps, create a new team for it's use.</span>
                    </div>
                </div>
            }
        </>
    )
}
