import React from 'react'

export const MenuCell = ({row, handleChangeRoleModal, handleRemoveMember}:any) => {
    return (
        <>
            <button id="dropdownUserMenu" type="button" className="btn btn-teal dropdown-toggle remove-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                ☰
            </button>

            <ul className="dropdown-menu" aria-labelledby="dropdownUserMenu">
                <li className="dropdown-item clickable" onClick={() => {handleChangeRoleModal(row)}}>Change Role</li>
                <li className="dropdown-item clickable" onClick={() => {handleRemoveMember(row)}}>Remove</li>
            </ul>
        </>
    )
}
