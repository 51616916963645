import React, {useEffect, useRef, useState} from 'react'
import axios from "axios";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import {
    faFile,
    faFilePdf,
    faFileWord,
    faFileCsv,
    faFileExcel,
    faFilePowerpoint,
    faPen
} from "@fortawesome/free-solid-svg-icons";
import {getUrlExtension, publicUrl} from "../../../../helpers/files";
// @ts-ignore
import {constants} from "../../../../config/constants";
// @ts-ignore
import {zynity_api} from "../../../../config/external-routes";
import moment from 'moment'

export const FileUploader = ({download_url, setUrl, readonly = false, accept = null}:any) => {
    const authenticated_user = useSelector((state:any) => state.auth)
    const [copyUrl, setCopyUrl] = useState(download_url)
    const [uploading, setUploading] = useState(0)
    const closeModalRef = useRef(null);
    const inputFileRef = useRef(null);
    let modalId = `modal_${parseInt(String(Date.now() * Math.random()))}`;
    let labelId = `modal_lbl_${parseInt(String(Date.now() * Math.random()))}`;
    let base_name = null;
    let fileIcon = (<FontAwesomeIcon icon={faFile} style={{color: "#313131"}}/>);
    let acceptFiles = constants.uploader_allowed_files
    if(accept != null) {
        acceptFiles = accept;
    }

    useEffect(() => {
        if(download_url != undefined && download_url.search(zynity_api.global_download_files) == -1) {
            console.log("goint to change");
            setCopyUrl(publicUrl( `temp_files/${moment().utc().format('YYYY_MM_DD')}/${download_url}`))
        } else if(download_url != undefined) {
            setCopyUrl(download_url);
        }
    }, [download_url])

    if(download_url != undefined) {

        base_name = download_url.split('/').pop();
        base_name = `${base_name.substring(0,11)}...${base_name.substring(base_name.length - 11)}`

        switch (getUrlExtension(download_url)) {
            case 'pdf':
                fileIcon = (<FontAwesomeIcon icon={faFilePdf} style={{color: "#AD0B00"}}/>);
                break;

            case 'doc':
            case 'docx':
                fileIcon = (<FontAwesomeIcon icon={faFileWord} style={{color: "#1D5197"}}/>);
                break;

            case 'csv':
                fileIcon = (<FontAwesomeIcon icon={faFileCsv} style={{color: "#147C44"}}/>);
                break;

            case 'xls':
            case 'xlsb':
            case 'xlsx':
            case 'ods':
                fileIcon = (<FontAwesomeIcon icon={faFileExcel} style={{color: "#147C44"}}/>);
                break;

            case 'ppt':
            case 'pptm':
            case 'pptx':
            case 'rtf':
                fileIcon = (<FontAwesomeIcon icon={faFilePowerpoint} style={{color: "#E94320"}}/>);
                break;
        }
    }

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            let formData = new FormData();
            formData.append('file', e.target.files[0])

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${authenticated_user.access_token}`
                },
                onUploadProgress: function(progressEvent:any) {
                    setUploading(Math.round( (progressEvent.loaded * 100) / progressEvent.total ));
                }
            }

            axios.post(zynity_api.global_upload_temp_files, formData, config).then(response => {
                closeModalRef.current.click()
                setUploading(0);
                setUrl(response.data.file_name)
            }).catch((e) => {
                console.log(e)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Error uploading the file.'
                })
            })
        }
    }

    const autoClickChooseFile = () => {
        inputFileRef.current.click()
    }

    const handleClickOpenModal = () => {
        if(!inputFileRef.current.valueOf().files.length) {
            autoClickChooseFile()
        }
    }

    return (
        <>
            {
                copyUrl !== undefined ?
                    (
                        <div className="imageUploaderComponentContainer">
                            <a href={copyUrl} target='_blank'>{fileIcon} {base_name}</a>
                            {
                                readonly === false &&
                                <FontAwesomeIcon onClick={autoClickChooseFile} icon={faPen} className="edit-btn" data-bs-toggle="modal" data-bs-target={`#${modalId}`}/>
                            }
                        </div>
                    )
                    :
                    <button type="button" onClick={handleClickOpenModal} className="btn btn-sm btn-primary mt-2" data-bs-toggle="modal" data-bs-target={`#${modalId}`}>Upload File</button>
            }
            <div className="modal fade" id={modalId} tabIndex={-1} aria-labelledby={labelId}
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id={labelId}>Upload File</h5>
                            <button ref={closeModalRef} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <input ref={inputFileRef} className="form-control" type="file" id="formFile" accept={acceptFiles} onChange={onSelectFile} />
                            </div>
                            {
                                uploading > 0 &&
                                <div className="progress">
                                    <div
                                        className="progress-bar progress-bar-striped progress-bar-animated"
                                        role="progressbar"
                                        aria-valuenow={0}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                        style={{width: `${uploading}%`}}
                                    >
                                        Uploading {uploading}%
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
