import {startLoading, setUserOrganization} from "./userOrganizationSlice";
import axios from "axios";
import {zynity_api} from "../../config/external-routes";

export const getUserOrganizations = () => {
    return async (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        dispatch(startLoading())

        console.log("calling again?")

        const user = state().auth
        axios.defaults.headers.common = {'Authorization': `Bearer ${user.access_token}`}

        axios.get(zynity_api.get_user_organization_light.replace('{user_id}', user.id))
            .then((response) => {
                dispatch( setUserOrganization(response.data) )
            }).catch((err) => {
            console.log('Eror', err);
        })

        axios.get(zynity_api.get_user_organization.replace('{user_id}', user.id))
            .then((response) => {
                dispatch( setUserOrganization(response.data) )
            }).catch((err) => {
            console.log('Eror', err);
        })
    }
}
