import React from 'react';
import { useSelector } from 'react-redux'
import {Navigate, useParams} from 'react-router-dom';
import {Loading} from "../components/ui/loading/Loading";

export const OrganizationTeamRoute = ({children}:any) => {
    const {organization_id} = useParams();
    const {team_id} = useParams();
    const user_organizations_obj = useSelector((state:any) => state.user_organizations);

    if(user_organizations_obj.isLoading) {
        return (
            <>
                <div className="row loading-center-fixed">
                    <div className="col-12 text-center mb-4">
                        <img src={require('../assets/images/zynity_logo.png')} width="400" alt=""/>
                    </div>
                    <div className="col-12 text-center mt-4">
                        <h2>Loading...<span style={{ fontSize: '16px' }}><Loading /></span></h2>
                    </div>
                </div>
            </>
        )
    }

    const user_organizations = user_organizations_obj.data?.data
    let organization = user_organizations.filter((item:any) => item.id == organization_id)[0];

    let team_found = organization.teams.filter((team:any) => team.id == team_id).length > 0;

    return team_found === true
        ? children
        : <Navigate to="/" />
}
