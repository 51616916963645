import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {ServiceCard} from "./ServiceCard";
import axios from "axios";
import {zynity_api} from "../../../../../../config/external-routes";
import Swal from "sweetalert2";
import {getOrganizationTeam} from "../../../../../../store/user_organizations/teams/team";

export const BillingCycle = ({skus, app_name, disableOptions}: any) => {
    const dispatch = useDispatch();
    const router = useSelector((state:any) => state.router);
    let organization_id = router.selected_organization_id;
    let team_id = router.selected_team_id;
    const user =  useSelector((state: any) => state.auth);
    const organization_team = useSelector((state:any) => state.organization_team);
    const services = useSelector((state:any) => state.services);
    const [selectedBillingCycle, setSelectedBillingCycle] = useState<string | null>('Monthly');
    const [selectedServiceSKU, setSelectedServiceSKU] = useState<string | null>(null);
    const selected_services = organization_team.data.services

    let org_team_url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);

    axios.defaults.headers.common = {'Authorization': `Bearer ${user.access_token}`}

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedBillingCycle(event.target.value);
    };

    const handleServiceOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedServiceSKU(event.target.value);
    };

    useEffect(() => {
        if(selected_services.length > 0) {
            let service = selected_services[0]
            if(service.BillingCycle != undefined) {
                setSelectedBillingCycle(service.BillingCycle)
            }
            setSelectedServiceSKU(service.SKU)
        }
    }, [selected_services])

    if(services.isLoading) {
        return <></>
    }

    if(selected_services.length == 0) {
        return <></>
    }

    const submitBillingCycleHandler = () => {
        let payload = {
            BillingCycle: selectedBillingCycle,
            SKU: selectedServiceSKU,
        }

        axios.post(`${org_team_url}/facilitator_dashboard/suite_selection/billing_cycle`, payload)
            .then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Suite Selection Updated!',
                })
                dispatch(getOrganizationTeam({organization_id , team_id}) )
            }).catch((err) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Try again.'
            })
        })
    }

    return (
        <>
            <div className="col-12">
                <h5 className='fw-bold text-muted'>{app_name}</h5>
            </div>

            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        {
                            disableOptions != true &&
                            <>
                                <h5>Billing Cycle</h5>
                                <label>
                                    <input
                                        type="radio"
                                        value="Monthly"
                                        checked={selectedBillingCycle === "Monthly"}
                                        onChange={handleOptionChange}
                                        className='mx-1'
                                    />
                                    Monthly
                                </label>
                                <label className='mx-2'>
                                    <input
                                        type="radio"
                                        value="Yearly"
                                        checked={selectedBillingCycle === "Yearly"}
                                        onChange={handleOptionChange}
                                        className='mx-1'
                                    />
                                    Yearly
                                </label>
                            </>
                        }


                        <div className="row mt-3">

                            {
                                skus.map((sku:any) => (
                                    <ServiceCard  sku={sku} selectedBillingCycle={selectedBillingCycle}/>
                                ))
                            }

                            {
                                disableOptions === true &&
                                <div className="col-4">The Coach Plan application does not have options and there is no cost. It is an added value app for Certified Coaches.</div>
                            }


                            {
                                disableOptions != true &&
                                <div className="col-12">
                                    <h5>Select an option</h5>
                                    <ul>
                                        {
                                            skus.map((sku:any) => {
                                                const service = services.data.data.find((service: { SKU: any; }) => service.SKU == sku);

                                                return (
                                                    <li>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                value={service.SKU}
                                                                checked={selectedServiceSKU === service.SKU}
                                                                onChange={handleServiceOptionChange}
                                                                className='mx-1'
                                                            />
                                                            {service.Name}
                                                        </label>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>

                                    <button className='btn btn-teal' onClick={submitBillingCycleHandler}>Save</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
