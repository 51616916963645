import React from 'react'
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye, faPen, faTrash} from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import {Link} from 'react-router-dom';

export const ActionDropdown = (props) => {
    const {linkView, linkEdit, linkDelete, messageBeforeToDelete, fetchData, currentPage} = props
    const handleDelete = () => {
        Swal.fire({
            title: messageBeforeToDelete,
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Deleted!', '', 'success')
                const response = axios.delete(linkDelete);
                response.then((success) => {
                    fetchData(currentPage)
                });
            }
        })
    }

    return (
        <>
            <Link
                to={linkView}
                className="mx-2"
                title="View"
            ><FontAwesomeIcon icon={faEye} style={{color: "#494949"}}/></Link>

            <Link
                to={linkEdit}
                className="mx-2"
                title="Edit"
            ><FontAwesomeIcon icon={faPen} style={{color: "#494949"}}/></Link>

            <a
                className="mx-2"
                title="Delete"
                onClick={handleDelete}><FontAwesomeIcon icon={faTrash} style={{color: "#494949"}}/></a>
        </>
    )
}
