import React, {useState, useRef, useEffect} from 'react'
import './imageUpload.scss'
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ModalUploadImage} from "./ModalUploadImage";
import axios from "axios";
import {zynity_api} from "../../../../config/external-routes";
import {useSelector} from "react-redux";
import {dataURLtoFile} from "../../../../helpers/files";
import Swal from "sweetalert2";

export const ImageUploader = ({src = null, aspect = null, setTempImgUrl, readonly = false}: any) => {
    const authenticated_user = useSelector(state => state.auth)
    const [imgSrc, setImageSrc] = useState(src)
    const [imgBase64, setImgBase64] = useState(null)
    const [uploading, setUploading] = useState(0)
    const closeModalRef = useRef(null);
    let modalId = `modal_${parseInt(Date.now() * Math.random())}`;
    let labelId = `modal_lbl_${parseInt(Date.now() * Math.random())}`;

    const handleSubmitFile = () => {
        console.log('image64', imgBase64);
        if(imgBase64 === null)
            return false;

        const file = dataURLtoFile(imgBase64);

        const data = new FormData()
        data.append('file', file, file.name)
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${authenticated_user.access_token}`
            },
            onUploadProgress: function(progressEvent) {
                setUploading(Math.round( (progressEvent.loaded * 100) / progressEvent.total ));
            }
        }
        axios.post(zynity_api.global_upload_temp_files, data, config).then(response => {
            closeModalRef.current.click()
            setUploading(0);
            setTempImgUrl(response.data.file_name)
            setImageSrc(imgBase64)
        }).catch((e) => {
            console.log(e)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Error uploading the image.'
            })
        })
    }

    return (
        <>

            {
                imgSrc != null ?
                    <div className="imageUploaderComponentContainer">
                        <img src={imgSrc} className="img-thumbnail"  alt="img"/>
                        {
                            readonly === false &&
                            <FontAwesomeIcon icon={faPen} className="edit-btn" data-bs-toggle="modal" data-bs-target={`#${modalId}`}/>
                        }

                    </div>
                    :
                    <button type="button" className="btn btn-primary mt-2" data-bs-toggle="modal" data-bs-target={`#${modalId}`}>Upload image</button>
            }
            <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby={labelId}
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id={labelId}>Upload Image</h5>
                            <button ref={closeModalRef} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <ModalUploadImage aspect={aspect} setImgBase64={setImgBase64} />
                            {
                                uploading > 0 &&
                                <div className="progress">
                                    <div
                                        className="progress-bar progress-bar-striped progress-bar-animated"
                                        role="progressbar"
                                        aria-valuenow="0"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{width: `${uploading}%`}}
                                    >
                                        Uploading {uploading}%
                                    </div>
                                </div>
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={handleSubmitFile}>Upload</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
