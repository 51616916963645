import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    setCnASearchHideResults,
    setCnASearchParams
} from "../../../../../store/user_organizations/teams/tools/connect_and_align/cnASearchSlice";
import {SearchResultsByUser} from "./SearchResultsByUser";

export const SearchResults = () => {
    const team_obj = useSelector((state: any) => state.organization_team);
    const dispatch = useDispatch();
    const tool_cna_search = useSelector((state: any) => state.tool_cna_search)

    if(!tool_cna_search.show_results) {
        return <></>
    }

    const closeSearchResultsHandled = () => {
        dispatch(setCnASearchHideResults())
    }

    let selected_user = tool_cna_search?.search_params?.member?.value;

    let member_list;
    if(selected_user == 'Members (All)') {
        member_list = team_obj?.data?.members;
    } else {
        member_list = team_obj?.data?.members?.filter((member: { id: number; }) => (member.id == selected_user))
    }

    return (
        <>
            <div className="card">
                <div className="modal-header">
                    <h5 className="card-title">Search Results</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeSearchResultsHandled}></button>
                </div>
                <div className="card-body container">
                    <div className="row">
                        {
                            member_list.map((member:any) => (
                                <SearchResultsByUser member={member}  />
                            ))
                        }
                    </div>
                </div>
            </div>

        </>
    )
}
