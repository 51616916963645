import * as React from "react"

const CnAIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 10.583 10.583"
    {...props}
  >
    <defs>
      <filter
        style={{
          colorInterpolationFilters: "sRGB",
        }}
        id="b"
        x={-0.244}
        width={1.488}
        y={-0.244}
        height={1.488}
      >
        <feGaussianBlur stdDeviation={0.942} />
      </filter>
      <filter
        style={{
          colorInterpolationFilters: "sRGB",
        }}
        id="a"
        x={-0.027}
        width={1.054}
        y={-0.027}
        height={1.054}
      >
        <feGaussianBlur stdDeviation={0.12} />
      </filter>
    </defs>
    <g
      style={{
        display: "inline",
      }}
      transform="translate(-.285 -.179)"
    >
      <path
        style={{
          display: "inline",
          fill: "#d8d8d8",
          fillOpacity: 1,
          stroke: "#4d4d4d",
          strokeWidth: 0.264469,
          strokeLinecap: "round",
          strokeLinejoin: "bevel",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
          filter: "url(#a)",
        }}
        d="M8.6 2.125c2.97-.926 3.735 9.933-2.11 7.793-4.876 2.46-7.764-.906-5.26-4.358C-3.096-.783 8.875-.95 8.6 2.125Z"
        transform="matrix(.94864 0 0 .94839 .265 .261)"
      />
      <circle
        style={{
          fill: "#fff",
          stroke: "none",
          strokeWidth: 0.263049,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          paintOrder: "markers fill stroke",
          filter: "url(#b)",
        }}
        cx={5.753}
        cy={5.768}
        r={4.631}
        transform="matrix(.87595 0 0 .87595 .277 .454)"
      />
      <g
        style={{
          fill: "#1a37a9",
          fillOpacity: 0.991968,
        }}
        transform="translate(-1.657 .161)"
      >
        <ellipse
          style={{
            fill: "#1a37a9",
            fillOpacity: 0.991968,
            stroke: "none",
            strokeWidth: 1.11584,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "markers fill stroke",
          }}
          cx={10.082}
          cy={3.826}
          rx={0.748}
          ry={0.74}
        />
        <path
          style={{
            fill: "#1a37a9",
            fillOpacity: 0.991968,
            stroke: "none",
            strokeWidth: ".013638px",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: 1,
          }}
          d="M9.332 5.158v2.516h.897c.3 0 .598-.296.598-.592V5.158Z"
        />
        <ellipse
          style={{
            fill: "#1a37a9",
            fillOpacity: 0.991968,
            stroke: "none",
            strokeWidth: 1.78535,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "markers fill stroke",
          }}
          cx={4.838}
          cy={2.501}
          rx={1.196}
          ry={1.184}
        />
        <path
          style={{
            fill: "#1a37a9",
            fillOpacity: 0.991968,
            stroke: "none",
            strokeWidth: ".0235396px",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: 1,
          }}
          d="M3.649 4.27v5.032h1.495c.449 0 .898-.444.898-.888V4.27Z"
        />
        <ellipse
          style={{
            fill: "#1a37a9",
            fillOpacity: 0.991968,
            stroke: "none",
            strokeWidth: 1.4506,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "markers fill stroke",
          }}
          cx={7.618}
          cy={3.156}
          rx={0.972}
          ry={0.962}
        />
        <path
          style={{
            fill: "#1a37a9",
            fillOpacity: 0.991968,
            stroke: "none",
            strokeWidth: ".0177493px",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: 1,
          }}
          d="M6.79 4.714v3.7h1.196c.448 0 .748-.296.748-.74v-2.96Z"
        />
      </g>
    </g>
  </svg>
)

export default CnAIcon
