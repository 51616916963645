import React from 'react'
import ReactPDF, {Document, Page, Text, View, StyleSheet, Image, Svg, Rect, Line, Font} from '@react-pdf/renderer';
import moment from "moment";

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
    body: {
        marginTop: 35,
        paddingBottom: 115,
        paddingHorizontal: 10,
    },
    title: {
        fontSize: 20,
        textAlign: 'center',
        fontFamily: 'Oswald'
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 2,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        fontFamily: 'Oswald',
        marginBottom: 0
    },
    field_name: {
        fontSize: 12,
        marginLeft: 30,
        marginBottom: 0,
        fontFamily: 'Oswald',
    },
    answer: {
        fontSize: 12,
        marginLeft: 50,
        marginBottom: 0
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    logo: {
        marginVertical: 5,
        marginHorizontal: 223,
        width: '150px'
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 60,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
});

export const HowLeadPdf = ({user, formData}: any) => {
    return (
        <Document>
            <Page size="A4" style={styles.body}>
                <Image style={{...styles.logo, marginTop: '-13px'}} src={require('../../../../assets/images/zynity_logo.png')} />
                <Svg height="3" width="600">
                    <Line x1="80" y1="0" x2="500" y2="0" strokeWidth={2} stroke="rgb(38,38,38)"/>
                </Svg>

                <Text style={styles.title}>How I Lead and Manage Treatise</Text>
                <Text style={styles.author}>by</Text>
                <Text style={styles.author}>{`${user.first_name} ${user.last_name}`}</Text>
                <Text style={styles.author}>as of {moment().format('MMMM DD, YYYY')}</Text>

                <Text style={styles.subtitle}>1. Philosophy</Text>
                <Svg height="3" width="600">
                    <Line x1="10" y1="0" x2="570" y2="0" strokeWidth={2} stroke="rgb(79,165,155)"/>
                </Svg>
                <Text style={styles.field_name}>a. Words</Text>
                <Text style={styles.answer}>{formData.answer1.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                <Text style={styles.field_name}>b. Theme</Text>
                <Text style={styles.answer}>{formData.answer2.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                <Text style={styles.field_name}>c. Leadership Statement</Text>
                <Text style={styles.answer}>{formData.answer3.replace(/<\/?[^>]+(>|$)/g, "")}</Text>

                <Text style={styles.subtitle}>2. What we do</Text>
                <Svg height="3" width="600">
                    <Line x1="10" y1="0" x2="570" y2="0" strokeWidth={2} stroke="rgb(79,165,155)"/>
                </Svg>
                <Text style={styles.field_name}>a. Why we do it</Text>
                <Text style={styles.answer}>{formData.answer4.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                <Text style={styles.field_name}>b. Description</Text>
                <Text style={styles.answer}>{formData.answer5.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                <Text style={styles.field_name}>c. How we do it</Text>
                <Text style={styles.answer}>{formData.answer6.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                <Text style={styles.field_name}>d. Core competencies</Text>
                <Text style={styles.answer}>{formData.answer7.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                <Text style={styles.field_name}>e. Problem(s) we solve - Opportunities we create</Text>
                <Text style={styles.answer}>{formData.answer8.replace(/<\/?[^>]+(>|$)/g, "")}</Text>

                <Text style={styles.subtitle}>3. The Markets we serve</Text>
                <Svg height="3" width="600">
                    <Line x1="10" y1="0" x2="570" y2="0" strokeWidth={2} stroke="rgb(79,165,155)"/>
                </Svg>
                <Text style={styles.field_name}>a. Descriptions</Text>
                <Text style={styles.answer}>{formData.answer9.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                <Text style={styles.field_name}>b. What we deliver</Text>
                <Text style={styles.answer}>{formData.answer10.replace(/<\/?[^>]+(>|$)/g, "")}</Text>

                <Text style={styles.subtitle}>4. Our organization</Text>
                <Svg height="3" width="600">
                    <Line x1="10" y1="0" x2="570" y2="0" strokeWidth={2} stroke="rgb(79,165,155)"/>
                </Svg>
                <Text style={styles.field_name}>a. Chart</Text>
                <Text style={styles.answer}>{formData.answer11.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                <Text style={styles.field_name}>b. Character, culture, and core values</Text>
                <Text style={styles.answer}>{formData.answer12.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                <Text style={styles.field_name}>c. Working together effectively and efficiently</Text>
                <Text style={styles.answer}>{formData.answer13.replace(/<\/?[^>]+(>|$)/g, "")}</Text>

                <Text style={styles.subtitle}>5. New Ways and new things</Text>
                <Svg height="3" width="600">
                    <Line x1="10" y1="0" x2="570" y2="0" strokeWidth={2} stroke="rgb(79,165,155)"/>
                </Svg>
                <Text style={styles.field_name}>a. Opportunities and innovations</Text>
                <Text style={styles.answer}>{formData.answer14.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                <Text style={styles.field_name}>b. Prioritizing and implementing strategic initiatives</Text>
                <Text style={styles.answer}>{formData.answer15.replace(/<\/?[^>]+(>|$)/g, "")}</Text>

                <Text style={styles.subtitle}>6. Everyone customer focused at all times</Text>
                <Svg height="3" width="600">
                    <Line x1="10" y1="0" x2="570" y2="0" strokeWidth={2} stroke="rgb(79,165,155)"/>
                </Svg>
                <Text style={styles.field_name}>a. Categories</Text>
                <Text style={styles.answer}>{formData.answer16.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                <Text style={styles.field_name}>b. Keeping the customer’s view</Text>
                <Text style={styles.answer}>{formData.answer17.replace(/<\/?[^>]+(>|$)/g, "")}</Text>

                <Text style={styles.subtitle}>7. Eyes on the numbers in all parts</Text>
                <Svg height="3" width="600">
                    <Line x1="10" y1="0" x2="570" y2="0" strokeWidth={2} stroke="rgb(79,165,155)"/>
                </Svg>
                <Text style={styles.field_name}>a. Budgets, benchmarks, and forecasting</Text>
                <Text style={styles.answer}>{formData.answer18.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                <Text style={styles.field_name}>b. Key Performance Metrics (KPMs)</Text>
                <Text style={styles.answer}>{formData.answer19.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                <Text style={styles.field_name}>c. Reporting</Text>
                <Text style={styles.answer}>{formData.answer20.replace(/<\/?[^>]+(>|$)/g, "")}</Text>

                <Text style={styles.subtitle}>8. Resources</Text>
                <Svg height="3" width="600">
                    <Line x1="10" y1="0" x2="570" y2="0" strokeWidth={2} stroke="rgb(79,165,155)"/>
                </Svg>
                <Text style={styles.field_name}>a. Capital</Text>
                <Text style={styles.answer}>{formData.answer21.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                <Text style={styles.field_name}>b. Individuals</Text>
                <Text style={styles.answer}>{formData.answer22.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                <Text style={styles.field_name}>c. Processes</Text>
                <Text style={styles.answer}>{formData.answer23.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                <Text style={styles.field_name}>d. Technology</Text>
                <Text style={styles.answer}>{formData.answer24.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                <Text style={styles.field_name}>e. Outside Professionals</Text>
                <Text style={styles.answer}>{formData.answer25.replace(/<\/?[^>]+(>|$)/g, "")}</Text>

                <Svg height="3" width="600" style={{...styles.pageNumber, bottom: 75}} fixed>
                    <Line x1="10" y1="0" x2="570" y2="0" strokeWidth={2} stroke="rgb(55,55,55)"/>
                </Svg>
                <Text style={styles.pageNumber} fixed >This Form © Copyright 2017 - {moment().year()} by Zynity, LLC All Rights Reserved. Content belongs to Author.</Text>
            </Page>
        </Document>
    )
}
