import React from 'react'

export const MemberExtraDataColumn = ({row}: any) => {
    return (
        <div>
            <div className="mt-1 mb-1"><span className='text-muted'>Known As:</span> {row.KnownAs}</div>
            <div className="mt-1 mb-1"><span className='text-muted'>Cell:</span> {row.CellPhone}</div>
            <div className="mt-1 mb-1"><span className='text-muted'>City:</span> {row.City}</div>
            <div className="mt-1 mb-1"><span className='text-muted'>State:</span> {row.State}</div>
            <div className="mt-1 mb-1"><span className='text-muted'>Org:</span> {row.OrganizationName}</div>
        </div>
    )
}
