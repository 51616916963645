import * as React from "react"

const CVyooIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 10.583 10.583"
    {...props}
  >
    <defs>
      <filter
        style={{
          colorInterpolationFilters: "sRGB",
        }}
        id="a"
        x={-0.027}
        width={1.054}
        y={-0.027}
        height={1.054}
      >
        <feGaussianBlur stdDeviation={0.12} />
      </filter>
    </defs>
    <g
      style={{
        display: "inline",
      }}
      transform="translate(-.285 -.179)"
    >
      <path
        style={{
          display: "inline",
          fill: "#d8d8d8",
          fillOpacity: 1,
          stroke: "#4d4d4d",
          strokeWidth: 0.264469,
          strokeLinecap: "round",
          strokeLinejoin: "bevel",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
          filter: "url(#a)",
        }}
        d="M8.6 2.125c2.97-.926 3.735 9.933-2.11 7.793-4.876 2.46-7.764-.906-5.26-4.358C-3.096-.783 8.875-.95 8.6 2.125Z"
        transform="matrix(.94855 0 0 .94856 .286 .281)"
      />
      <circle
        style={{
          fill: "#fff",
          stroke: "none",
          strokeWidth: 0.369487,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          paintOrder: "markers fill stroke",
        }}
        cx={5.362}
        cy={5.215}
        r={3.704}
      />
      <g
        style={{
          fill: "#007300",
          stroke: "none",
          strokeOpacity: 1,
          fillOpacity: 1,
        }}
      >
        <path
          d="M8.457 12.559c2.08 0 3.648-1.569 3.648-3.648 0-2.078-1.569-3.647-3.648-3.647-2.078 0-3.647 1.569-3.647 3.647 0 2.08 1.569 3.648 3.647 3.648zm1.043.988H7.415a5.216 5.216 0 0 0-5.21 5.21v1.042H14.71v-1.042a5.216 5.216 0 0 0-5.21-5.21z"
          style={{
            fill: "#007300",
            stroke: "none",
            strokeWidth: 1.04205,
            strokeOpacity: 1,
            fillOpacity: 1,
          }}
          transform="matrix(.38688 0 0 .38688 2.192 .782)"
        />
      </g>
      <g
        transform="matrix(.25514 0 0 .25514 -25.229 -7.447)"
        style={{
          stroke: "#00b506",
          strokeOpacity: 1,
        }}
      >
        <circle
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 0.991968,
            stroke: "#00b506",
            strokeWidth: 4.32064,
            strokeLinecap: "round",
            strokeLinejoin: "bevel",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
            paintOrder: "markers fill stroke",
          }}
          cx={120.098}
          cy={49.682}
          r={14.683}
        />
        <path
          style={{
            fill: "none",
            stroke: "#00b506",
            strokeWidth: 4,
            strokeLinecap: "round",
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          d="m131.946 61.515 7.513 7.867"
        />
      </g>
    </g>
  </svg>
)

export default CVyooIcon
