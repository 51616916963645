import axios from 'axios'

export const getRequest = async (url, access_token) => {
    axios.defaults.headers.common = {'Authorization': `Bearer ${access_token}`}
    let result = false;
    await axios.get(url)
        .then((response) => {
            result = response.data;
        }).catch((error) => {
            throw error
        })

        return result;
}