import React, {useState} from 'react'
import {Header} from "../../../../header/Header";
import {FacilitatorHeader} from "../FacilitatorHeader";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {zynity_api} from "../../../../../config/external-routes";
import {publicUrl} from "../../../../../helpers/files";
import Swal from "sweetalert2";
import {getOrganizationTeam} from "../../../../../store/user_organizations/teams/team";

export const AddMember = () => {
    const navigateTo = useNavigate()
    const dispatch = useDispatch();
    const router = useSelector((state:any) => state.router);
    let organization_id = router.selected_organization_id;
    let team_id = router.selected_team_id;
    const user_organizations_obj = useSelector((state:any) => state.user_organizations);
    const user_organizations = user_organizations_obj.data?.data
    let organization = user_organizations.find((item:any) => item.id == organization_id)
    let team = organization.teams.find((item:any) => item.id == team_id)
    const [email, setEmail] = useState('')
    let org_team_url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);

    const addMemberHandle = (e:any) => {
        e.preventDefault()

        let payload = {
            email: email
        }

        axios.post(`${org_team_url}/facilitator_dashboard/add_member`, payload)
            .then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: response.data.msg,
                })
                dispatch(getOrganizationTeam({organization_id , team_id}) )
                navigateTo('/facilitator-dashboard')
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'We have problems add!',
                })
            })
    }

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-2 mb-5">
                    <div className="col-12 col-md-9">
                        <div className="row">
                            <FacilitatorHeader page_name={'Add Member'} />
                        </div>
                    </div>
                    <div className="col-md-3 col-12 mt-3">
                        <Link to="/facilitator-dashboard/manage-members" className='btn btn-teal'>‹ Back</Link>
                    </div>

                    <div className="col-12 mt-4">
                        <p style={{fontSize: '20px'}}>Enter the email address of the person to add to your team <b>({team.TeamName})</b>.</p>
                        <p>Note: If the requested member does not have a user account with Zynity.com, they will be be sent an email request to complete the sign up process. The record will be created, but will not become active until they complete the emailed request.</p>
                    </div>

                    <div className="col-12 col-md-3">
                        <form onSubmit={addMemberHandle}>
                            <div className="mb-3">
                                <label htmlFor="inviteEmail" className="form-label fw-bold">Email Address *</label>
                                <input type="email" required={true} className="form-control" id="inviteEmail" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={'Invite email'}/>
                            </div>
                            <button className='btn btn-teal' type='submit'>Submit</button>
                        </form>
                    </div>

                    <div className="col-12 mt-5">
                        <div className="card border-warning mb-3" style={{maxWidth: '40rem'}}>
                            <div className="card-body">
                                <h5 className="card-title text-danger">Important Note</h5>
                                <p className="card-text">When inviting new team members to Zynity -
                                    <u>inform them to add <b>noreply@zynity.com</b> to their address book</u>
                                    This is to ensure delivery of email messages, and not sent to the Spam folder.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
