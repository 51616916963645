import React from 'react'
// @ts-ignore
import CnAIcon from '../../../assets/components_icons/CnAIcon';
// @ts-ignore
import CVyooIcon from '../../../assets/components_icons/CVyooIcon';
// @ts-ignore
import ProjectIcon from '../../../assets/components_icons/ProjectIcon';
// @ts-ignore
import StratIcon from '../../../assets/components_icons/StratIcon';
import { zynity_website } from '../../../config/external-routes';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

export const Team = ({team, org_id}:any) => {
    let team_name = team.TeamName
    let suite = team.Suite

    const renderToolIcon = (suite:string) => {
        let svgStyle = { width: '50px', height: '100%', marginTop: '-5px', marginLeft: '5px', position: "absolute" };
        switch (suite) {
            case 'Executive':
                return <CnAIcon style={svgStyle} />
            case 'Stakeholders':
                return <CVyooIcon style={svgStyle} />
            case 'Strategies':
                return <StratIcon style={svgStyle} />
            case 'Projects':
                return <ProjectIcon style={svgStyle} />
            case 'Measurements':
                return <StratIcon style={svgStyle} />
            case 'CoachPlan':
            case 'CoachKPI':
                return <img className='d-inline' src={require('../../../assets/images/coach_notes_icon.png')} alt="" style={{width: '70px', marginLeft: '-5px', position: "absolute"}}/>
            default:
                return <ProjectIcon style={svgStyle} />
        }
    }

    return (
        <>
            <div className="card mx-3 mb-1 org-team-component">
                <div className="card-body">
                    <div className="row">
                        {renderToolIcon(suite)}
                        <div className="col-6" style={{marginLeft: "40px"}}>
                            <form id={`goToToolForm${team.id}`} className="d-none" method="post" action={`${zynity_website.yii_website_url}/coach`}>
                                <input type="hidden" name="teamId" value={team.id}/>
                                <input type="hidden" name="orgId" value={org_id}/>
                                <input type="hidden" name="teamAction" value='goToTools'/>
                            </form>
                            <button
                                onClick={() => document.getElementById(`goToToolForm${team.id}`).submit() }
                                type="button"
                                className="btn btn-outline-secondary team-redirect-button"
                            >{team_name}</button>
                        </div>
                        <div className="col-2 d-flex align-items-center">
                            {/*Notes*/}
                            <form id={`goToNotesForm${team.id}`} className="d-none" method="post" action={`${zynity_website.yii_website_url}/tools/CoachNotes`}>
                                <input type="hidden" name="teamId" value={team.id}/>
                                <input type="hidden" name="orgId" value={org_id}/>
                                <input type="hidden" name="notes" value='Notes'/>
                            </form>
                            <Link
                                to={`/organizations/${org_id}/teams/${team.id}/coach/notes`}
                                title="View"
                            ><button
                                type="button" className="btn btn-warning btn-sm mx-3">Notes</button></Link>


                            {/*Assess*/}
                            <form id={`goToAssessmentsForm${team.id}`} className="d-none" method="post" action={`${zynity_website.yii_website_url}/coach/Assessments`}>
                                <input type="hidden" name="teamId" value={team.id}/>
                                <input type="hidden" name="orgId" value={org_id}/>
                                <input type="hidden" name="assess" value='Assess'/>
                            </form>
                            <button
                                onClick={() => document.getElementById(`goToAssessmentsForm${team.id}`).submit() }
                                type="button" className="btn btn-warning btn-sm mx-3">Assess</button>

                            {/*Stats*/}
                            <form id={`goToStatsForm${team.id}`} className="d-none" method="post" action={`${zynity_website.yii_website_url}/coach/engagement`}>
                                <input type="hidden" name="teamId" value={team.id}/>
                                <input type="hidden" name="orgId" value={org_id}/>
                                <input type="hidden" name="stats" value='Stats'/>
                            </form>
                            <button
                                onClick={() => document.getElementById(`goToStatsForm${team.id}`).submit() }
                                type="button" className="btn btn-warning btn-sm mx-3">Stats</button>

                            <form id={`goToDashboardForm${team.id}`} className="d-none" method="post" action={`${zynity_website.yii_website_url}/coach`}>
                                <input type="hidden" name="teamId" value={team.id}/>
                                <input type="hidden" name="orgId" value={org_id}/>
                                <input type="hidden" name="teamMenuAction" value='FacilitatorDashboard'/>
                            </form>
                            <img
                                onClick={() => document.getElementById(`goToDashboardForm${team.id}`).submit() }
                                style={{cursor: 'pointer'}}
                                title='Facilitator Dashboard'
                                src={require('../../../assets/images/FacilitatorIcon.png')} className="mx-3" width="40"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
