import React from 'react'
import {useSelector} from "react-redux";

export const ServiceCard = ({sku, selectedBillingCycle}:any) => {
    const services = useSelector((state:any) => state.services);
    if(services.isLoading) {
        return <></>
    }

    const service = services.data.data.find((service: { SKU: any; }) => service.SKU == sku);

    let fee = service.Fee
    let feeMember = service.FeeMember
    if(selectedBillingCycle == 'Yearly') {
        fee = service.FeeY
        feeMember = service.FeeMemberY
    }

    return (
        <div className="col-4">
            <div className="card" style={{border: 'solid 2px teal', boxShadow: '3px 3px 5px 6px #ccc'}}>
                <div className="card-body text-center">
                    <div className={'fw-bold'}><u>{service.Name}</u></div>
                    <div>${fee} (base app fee)</div>
                    <div>${feeMember} /member/mo</div>
                    {
                        service.TrialPeriod > 0 &&
                        <div>{service.TrialPeriod} day trial period</div>
                    }

                    <div className='fw-bold mt-3' style={{letterSpacing: '5px', fontSize: '12px'}}><u>INCLUDES</u></div>
                    <div dangerouslySetInnerHTML={{__html: service.Description}}/>
                </div>
            </div>
        </div>
    )
}
