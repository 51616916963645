import React, {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import {Header} from "../../../header/Header";
import {OrganizationAdminHeader} from "../OrganizationAdminHeader";
import {useParams} from "react-router-dom";
import {zynity_api, zynity_website} from "../../../../config/external-routes";
import axios from "axios";
import FilterComponent from "../../../ui/FilterComponent";
import {TeamMembersColumn} from "../teams/TeamMembersColumn";
import {TeamServicesColumn} from "../teams/TeamServicesColumn";
import DataTable from "react-data-table-component";
import {CoachNameColumn} from "./CoachNameColumn";
import {Button, Modal} from "react-bootstrap";
import {AssignOrgCoachColumn} from "./AssignOrgCoachColumn";

export const ManageCoaches = () => {
    const {organization_id} = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingCoachFullList, setLoadingCoachFullList] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [totalRowsCoachFullList, setTotalRowsCoachFullList] = useState(0);
    const [perPage, setPerPage] = useState(15);
    const [perPageCoachFullList, setPerPageCoachFullList] = useState(7);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageCoachFullList, setCurrentPageCoachFullList] = useState(1);
    const [filterText, setFilterText] = React.useState(null);
    const [filterTextCoachFullList, setFilterTextCoachFullList] = React.useState(null);

    const [fullListCoaches, setFullListCoaches] = useState([]);
    const [showFullListCoachesModal, setShowFullListCoachesModal] = useState(false);

    let organization_coaches_url = zynity_api.organization_coaches.replace('{organization_id}', organization_id);
    let full_list_coaches_url = zynity_api.admin_coaches;

    const fetchCoaches = async (page: any) => {
        setLoading(true);
        setCurrentPage(page);
        let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
        const response = await axios.get(`${organization_coaches_url}?page=${page}&limit=${perPage}${filter}`);

        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const fetchFullListCoaches = async (page: any) => {
        setLoadingCoachFullList(true);
        let filter = filterTextCoachFullList !== null && filterTextCoachFullList !== '' ? `&search_text=${filterTextCoachFullList}` : '';
        const response = await axios.get(`${full_list_coaches_url}?page=${page}&limit=${perPageCoachFullList}${filter}`);
        setFullListCoaches(response.data.data);
        setTotalRowsCoachFullList(response.data.total);
        setLoadingCoachFullList(false);
    }

    const handlePageChange = (page: any) => {
        fetchCoaches(page);
    };

    const handlePageChangeCoachFullList = (page: any) => {
        fetchFullListCoaches(page);
    };

    const handlePerRowsChange = async (newPerPage: React.SetStateAction<number>, page: React.SetStateAction<number>) => {
        setLoading(true);
        setCurrentPage(page);
        let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
        const response = await axios.get(`${organization_coaches_url}?page=${page}&limit=${newPerPage}${filter}`);

        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const handlePerRowsChangeCoachFullList = async (newPerPage: React.SetStateAction<number>, page: React.SetStateAction<number>) => {
        setLoadingCoachFullList(true);
        setCurrentPageCoachFullList(page);
        let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
        const response = await axios.get(`${full_list_coaches_url}?page=${page}&limit=${newPerPage}${filter}`);

        setFullListCoaches(response.data.data);
        setPerPageCoachFullList(newPerPage);
        setLoadingCoachFullList(false);
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            setFilterText('');
        };

        const handleFilter = (e: { target: { value: any; }; }) => {
            setFilterText(e.target.value)
        }

        return (
            <FilterComponent onFilter={handleFilter} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText]);

    const subHeaderComponentMemoCoachFullList = React.useMemo(() => {
        const handleClear = () => {
            setFilterTextCoachFullList('');
        };

        const handleFilter = (e: { target: { value: any; }; }) => {
            setFilterTextCoachFullList(e.target.value)
        }

        return (
            <FilterComponent onFilter={handleFilter} onClear={handleClear} filterText={filterTextCoachFullList} />
        );
    }, [filterTextCoachFullList]);

    const columns = [
        {
            name: 'Name',
            cell: (row: any) => <CoachNameColumn row={row} organization_id={organization_id} />
        },
        {
            name: 'Email',
            cell: (row: any) => row.Email
        },
        {
            name: 'Phone',
            cell: (row: any) => row.Phone,
            allowOverflow: true
        },
        {
            name: 'Action',
            cell: (row: any) => <button type="button" className="btn btn-teal btn-sm"
                                        onClick={() => removeOrgCoach(row.id, `${row.FirstName} ${row.LastName}`)}>Remove</button>
        },
    ];

    const columns_full_coaches_list = [
        {
            name: 'Name',
            cell: (row: any) => <CoachNameColumn row={row} organization_id={organization_id} />
        },
        {
            name: 'Email',
            cell: (row: any) => row.Email
        },
        {
            name: 'Action',
            cell: (row: any) => <AssignOrgCoachColumn row={row} organization_coaches_list={data} assignOrgCoachMethod={assignOrgCoach} />
        },
    ];

    console.log("fullListCoaches", fullListCoaches);

    useEffect(() => {
        fetchCoaches(1)
        fetchFullListCoaches(1)
    }, []);

    useEffect(() => {
        if(filterText !== null) {
            const timeOutId = setTimeout(() => fetchCoaches(1), 500);
            return () => clearTimeout(timeOutId);
        }
    }, [filterText]);

    useEffect(() => {
        if(filterTextCoachFullList !== null) {
            const timeOutId = setTimeout(() => fetchFullListCoaches(1), 500);
            return () => clearTimeout(timeOutId);
        }
    }, [filterTextCoachFullList]);

    const removeOrgCoach = (coach_id: any, coach_name: any) =>
    {
        console.log("going to delete: ",coach_id )
        Swal.fire({
            title: `Confirm Removing ${coach_name}`,
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Deleted!', '', 'success')
                const response = axios.delete(`${organization_coaches_url}/${coach_id}`);
                response.then((success) => {
                    fetchCoaches(currentPage)
                });
            }
        })
    }

    const assignOrgCoach = (coach_id: any) =>
    {
        console.log("going to add: ",coach_id )
        document.getElementById(`button_assign_coach_${coach_id}`).innerHTML = "Adding...";
        let params = {
            coach_id: coach_id
        };
        const response = axios.post(`${organization_coaches_url}`, params);
        response.then((success) => {
            Swal.fire('Coach Added!', '', 'success')
            fetchCoaches(currentPage)
            fetchFullListCoaches(currentPageCoachFullList)
            handleClose()
        });
    }

    const handleClose = () => setShowFullListCoachesModal(false);

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-2 mb-5">
                    <div className="col-12 col-md-9">
                        <div className="row">
                            <OrganizationAdminHeader page_name='Manage Coaches' />
                        </div>
                    </div>

                    <div className="col-12 col-md-3 align-self-center text-end">
                        <button type="button" className="btn btn-primary" onClick={() => setShowFullListCoachesModal(true)}>Add Coach</button>

                        <Modal show={showFullListCoachesModal} onHide={handleClose} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>Assign Certified Coach</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="text-muted">Complete coach profiles are available at <a target='_blank' href={`${zynity_website.public}/coachList`}>Zynity Certified Coaches</a>.</div>
                                <div className="row">
                                    <div className="col-12 data-table-container">
                                        <DataTable
                                            columns={columns_full_coaches_list}
                                            data={fullListCoaches}
                                            progressPending={loadingCoachFullList}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRowsCoachFullList}
                                            onChangeRowsPerPage={handlePerRowsChangeCoachFullList}
                                            onChangePage={handlePageChangeCoachFullList}
                                            paginationPerPage={perPageCoachFullList}
                                            subHeader
                                            subHeaderComponent={subHeaderComponentMemoCoachFullList}
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>

                    <div className="col-12 data-table-container">
                        <DataTable
                            className='datatable_no_overflow'
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationPerPage={perPage}
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
