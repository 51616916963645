
export const constants:any = {
    envs: {
        local: 'local',
        dev: 'dev',
    },
    website_title: 'Zynity Leadership',
    zynity_api_access_token_cookie_name: process.env.REACT_APP_ZYNITY_SESSION_COOKIE_NAME,
    admin_contents: {
        user_news: 'UserNews'
    },

    // Quill Editor Default Modules configuration: https://github.com/zenoamaro/react-quill
    quillDefaultModules: {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{ 'color': [] }, { 'background': [] }],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link'],
            ['clean']
        ]
    },
    quillDefaultFormats: [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote', 'color', 'background',
        'list', 'bullet', 'indent',
        'link'
    ],

    uploader_allowed_files: 'image/*,.pdf,.doc,.docx'
}
