import React from 'react'
import {Link, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {setRoute} from "../../../../store/routes";
import {setDatumIdOpen, setDatumIdOpenData} from "../../../../store/user_organizations/teams/tools/connect_and_align";

export const FacilitatorHeader = ({page_name}:any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const router = useSelector((state:any) => state.router);
    let organization_id = router.selected_organization_id;
    let team_id = router.selected_team_id;
    const user_organizations_obj = useSelector((state:any) => state.user_organizations);
    const user_organizations = user_organizations_obj.data?.data
    let organization = user_organizations.find((item:any) => item.id == organization_id)
    let team = organization.teams.find((item:any) => item.id == team_id)

    const handleMouseOver = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.currentTarget.style.background = '#d8d8d8';
    };

    const handleMouseOut = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.currentTarget.style.background = '#f8f8f8';
    };

    const handleGoToTeam = () => {
        if(team?.Suite == undefined) return false

        dispatch(setRoute(null))
        let suite = team.Suite.toLowerCase()
        if(suite === 'executive') {
            dispatch(setDatumIdOpen(0));
            dispatch(setDatumIdOpenData(undefined));
            navigate(`/organizations/${organization_id}/teams/${team.id}/CnA`)
        }
        return false;
    }

    return (
        <div className="col-12 user-main-tittle d-flex align-items-center align-self-start">
            <span style={{fontSize: '40px', color: 'SaddleBrown'}}>
                <img src={require('../../../../assets/images/FacilitatorIcon.png')} width="45" />
            </span>
            <span style={{fontSize: '24px', marginTop: '5px'}} className='mx-2'>{page_name} <span
                style={{borderRadius: '12px', color: 'purple', padding: '0px 12px', backgroundColor: 'rgb(248, 248, 248)', cursor: 'pointer'}}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                onClick={handleGoToTeam}
            ><b>{team.TeamName}</b></span></span>

            <span style={{marginTop: '10px'}}><Link to={`/`} style={{textDecoration: 'none', color: 'black'}}><b>({organization.name})</b></Link></span>
            <div className="dropdown d-inline-block mx-2 mt-2">
                <button className="btn btn-teal dropdown-toggle remove-arrow" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><Link to={`/facilitator-dashboard`} className="dropdown-item">Facilitator Dashboard</Link></li>
                    <li><Link to={`/facilitator-dashboard/team-profile`} className="dropdown-item">Team Profile</Link></li>
                    <li><Link to={`/facilitator-dashboard/manage-members`} className="dropdown-item">Manage Members</Link></li>
                    <li><Link to={`/facilitator-dashboard/manage-tools`} className="dropdown-item">Manage Apps</Link></li>
                    <li><Link to={`/facilitator-dashboard/manage-coaches`} className="dropdown-item">Assign Coach</Link></li>
                    <li><Link to={`/facilitator-dashboard/remove-team`} className="dropdown-item">Remove Team</Link></li>
                </ul>
            </div>
        </div>
    )
}
