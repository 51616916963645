import React from 'react'
import {Button, Form, Modal} from "react-bootstrap";
import ModalDialog from 'react-bootstrap/ModalDialog';
import Draggable from 'react-draggable';

class DraggableModalDialog extends React.Component {
    render() {
        return <Draggable handle=".draggableHandler"><ModalDialog {...this.props} />
        </Draggable>
    }
}

export const ReminderModal = ({showCreateModal, handleCloseModal, submitHandled, isNewReminder, reminder, setReminder, disableSubmitBtn}:any) => {
    return (
        <Modal show={showCreateModal} onHide={handleCloseModal} size="lg" dialogAs={DraggableModalDialog} style={{cursor: 'grab'}}>
            <Form>
                <Modal.Header closeButton className='bg-teal draggableHandler'>
                    <Modal.Title>{isNewReminder ? 'Add Reminder' : 'Update Reminder'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='row d-flex align-items-center'>
                    <textarea onChange={(e) => {setReminder(e.target.value)}}>{reminder}</textarea>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    {
                        isNewReminder === false &&
                        <Button className='btn-light' onClick={() => {submitHandled('delete')}} disabled={disableSubmitBtn}>
                            Delete
                        </Button>
                    }
                    <Button className='btn-teal' onClick={() => {submitHandled('save')}} disabled={disableSubmitBtn}>
                        {isNewReminder ? 'Save' : 'Update'}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
