import React from 'react';
import { useSelector } from 'react-redux'
import {Navigate, useParams} from 'react-router-dom';
import {Loading} from "../components/ui/loading/Loading";
import {constants} from "../config/constants";
import {zynity_website} from "../config/external-routes";
// @ts-ignore
import Cookies from 'js-cookie'

export const OrganizationRoute = ({children}:any) => {
    let access_token = Cookies.get(constants.zynity_api_access_token_cookie_name);
    if(access_token == undefined) {
        window.location.replace(zynity_website.yii_website_logout_url);
    }
    const {organization_id} = useParams();
    const user_organizations_obj = useSelector((state:any) => state.user_organizations);

    if(user_organizations_obj.isLoading) {
        return (
            <>
                <div className="row loading-center-fixed">
                    <div className="col-12 text-center mb-4">
                        <img src={require('../assets/images/zynity_logo.png')} width="400" alt=""/>
                    </div>
                    <div className="col-12 text-center mt-4">
                        <h2>Loading...<span style={{ fontSize: '16px' }}><Loading /></span></h2>
                    </div>
                </div>
            </>
        )
    }

    const user_organizations = user_organizations_obj.data?.data
    let user_organization_found = user_organizations.filter((item:any) => item.id == organization_id).length > 0;

    return user_organization_found === true
        ? children
        : <Navigate to="/" />
}
