import { createSlice } from '@reduxjs/toolkit'

export interface UserState {
    id: number,
    login_id: string,
    first_name: string,
    last_name: string,
    know_as: string,
    email: string,
    visibility_profile: string,
    organization_name: string,
    phone: string,
    mobile_phone: string,
    address: string,
    address_2: string,
    city: string,
    state: string,
    country: string,
    time_zone: string,
    is_coach: boolean,
    has_assessment: boolean,
    coach: object,
    access_token: string,
    isLoading: boolean,
    redirectToLoginPage: boolean,
}

const initialState: UserState = {
    id: null,
    login_id: null,
    first_name: null,
    last_name: null,
    know_as: null,
    email: null,
    visibility_profile: null,
    organization_name: null,
    phone: null,
    mobile_phone: null,
    address: null,
    address_2: null,
    city: null,
    state: null,
    country: null,
    time_zone: null,
    is_coach: null,
    has_assessment: null,
    coach: null,
    access_token: null,
    isLoading: true,
    redirectToLoginPage: false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        setUser: (state, action) => {
            state.id = action.payload.id
            state.login_id = action.payload.LoginID
            state.first_name = action.payload.FirstName
            state.last_name = action.payload.LastName
            state.know_as = action.payload.KnownAs
            state.email = action.payload.EmailAddress
            state.visibility_profile = action.payload.VisibilityProfile
            state.organization_name = action.payload.OrganizationName
            state.phone = action.payload.Phone
            state.mobile_phone = action.payload.CellPhone
            state.address = action.payload.Address
            state.address_2 = action.payload.Address2
            state.city = action.payload.City
            state.state = action.payload.State
            state.country = action.payload.Country
            state.time_zone = action.payload.TimeZone
            state.is_coach = action.payload.isCoach
            state.has_assessment = action.payload.hasAssessment
            state.coach = action.payload.coach
            state.access_token = action.payload.access_token
            state.isLoading = false
        },
        setLogOut: (state) => {
            state = initialState
            state.redirectToLoginPage = true;
        }
    },
})

// Action creators are generated for each case reducer function
export const { startLoading, setUser, setLogOut } = authSlice.actions
