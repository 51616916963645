import React from 'react'

export const TeamServicesColumn = ({row}: any) => {
    if(row.services.length < 1) {
        return (<div className="text-muted">No active subscriptions</div>)
    }
    return (
        <div>
            {
                row.services.map((field: any) => {
                    return (
                        <div className="dropdown mt-1 mb-1">
                            <span className="dropdown-toggle"  id={field.id + 1}
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                {field.Name}
                            </span>
                            <div className="dropdown-menu p-4 text-muted" style={{width: '370px'}} aria-labelledby={field.id + 1}>
                                <button style={{float: 'right'}} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                                <div>SKU: {field.SKU}</div>
                                <div>${field.Fee} {field.Basis}</div>
                                <div dangerouslySetInnerHTML={{__html: field.Description}}/>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}
