import React from 'react'

export const CountsCard = ({ adminCounts }) => {
    return (
        <div className="card">
            <ul className="list-group list-group-flush">
                {
                    adminCounts.map(count => (
                        <li className="list-group-item"><h5>{count.title} - <span class="badge bg-success">{count.total}</span></h5></li>
                    ))
                }
            </ul>
        </div>
    )
}
