import React, {useEffect} from 'react'
import {FacilitatorHeader} from "./FacilitatorHeader";
import {Header} from "../../../header/Header";
import {Link} from "react-router-dom";

export const Dashboard = () => {
    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-2 mb-5">
                    <div className="col-12 col-md-9">
                        <div className="row">
                            <FacilitatorHeader page_name='Facilitator Dashboard' />
                            <div className="col-12">
                                <Link to={`/facilitator-dashboard`}><button type="button" className="btn btn-teal">Facilitator Dashboard</button></Link>
                                <p className='mt-2' style={{fontSize: '17px', fontFamily: 'Open Sans, sans-serif', lineHeight: '1.8em'}}>The Team Facilitator manages the Team within the Zynity framework. This includes general messaging and communications, managing members and managing the Apps available. This page, which only you and other assigned facilitators have access to, serves as your dashboard to perform the functions shown below.</p>
                            </div>

                            <div className="col-12">
                                <Link to={`/facilitator-dashboard/team-profile`}><button type="button" className="btn btn-teal">Team Profile</button></Link>
                                <p className='mt-2' style={{fontSize: '17px', fontFamily: 'Open Sans, sans-serif', lineHeight: '1.8em'}}>Edit the Team name and general messaging.</p>
                            </div>

                            <div className="col-12">
                                <Link to={`/facilitator-dashboard/manage-members`}><button type="button" className="btn btn-teal">Manage Members</button></Link>
                                <p className='mt-2' style={{fontSize: '17px', fontFamily: 'Open Sans, sans-serif', lineHeight: '1.8em'}}>Invite and remove members. Team members can function in the following Roles: Regular, Viewer and Facilitator.</p>
                            </div>

                            <div className="col-12">
                                <Link to={`/facilitator-dashboard/manage-tools`}><button type="button" className="btn btn-teal">Manage Apps</button></Link>
                                <p className='mt-2' style={{fontSize: '17px', fontFamily: 'Open Sans, sans-serif', lineHeight: '1.8em'}}>Each team on Zynity will have a singular focus - defined by the selected application in use.</p>
                                <div className="mx-2">
                                    There are four primary Apps to choose from...
                                    <ul style={{ marginLeft: '40px' }}>
                                        <li>Collaboration App - Connect &amp; Align executive leadership</li>
                                        <li>Highlights App - High level view of projects and activities</li>
                                        <li>Strategies App - Evaluating and implementing the next big things</li>
                                        <li>Stakeholders App - Customers view as a digital operations manual</li>
                                        <li>Reporting App - Reports and KPI's management</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-12">
                                <Link to={`/facilitator-dashboard/manage-coaches`}><button type="button" className="btn btn-teal">Assign Coach</button></Link>
                                <p className='mt-2' style={{fontSize: '17px', fontFamily: 'Open Sans, sans-serif', lineHeight: '1.8em'}}>Zynity Certified Coaches are a great resource when using the Zynity Online Apps. Coaches must first be assigned to the Organization by the Org Administrator. After they appear in your organization as authorized, then a team Facilitator can add the coach to any of your organization's teams. They are usually designated as a 'Viewer' so they can engage with the team as needed.</p>
                            </div>

                            <div className="col-12">
                                <Link to={`/facilitator-dashboard/remove-team`}><button type="button" className="btn btn-teal">Remove Team</button></Link>
                                <p className='mt-2' style={{fontSize: '17px', fontFamily: 'Open Sans, sans-serif', lineHeight: '1.8em'}}>Use this action to remove a Team from active use. The Team records will remain in the Zynity database for a period of time and can be reactivated by request to the Zynity Webmaster.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-3 mt-2">
                        <div className="card">
                            <div className="card-header text-center" style={{backgroundColor: 'teal', color: 'white', fontWeight: 'bold'}}>
                                Team Menu
                            </div>
                            <ul className="list-group list-group-flush">
                                <Link to={`/facilitator-dashboard`} className="list-group-item list-group-item-action">Facilitator Dashboard</Link>
                                <Link to={`/facilitator-dashboard/team-profile`} className="list-group-item list-group-item-action">Team Profile</Link>
                                <Link to={`/facilitator-dashboard/manage-members`} className="list-group-item list-group-item-action">Manage Members</Link>
                                <Link to={`/facilitator-dashboard/manage-tools`} className="list-group-item list-group-item-action">Manage Apps</Link>
                                <Link to={`/facilitator-dashboard/manage-coaches`} className="list-group-item list-group-item-action">Assign Coach</Link>
                                <Link to={`/facilitator-dashboard/remove-team`} className="list-group-item list-group-item-action">Remove Team</Link>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
