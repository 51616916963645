import React, {useEffect, useState} from 'react'

export const WithoutSuite = ({submitSuiteSelectionHandler}:any) => {
    const [selectedOptionApplication, setSelectedOptionApplication] = useState<string | null>(null);

    let originalApplicationOption = 'Executive'

    useEffect(() => {
        setSelectedOptionApplication(originalApplicationOption);
    }, [])

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOptionApplication(event.target.value);
    };

    return (
        <>
            <div className="col-12 d-flex align-items-center">
                <h2 className='d-inline'>Suite Selection</h2>
            </div>

            <div className="mx-5">
                <label>
                    <input
                        type="radio"
                        value="Executive"
                        checked={selectedOptionApplication === "Executive"}
                        onChange={handleOptionChange}
                        className='mx-1'
                    />
                    <span>The Team Collab App</span>
                </label>

                <label className='mx-4'>
                    <input
                        type="radio"
                        value="Projects"
                        checked={selectedOptionApplication === "Projects"}
                        onChange={handleOptionChange}
                        className='mx-1'
                    />
                    <span>The Meeting Collab App</span>
                </label>

                <label className='mx-4'>
                    <input
                        type="radio"
                        value="Strategies"
                        checked={selectedOptionApplication === "Strategies"}
                        onChange={handleOptionChange}
                        className='mx-1'
                    />
                    <span>Strategies App</span>
                </label>


                <label className='mx-4'>
                    <input
                        type="radio"
                        value="Stakeholders"
                        checked={selectedOptionApplication === "Stakeholders"}
                        onChange={handleOptionChange}
                        className='mx-1'
                    />
                    <span>Stakeholders App</span>
                </label>

                {/*<label className='mx-4'>
                    <input
                        type="radio"
                        value="Measurements"
                        checked={selectedOptionApplication === "Measurements"}
                        onChange={handleOptionChange}
                        className='mx-1'
                    />
                    <span>Measurements App</span>
                </label>

                <label className='mx-4'>
                    <input
                        type="radio"
                        value="Projects"
                        checked={selectedOptionApplication === "Projects"}
                        onChange={handleOptionChange}
                        className='mx-1'
                    />
                    <span>Highlights App</span>
                </label>*/}

                <br/>
                <button className='btn btn-teal mt-4' onClick={() => submitSuiteSelectionHandler(selectedOptionApplication)}>Save</button>
            </div>
        </>
    )
}
