import React, {useState} from 'react'
import Swal from "sweetalert2";
import {Header} from "../../../header/Header";
import {OrganizationAdminHeader} from "../OrganizationAdminHeader";
import {TextAreaInput} from "../../../user/menu/how_to_lead/TextAreaInput";
import {useForm} from "../../../hooks/useForm";
import axios from "axios";
import {Navigate, useParams} from "react-router-dom";
import {zynity_api} from "../../../../config/external-routes";
import {useSelector} from "react-redux";

export const RemoveOrganization = () => {
    const {organization_id} = useParams();
    const user_organizations_obj = useSelector((state:any) => state.user_organizations);
    let user_organizations = user_organizations_obj.data?.data
    let organization = user_organizations.filter((item:any) => item.id == organization_id)[0];
    const [formData, setFormDatum]: any = useForm({description: ''})
    const [submitRemoveTitle, setSubmitRemoveTitle] = useState(`Send - Remove ${organization.name} Request.`);
    const [redirectToHome, setRedirectToHome] = useState(false);

    const submitRemoveOrgHandled = () => {
        setSubmitRemoveTitle("Sending request...")
        const response = axios.post(`${zynity_api.organizations}/${organization_id}/request-remove`, formData);
        response.then((success) => {
            Swal.fire('Success!', 'Your request has been sent.', 'success')
            setRedirectToHome(true)
        }).catch((err) => {
            if(err.response?.data.error !== undefined) {
                Swal.fire('Error!', err.response.data.error, 'error')
            } else {
                Swal.fire('Error!', 'An internal error occur in our system. please try again.', 'error')
            }
            setSubmitRemoveTitle(`Send - Remove ${organization.name} Request.`)
        });
    }

    if(redirectToHome) {
        return <Navigate to="/" />
    }

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-2 mb-5">
                    <div className="col-12 col-md-9">
                        <div className="row">
                            <OrganizationAdminHeader page_name='Remove Organization' />
                        </div>
                    </div>
                    <div className="col-12">
                        An email request will be sent to the Zynity Webmaster to remove this organization.
                        You will be copied on the email.
                    </div>
                    <div className="col-12 col-lg-6 mt-2">
                        <h5>Briefly describe why you want to remove this organization.</h5>
                        <TextAreaInput placeholder='Add here your notes...' key_name='description' value={formData.description} setFormDatum={setFormDatum} />
                        <button type="button" className="btn btn-teal btn-sm mt-3" onClick={submitRemoveOrgHandled}>{submitRemoveTitle}</button>
                    </div>
                </div>
            </div>
        </>
    )
}
