import React, {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useSelector, useDispatch } from 'react-redux'
import CnAIcon from '../../../../../assets/components_icons/CnAIcon';
import CVyooIcon from '../../../../../assets/components_icons/CVyooIcon';
import ProjectIcon from '../../../../../assets/components_icons/ProjectIcon';
import StratIcon from '../../../../../assets/components_icons/StratIcon';
import { zynity_api, zynity_website } from '../../../../../config/external-routes';
import './team.scss';
import { ZMail } from './z-mail/ZMail';
import {getUserOrganizations} from "../../../../../store/user_organizations";
import {setRoute, setRouteSelectedOrgId, setRouteSelectedTeamId} from "../../../../../store/routes";
import {getOrganizationTeam} from "../../../../../store/user_organizations/teams/team";
import strike from "quill/formats/strike";
import {
    setDatumIdOpen,
    setDatumIdOpenData
} from "../../../../../store/user_organizations/teams/tools/connect_and_align";

export const Team = ({ team, org_id }) => {
    const navigate = useNavigate();
    const yii_url = zynity_website.yii_website_url;
    const user = useSelector(state => state.auth);
    const dispatch = useDispatch();
    let z_mail_all_read = false;

    team.z_mail?.map(z_mail => (
        z_mail_all_read = z_mail.viewed == 'Yes' && true
    ))

    const [zMailAllRead, setZMailAllRead] = useState(z_mail_all_read)

    const setViewedZMailByTeam = (team_id) => {
        if(zMailAllRead)
            return true;
        
        setZMailAllRead(true);
        let zmail_url = zynity_api.user_organization_team_set_z_mail_as_viewed
        .replace('{user_id}', user.id)
        .replace('{organization_id}', org_id)
        .replace('{team_id}', team_id);

        axios.defaults.headers.common = {'Authorization': `Bearer ${user.access_token}`}
        axios.post(zmail_url)
        .then((response) => {
            //dispatch( getUserOrganizations() )
        }).catch((err) => {
            setZMailAllRead(false);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Try again.'
              })
        })
    }
    
    const goToFacilitatorDashboardHandled = (org_id, team_id) => {
        dispatch(getOrganizationTeam({organization_id:org_id , team_id}) );
        dispatch(setRouteSelectedOrgId(org_id))
        dispatch(setRouteSelectedTeamId(team_id))
        navigate('facilitator-dashboard')
    }

    const goToTools = (suite) => {
        dispatch(setRoute(null))
        suite = suite.toLowerCase()
        if(suite === 'executive') {
            dispatch(setDatumIdOpen(0));
            dispatch(setDatumIdOpenData(undefined));
            navigate(`organizations/${org_id}/teams/${team.id}/CnA`)
        }
        return false;
    }

    return (
        <>
            <div className="card mx-3 mb-1 org-team-component">
                <div className="card-body">
                    <div className="row">
                        {
                            {
                                'Executive': <CnAIcon style={{ width: '50px', height: '100%', marginRight: '-10px' }} />,
                                'Stakeholders': <CVyooIcon style={{ width: '50px', height: '100%', marginRight: '-10px' }} />,
                                'Strategies': <StratIcon style={{ width: '50px', height: '100%', marginRight: '-10px' }} />,
                                'Projects': <ProjectIcon style={{ width: '50px', height: '100%', marginRight: '-10px' }} />,
                                'Measurements': <StratIcon style={{ width: '50px', height: '100%', marginRight: '-10px' }} />,
                                'CoachKPI': <img style={{ width: '55px', height: '100%', marginRight: '-14px', marginTop: '2px' }} src={require('../../../../../assets/images/coach_notes_icon.png')}></img>,
                                'CoachPlan': <img style={{ width: '55px', height: '100%', marginRight: '-14px', marginTop: '2px' }} src={require('../../../../../assets/images/coach_notes_icon.png')}></img>
                            }[team.Suite]
                        }
                        <div className="col-6 px-0">
                            <form id={`goToToolForm${team.id}`} className="d-none" method="post" action={`${yii_url}/userHome/index`}>
                                <input type="hidden" name="teamId" value={team.id}></input>
                                <input type="hidden" name="orgId" value={org_id}></input>
                                <input type="hidden" name="teamAction" value='goToTools'></input>
                            </form>
                            {
                                team.Suite != undefined && team.Suite.toLowerCase() === 'executive' ?
                                    <button type="button" onClick={() => goToTools(team.Suite)} className="btn btn-outline-secondary team-redirect-button">{team.TeamName}</button>
                                    :
                                    <button type="button" onClick={() => document.getElementById(`goToToolForm${team.id}`).submit()} className="btn btn-outline-secondary team-redirect-button">{team.TeamName}</button>
                            }
                        </div>
                        <div className="col-2 d-flex align-items-center">
                            {
                                team.Suite != undefined &&
                                <div onClick={() => {setViewedZMailByTeam(team.id)}} className="notify-badge-item collapsed" data-bs-toggle="collapse" data-bs-target={`#flush-collapse-team-zmail${team.id}`} aria-expanded="false" aria-controls="flush-collapseOne">
                                    {
                                        team.z_mail?.length > 0 &&
                                        <span className={'notify-badge ' + (zMailAllRead == true ? 'bg-secondary' : '')}>{team.z_mail.length}</span>
                                    }

                                    <img data-toggle="tooltip" className="team-icons" title="show Z-mail" src={require('../../../../../assets/images/ZmailIcon.png')} alt="Z-mail"></img>
                                </div>
                            }

                            {
                                team.is_facilitator &&
                                (
                                    <>
                                        {/*<form id={`facilitatorForm${team.id}`} className="d-none" method="post" action={`${yii_url}/userHome/index`}>
                                            <input type="hidden" name="teamId" value={team.id}></input>
                                            <input type="hidden" name="orgId" value={org_id}></input>
                                            <input type="hidden" name="teamMenuAction" value='FacilitatorDashboard'></input>
                                        </form>
                                        <img onClick={() => document.getElementById(`facilitatorForm${team.id}`).submit()} data-toggle="tooltip" className="team-icons mx-2" src={require('../../../../../assets/images/FacilitatorIcon.png')}></img>*/}
                                        <img onClick={() => goToFacilitatorDashboardHandled(org_id, team.id)} data-toggle="tooltip" className="team-icons mx-2" src={require('../../../../../assets/images/FacilitatorIcon.png')}></img>
                                    </>
                                )
                            }
                            {
                                team.Suite != undefined && team.is_coach &&
                                <>
                                    <Link to="/coach">
																			<img src={require('../../../../../assets/images/coach_notes_icon.png')} width="36"></img>
																		</Link>
					                      </>
                            }

                            {
                                team.Suite != undefined && team.total_notifications > 0 &&
                                <span className="badge rounded-pill bg-danger badge-total-notifications" data-bs-toggle="tooltip" data-bs-placement="top" title="New Content">{team.total_notifications}</span>
                            }
                        </div>
                        {
                            team.Suite == undefined &&
                            <div className="col-12" style={{color: '#FFB226', fontWeight: 'bold'}}>
                                You must select a suite for this team. Use the Facilitator link, then 'Manage Apps'.
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div id={`flush-collapse-team-zmail${team.id}`} className="row accordion-collapse collapse card mx-3 mb-1 org-team-component org-team-z-mail-collapse-container" aria-labelledby={`flush-collapse-team-zmail${team.id}`} data-bs-parent="#accordionFlushExample">
                <div className="card-body">
                    <div className="row">
                        {
                            team.z_mail?.length > 0 ?
                                (
                                    team.z_mail?.map(z_mail => (
                                        <ZMail z_mail={z_mail} team_id={team.id} org_id={org_id} key={z_mail.id} />
                                    ))
                                ) :
                                (
                                    <div style={{ marginLeft: '10px' }}>No new messages</div>
                                )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
