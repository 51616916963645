import React, {useEffect, useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import {Header} from "../../../header/Header";
import {zynity_api} from "../../../../config/external-routes";
import {getRequest} from "../../../../helpers/getRequest";
import {useForm} from "../../../hooks/useForm";
import {useParams} from "react-router-dom";
import {Loading} from "../../../ui/loading/Loading";
import {FormServiceDetails} from "./FormServiceDetails";

export const ServiceDetails = ({readOnly = true}) => {
	const {service_id} = useParams()
	const authenticated_user = useSelector(state => state.auth)
	const [readonly, setReadonly] = useState(readOnly)
	const [loading, setLoading] = useState(true)
	const [data, setFormDatum, setData] = useForm({})

	useEffect(() => {
		getRequest(`${zynity_api.admin_services}/${service_id}`, authenticated_user.access_token).then((response) => {
			setData(response)
			setLoading(false)
		})
	}, [])

	const handleSubmitForm = (event) => {
		event.preventDefault();
		axios.defaults.headers.common = {'Authorization': `Bearer ${authenticated_user.access_token}`}
		axios.put(`${zynity_api.admin_services}/${service_id}`, data)
			.then((response) => {
				Swal.fire({
					icon: 'success',
					title: 'Success!',
					text: response.data.message,
				})
			})
			.catch((error) => {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'We have problems updating the Service!',
				})
			})
	}

	return (
		<>
			<Header />
			<div className="container">
				<div className="row">
					<div className="col-6 col-md-3 user-main-tittle">
						<h4>Service Details</h4>
					</div>
					<div className="col-6 col-md-3 mt-3">
					
						<a href="/admin/z-admin">Admin Home</a> &nbsp; &nbsp; 
						<a href="/admin/services">Services Home</a>
					</div>
				</div>

				<div className="row mt-4 d-flex justify-content-center">
					<div className="col-12 col-md-6">
						<div className="card">
							<div className="card-body">
								<div className="card-title row">
									<div className="col-6">
										<h5 className="card-title">Service</h5>
									</div>
									{
										readonly &&
										<div className="col-6 text-end">
											<FontAwesomeIcon onClick={() => setReadonly(false)}
												title="Edit" icon={faPen}
												style={{
													fontSize: "16px",
													color: "#4c4c4c",
													cursor: "pointer"
												}}
											/>
										</div>
									}
								</div>
								{
									loading ?
										(<Loading />)
										:
										<FormServiceDetails
											data={data}
											readonly={readonly}
											setFormDatum={setFormDatum}
											onSubmit={handleSubmitForm}
										/>
								}
							</div>
							{
								readonly === false &&
								<div className="card-footer bg-transparent d-flex flex-row-reverse">
									<button type="submit"
											form="personalDataForm"
											className="btn btn-primary">Update
									</button>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
