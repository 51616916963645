import React, {useEffect} from 'react'
import {useSelector} from "react-redux";
import {Navigate, useNavigate, useParams} from "react-router-dom";

export const RedirectBase64 = () => {
    const router = useSelector((state:any) => state.router)
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);

    // Get specific query parameters
    const iParam = searchParams.get('i');

    useEffect(() => {
        if(iParam == null) {
            navigate('/')
        } else {
            let iBase64Decoded = atob(iParam);
            const resultArray = iBase64Decoded.split('_');


            let route = router.specific_route;
            if(window.location.href.includes('/collab')) {
                route = `/organizations/${resultArray[0]}/teams/${resultArray[1]}/collab`
                console.log("route", route)
            }
            navigate(route)
        }

    }, [])
    return (
        <>
        </>
    )
}
