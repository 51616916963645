import React from 'react'
import {TextAreaInput} from "./TextAreaInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {HowLeadPdf} from "./HowLeadPdf";

export const HowLeadForm = ({formData, setFormDatum, handleSubmit, user}: any) => {
    return (
        <>
            <div className='col-12 mt-4' id="top">
                <div className="row">
                    <div className="col-6"><h4>1. Philosophy</h4></div>
                    <div className="col-6">
                        <PDFDownloadLink document={<HowLeadPdf user={user} formData={formData} />} fileName="zynity_how_to_lead.pdf">
                            <button type="button" className="btn btn-success">Download PDF</button>
                        </PDFDownloadLink>
                    </div>
                </div>
                <hr style={{borderBottom: '4px solid #12887a', width: '100%', marginTop: '6px', marginBottom: '8px', float: 'left'}}/>
            </div>
            <div className="col-12 col-md-8">
                <h5>a. Words</h5>
                <TextAreaInput key_name='answer1' value={formData.answer1} setFormDatum={setFormDatum} />
                <h5 className='mt-3'>b. Theme</h5>
                <TextAreaInput key_name='answer2' value={formData.answer2} setFormDatum={setFormDatum} />
                <h5 className='mt-3'>c. Leadership Statement</h5>
                <TextAreaInput key_name='answer3' value={formData.answer3} setFormDatum={setFormDatum} />
            </div>
            <div className="col-12 col-md-4">
                <div className="lead_pop_up radialGrad">
                    <h5>Philosophy, rightly defined, is simply the love of wisdom.</h5>
                    <p>─Marcus Tullius Cicero</p>
                </div>
            </div>
            <div className="col-12 mt-3"><button type="button" className="btn btn-success" onClick={handleSubmit}>Submit</button></div>


            <div className='col-12 mt-4' id="top">
                <div className="row">
                    <div className="col-6"><h4>2. What we do</h4></div>
                </div>
                <hr style={{borderBottom: '4px solid #12887a', width: '100%', marginTop: '6px', marginBottom: '8px', float: 'left'}}/>
            </div>
            <div className="col-12 col-md-8">
                <h5>a. Why we do it</h5>
                <TextAreaInput key_name='answer4' value={formData.answer4} setFormDatum={setFormDatum} />
                <h5 className='mt-3'>b. Description</h5>
                <TextAreaInput key_name='answer5' value={formData.answer5} setFormDatum={setFormDatum} />
                <h5 className='mt-3'>c. How we do it</h5>
                <TextAreaInput key_name='answer6' value={formData.answer6} setFormDatum={setFormDatum} />
                <h5 className='mt-3'>d. Core competencies</h5>
                <TextAreaInput key_name='answer7' value={formData.answer7} setFormDatum={setFormDatum} />
                <h5 className='mt-3'>e. Problem(s) we solve - Opportunities we create</h5>
                <TextAreaInput key_name='answer8' value={formData.answer8} setFormDatum={setFormDatum} />
            </div>
            <div className="col-12 col-md-4">
                <div className="lead_pop_up radialGrad">
                    <h5>You will be infinitely more successful if you contribute to the success of others.</h5>
                    <p>─Ann Marie Houghtailing</p>
                </div>
            </div>
            <div className="col-12 mt-3"><button type="button" className="btn btn-success" onClick={handleSubmit}>Submit</button></div>


            <div className='col-12 mt-4' id="top">
                <div className="row">
                    <div className="col-6"><h4>3. The Markets we serve</h4></div>
                </div>
                <hr style={{borderBottom: '4px solid #12887a', width: '100%', marginTop: '6px', marginBottom: '8px', float: 'left'}}/>
            </div>
            <div className="col-12 col-md-8">
                <h5>a. Descriptions</h5>
                <TextAreaInput key_name='answer9' value={formData.answer9} setFormDatum={setFormDatum} />
                <h5 className='mt-3'>b. What we deliver</h5>
                <TextAreaInput key_name='answer10' value={formData.answer10} setFormDatum={setFormDatum} />
            </div>
            <div className="col-12 col-md-4">
                <div className="lead_pop_up radialGrad">
                    <h5>The aim of marketing is to know and understand the customer so well the product or service fits him and sells itself.</h5>
                    <p>─Peter Drucker</p>
                </div>
            </div>
            <div className="col-12 mt-3"><button type="button" className="btn btn-success" onClick={handleSubmit}>Submit</button></div>


            <div className='col-12 mt-4' id="top">
                <div className="row">
                    <div className="col-6"><h4>4. Our organization</h4></div>
                </div>
                <hr style={{borderBottom: '4px solid #12887a', width: '100%', marginTop: '6px', marginBottom: '8px', float: 'left'}}/>
            </div>
            <div className="col-12 col-md-8">
                <h5>a. Chart</h5>
                <TextAreaInput key_name='answer11' value={formData.answer11} setFormDatum={setFormDatum} />
                <h5 className='mt-3'>b. Character, culture, and core values</h5>
                <TextAreaInput key_name='answer12' value={formData.answer12} setFormDatum={setFormDatum} />
                <h5 className='mt-3'>c. Working together effectively and efficiently</h5>
                <TextAreaInput key_name='answer13' value={formData.answer13} setFormDatum={setFormDatum} />
            </div>
            <div className="col-12 col-md-4">
                <div className="lead_pop_up radialGrad">
                    <h5>Every company has two organizational structures: The formal one is written on the charts; the other is the everyday relationship of the men and women in the organization.</h5>
                    <p>─Harold S. Geneen</p>
                </div>
            </div>
            <div className="col-12 mt-3"><button type="button" className="btn btn-success" onClick={handleSubmit}>Submit</button></div>


            <div className='col-12 mt-4' id="top">
                <div className="row">
                    <div className="col-6"><h4>5. New Ways and new things</h4></div>
                </div>
                <hr style={{borderBottom: '4px solid #12887a', width: '100%', marginTop: '6px', marginBottom: '8px', float: 'left'}}/>
            </div>
            <div className="col-12 col-md-8">
                <h5>a. Opportunities and innovations</h5>
                <TextAreaInput key_name='answer14' value={formData.answer14} setFormDatum={setFormDatum} />
                <h5 className='mt-3'>b. Prioritizing and implementing strategic initiatives</h5>
                <TextAreaInput key_name='answer15' value={formData.answer15} setFormDatum={setFormDatum} />
            </div>
            <div className="col-12 col-md-4">
                <div className="lead_pop_up radialGrad">
                    <h5>It is not enough to be busy; so are the ants. The question is: what are we busy about?</h5>
                    <p>─Henry David Thoreau</p>
                </div>
            </div>
            <div className="col-12 mt-3"><button type="button" className="btn btn-success" onClick={handleSubmit}>Submit</button></div>


            <div className='col-12 mt-4' id="top">
                <div className="row">
                    <div className="col-6"><h4>6. Everyone customer focused at all times</h4></div>
                </div>
                <hr style={{borderBottom: '4px solid #12887a', width: '100%', marginTop: '6px', marginBottom: '8px', float: 'left'}}/>
            </div>
            <div className="col-12 col-md-8">
                <h5>a. Categories</h5>
                <TextAreaInput key_name='answer16' value={formData.answer16} setFormDatum={setFormDatum} />
                <h5 className='mt-3'>b. Keeping the customer’s view</h5>
                <TextAreaInput key_name='answer17' value={formData.answer17} setFormDatum={setFormDatum} />
            </div>
            <div className="col-12 col-md-4">
                <div className="lead_pop_up radialGrad">
                    <h5>A business is simply an idea to make other individuals’ lives better.</h5>
                    <p>─Richard Branson</p>
                </div>
            </div>
            <div className="col-12 mt-3"><button type="button" className="btn btn-success" onClick={handleSubmit}>Submit</button></div>


            <div className='col-12 mt-4' id="top">
                <div className="row">
                    <div className="col-6"><h4>7. Eyes on the numbers in all parts</h4></div>
                </div>
                <hr style={{borderBottom: '4px solid #12887a', width: '100%', marginTop: '6px', marginBottom: '8px', float: 'left'}}/>
            </div>
            <div className="col-12 col-md-8">
                <h5>a. Budgets, benchmarks, and forecasting</h5>
                <TextAreaInput key_name='answer18' value={formData.answer18} setFormDatum={setFormDatum} />
                <h5 className='mt-3'>b. Key Performance Metrics (KPMs)</h5>
                <TextAreaInput key_name='answer19' value={formData.answer19} setFormDatum={setFormDatum} />
                <h5 className='mt-3'>c. Reporting</h5>
                <TextAreaInput key_name='answer20' value={formData.answer20} setFormDatum={setFormDatum} />
            </div>
            <div className="col-12 col-md-4">
                <div className="lead_pop_up radialGrad">
                    <h5>If a measurement matters at all, it is because it must have some conceivable effect on decisions and behaviour.</h5>
                    <p>─Douglas W. Hubbard</p>
                </div>
            </div>
            <div className="col-12 mt-3"><button type="button" className="btn btn-success" onClick={handleSubmit}>Submit</button></div>


            <div className='col-12 mt-4' id="top">
                <div className="row">
                    <div className="col-6"><h4>8. Resources</h4></div>
                </div>
                <hr style={{borderBottom: '4px solid #12887a', width: '100%', marginTop: '6px', marginBottom: '8px', float: 'left'}}/>
            </div>
            <div className="col-12 col-md-8">
                <h5>a. Capital</h5>
                <TextAreaInput key_name='answer21' value={formData.answer21} setFormDatum={setFormDatum} />
                <h5 className='mt-3'>b. Individuals</h5>
                <TextAreaInput key_name='answer22' value={formData.answer22} setFormDatum={setFormDatum} />
                <h5 className='mt-3'>c. Processes</h5>
                <TextAreaInput key_name='answer23' value={formData.answer23} setFormDatum={setFormDatum} />
                <h5 className='mt-3'>d. Technology</h5>
                <TextAreaInput key_name='answer24' value={formData.answer24} setFormDatum={setFormDatum} />
                <h5 className='mt-3'>e. Outside Professionals</h5>
                <TextAreaInput key_name='answer25' value={formData.answer25} setFormDatum={setFormDatum} />
            </div>
            <div className="col-12 col-md-4">
                <div className="lead_pop_up radialGrad">
                    <h5>Success is neither magical nor mysterious. Success is the natural consequence of consistently applying the basic fundamentals.</h5>
                    <p>─Jim Rohn</p>
                </div>
            </div>
            <div className="col-12 mt-3"><button type="button" className="btn btn-success" onClick={handleSubmit}>Submit</button></div>
        </>
    )
}
