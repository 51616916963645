import {startLoading, setServices} from "./servicesSlice";
import axios from "axios";
import {zynity_api} from "../../../config/external-routes";

export const getServices = () => {
    return async (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        dispatch(startLoading())

        const user = state().auth
        axios.defaults.headers.common = {'Authorization': `Bearer ${user.access_token}`}
        axios.get(`${zynity_api.url}/services`)
            .then((response) => {
                dispatch( setServices(response.data) )
            }).catch((err) => {
            console.log('Error', err);
        })
    }
}
