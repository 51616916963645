import { createSlice } from '@reduxjs/toolkit'
import moment from "moment";

export interface CnAState {
    isLoading: boolean,
    isLoadingFullData: boolean,
    isLoadingArchivedData: boolean,
    data: object,
    full_data: object,
    archived_data: object,
    datum_id_open: number,
    datum_id_open_data: number,
    pastDate: string
}

const initialState: CnAState = {
    isLoading: true,
    isLoadingFullData: true,
    isLoadingArchivedData: true,
    data: null,
    full_data: null,
    archived_data: null,
    datum_id_open: 0,
    datum_id_open_data: undefined,
    pastDate: moment().utc().endOf("month").format("YYYY-MM-DD")
}

export const connectAndAlignSlice = createSlice({
    name: 'tool_cna',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        startLoadingFullData: (state) => {
            state.isLoadingFullData = true;
        },
        setData: (state, action) => {
            state.data = action.payload
            state.isLoading = false
        },
        setFullData: (state, action) => {
            state.data = action.payload
            state.isLoadingFullData = false
        },
        setArchiveData: (state, action) => {
            state.archived_data = action.payload
            state.isLoadingArchivedData = false
        },
        setDatumIdOpen: (state, action) => {
            state.datum_id_open = action.payload
        },
        setDatumIdOpenData: (state, action) => {
            state.datum_id_open_data = action.payload
        },
        setPastDate: (state, action) => {
            state.pastDate = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { startLoading, startLoadingFullData, setData, setFullData, setArchiveData, setDatumIdOpen, setDatumIdOpenData, setPastDate } = connectAndAlignSlice.actions
