import { createSlice } from '@reduxjs/toolkit'

export interface RouterState {
    specific_route: string,
    selected_team_id: number,
    selected_organization_id: number,
}

const initialState: RouterState = {
    specific_route: null,
    selected_team_id: null,
    selected_organization_id: null,
}

export const routerSlice = createSlice({
    name: 'router',
    initialState,
    reducers: {
        setRoute: (state, action) => {
            state.specific_route = action.payload
        },
        setRouteSelectedTeamId: (state, action) => {
            state.selected_team_id = action.payload
        },
        setRouteSelectedOrgId: (state, action) => {
            state.selected_organization_id = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setRoute, setRouteSelectedTeamId, setRouteSelectedOrgId } = routerSlice.actions
