import * as React from "react"

const StratIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    viewBox="0 0 10.583 10.583"
    {...props}
  >
    <defs>
      <linearGradient id="a">
        <stop
          style={{
            stopColor: "#fff",
            stopOpacity: 1,
          }}
          offset={0}
        />
        <stop
          style={{
            stopColor: "#fff",
            stopOpacity: 0,
          }}
          offset={1}
        />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="c"
        x1={5.159}
        y1={5.3}
        x2={2.44}
        y2={8.155}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(.89424 0 0 .95104 .373 .047)"
      />
      <filter
        style={{
          colorInterpolationFilters: "sRGB",
        }}
        id="d"
        x={-0.234}
        width={1.467}
        y={-0.234}
        height={1.467}
      >
        <feGaussianBlur stdDeviation={0.407} />
      </filter>
      <filter
        style={{
          colorInterpolationFilters: "sRGB",
        }}
        id="b"
        x={-0.027}
        width={1.054}
        y={-0.027}
        height={1.054}
      >
        <feGaussianBlur stdDeviation={0.12} />
      </filter>
    </defs>
    <path
      style={{
        display: "inline",
        fill: "#d8d8d8",
        fillOpacity: 1,
        stroke: "#4d4d4d",
        strokeWidth: 0.264469,
        strokeLinecap: "round",
        strokeLinejoin: "bevel",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
        filter: "url(#b)",
      }}
      d="M8.6 2.125c2.97-.926 3.735 9.933-2.11 7.793-4.876 2.46-7.764-.906-5.26-4.358C-3.096-.783 8.875-.95 8.6 2.125Z"
      transform="matrix(.94855 0 0 .94856 0 .103)"
    />
    <rect
      style={{
        opacity: 1,
        fill: "url(#c)",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 0.0488,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        paintOrder: "markers fill stroke",
      }}
      width={7.325}
      height={8.248}
      x={1.659}
      y={0.956}
      rx={0}
      ry={0}
    />
    <circle
      style={{
        display: "inline",
        opacity: 1,
        fill: "#f5ff2d",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 0.0522721,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        paintOrder: "markers fill stroke",
        filter: "url(#d)",
      }}
      cx={3.406}
      cy={7.519}
      r={2.091}
      transform="translate(-.363 -1.173) scale(1.13065)"
    />
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        style={{
          display: "inline",
          fill: "none",
          stroke: "#666",
          strokeWidth: 0.804677,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M1.944 1.134v3.583m7.308-3.583v8.192M1.944 1.134h7.308M6.566 9.326h2.686"
        transform="translate(-.285 -.179)"
      />
      <g
        style={{
          stroke: "#666",
        }}
      >
        <path
          style={{
            display: "inline",
            fill: "none",
            stroke: "#666",
            strokeWidth: 0.79375,
            strokeLinecap: "round",
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          d="M3.327 3.389h5.058M5.936 5.479h2.45M8.16 7.569h.225"
          transform="translate(-.575 -.46)"
        />
      </g>
      <path
        d="M4.753 4.245h-1.96v2.282H.508v1.96h2.287v2.282h1.96V8.487H7.04v-1.96H4.753Z"
        style={{
          fill: "#009fde",
          fillOpacity: 1,
          stroke: "#000",
          strokeWidth: 0.132292,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
        }}
        transform="translate(-.285 -.179)"
      />
    </g>
  </svg>
)

export default StratIcon
