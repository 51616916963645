import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';
import {constants} from "../config/constants";
import {zynity_website} from "../config/external-routes";
// @ts-ignore
import Cookies from 'js-cookie'

export const AdminRoute = ({children}) => {
    let access_token = Cookies.get(constants.zynity_api_access_token_cookie_name);
    if(access_token == undefined) {
        window.location.replace(zynity_website.yii_website_logout_url);
    }

    const user = useSelector(state => state.auth);

    return user.visibility_profile === 'Zadmin'
        ? children
        : <Navigate to="/" />
}
