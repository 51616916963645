import React, {useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {Button, Modal} from "react-bootstrap";
import {Team} from "./Team";
import { zynity_website } from '../../../config/external-routes';

export const Accordion = ({org, user}:any) => {
    const [show, setShow] = useState(false);
    const [coachModalFormAction, setCoachModalFormAction] = useState('CoachKPI');
    let id = org.id;
    let name = org.name;
    let hasPlan = org.has_coach_plan;
    let hasKPI = org.has_coach_kpi;
    let totalNotifications = org.total_notifications;

    console.log(org)

    const [modalInfo, setModalInfo] = useState({
        type: 'plan',
        title: 'Create Coach Plan - (Zynity, LLC)',
        body: `Click <b>'Save'</b> to create a Coach Plan for this Organization.
               <br>
               The Coach KPI is presented using the <b>Highlights App</b>
               <br>
               Once created the Coach KPI will show as a Team on your Community view.`,
    });

    const handleClose = () => setShow(false);

    const handleSubmitCoachForm = () => {
        document.getElementById(`modalCoachForm${id}`).submit()
        setShow(false)
    };

    const handleShow = (type:string) => {
        switch (type) {
            case 'plan':
                if(hasPlan === true) {
                    document.getElementById(`goToCoachPlanForm${id}`).submit()
                    return false
                }
                setModalInfo({
                    ...modalInfo,
                    type: 'plan',
                    title: 'Create Coach Plan - (Zynity, LLC)',
                    body: `Click 'Save' to create a Coach Plan for this Organization.<br>
                           The Coach Plan is presented using the <b>Highlights App</b>.<br>
                           Once created the Coach Plan will show as a Team on your Community view.`
                })
                setCoachModalFormAction('CoachPlan')
                setShow(true)
                break;
            case 'kpi':
                if(hasKPI === true) {
                    document.getElementById(`goToKPIForm${id}`).submit()
                    return false;
                }
                setModalInfo({
                    type: 'kpi',
                    title: 'Create Coach KPI - (Zynity, LLC)',
                    body: `Click <b>'Save'</b> to create a Coach KPI for this Organization.
                               <br>
                               The Coach KPI is presented using the <b>Highlights App</b>.
                               <br>
                               Once created the Coach KPI will show as a Team on your Community view.`,
                })
                setCoachModalFormAction('CoachKPI')
                setShow(true)
                break;
        }


    };
    return (
        <>
            <div className="accordion accordion-flush user_home_body_accordion mt-1">
                <div className="accordion-item">
                    <h2 className="accordion-header" id={`accordionOrgId${id}`}>
                        <div className="row pt-1">
                            <div className="col-7 col-md-6">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse-org${id}`} aria-expanded="false" aria-controls="flush-collapseOne">
                                    {name}
                                </button>
                            </div>
                            <div className="col-5 col-md-6 d-flex align-items-center">
                                <div className="hoverable d-flex align-items-center mx-1">
                                    <form id={`goToCoachPlanForm${id}`} className="d-none" method="post" action={`${zynity_website.yii_website_url}/coach`}>
                                        <input type="hidden" name="orgId" value={id}/>
                                        <input type="hidden" name="coachId" value={user.coach.id}/>
                                        <input type="hidden" name="coachMemberId" value={user.id}/>
                                        <input type="hidden" name="menuAction" value='goToCoachPlan'/>
                                    </form>
                                    <button
                                        type="button"
                                        className="btn dropdown-toggle remove-arrow"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={() => handleShow('plan')}
                                    >Plans</button>
                                </div>
                                <div className="hoverable d-flex align-items-center mx-1">
                                    <form id={`goToKPIForm${id}`} className="d-none" method="post" action={`${zynity_website.yii_website_url}/coach`}>
                                        <input type="hidden" name="orgId" value={id}/>
                                        <input type="hidden" name="coachId" value={user.coach.id}/>
                                        <input type="hidden" name="coachMemberId" value={user.id}/>
                                        <input type="hidden" name="menuAction" value='goToCoachKPI'/>
                                    </form>
                                    <button
                                        type="button"
                                        className="btn dropdown-toggle remove-arrow"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={() => handleShow('kpi')}
                                    >KPIs</button>
                                </div>
                                <div className="hoverable d-flex align-items-center mx-1">
                                    <form id={`goToROIForm${id}`} className="d-none" method="post" action={`${zynity_website.yii_website_url}/coach`}>
                                        <input type="hidden" name="orgId" value={id}/>
                                        <input type="hidden" name="coachId" value={user.coach.id}/>
                                        <input type="hidden" name="coachMemberId" value={user.id}/>
                                        <input type="hidden" name="menuAction" value='ROI'/>
                                    </form>
                                    <button
                                        type="button"
                                        className="btn dropdown-toggle remove-arrow"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={() => document.getElementById(`goToROIForm${id}`).submit() }
                                    >ROIs</button>
                                </div>
                                {/*<div className="hoverable d-flex align-items-center mx-1">
                                    <form id={`goToAuditForm${id}`} className="d-none" method="post" action={`${zynity_website.yii_website_url}/coach`}>
                                        <input type="hidden" name="orgId" value={id}/>
                                        <input type="hidden" name="coachId" value={user.coach.id}/>
                                        <input type="hidden" name="coachMemberId" value={user.id}/>
                                        <input type="hidden" name="menuAction" value='audit'/>
                                    </form>
                                    <button
                                        type="button"
                                        className="btn dropdown-toggle remove-arrow"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={() => document.getElementById(`goToAuditForm${id}`).submit() }
                                    >Audit</button>
                                </div>*/}
                                {
                                    totalNotifications > 0 &&
                                    <span className="badge rounded-pill bg-danger badge-total-notifications" data-bs-toggle="tooltip" data-bs-placement="top" title="New Content">{totalNotifications}</span>
                                }
                            </div>
                        </div>
                    </h2>
                    <div className="pb-1">
                        <div id={`flush-collapse-org${id}`} className="accordion-collapse collapse" aria-labelledby={`accordionOrgId${id}`} data-bs-parent="#accordionFlushExample">
                            {
                                org?._embedded.teams.map((team: any) => (
                                    <Team key={team.id} team={team} org_id={org.id} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{modalInfo.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body dangerouslySetInnerHTML={{__html: modalInfo.body}}/>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                    <form id={`modalCoachForm${id}`} className="d-none" method="post" action={`${zynity_website.yii_website_url}/coach`}>
                        <input type="hidden" name="orgId" value={id}/>
                        <input type="hidden" name="coachId" value={user.coach.id}/>
                        <input type="hidden" name="coachMemberId" value={user.id}/>
                        <input type="hidden" name="menuAction" value={coachModalFormAction}/>
                    </form>
                    <Button variant="primary" onClick={handleSubmitCoachForm}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
