import { createSlice } from '@reduxjs/toolkit'

export interface CnAState {
    remember: any,
}

const initialState: CnAState = {
    remember: []
}

export const connectAndAlignRememberSlice = createSlice({
    name: 'tool_cna_remember',
    initialState,
    reducers: {
        setRemember: (state, action) => {
            state.remember = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setRemember } = connectAndAlignRememberSlice.actions
