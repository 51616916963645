// @ts-ignore
import {zynity_api} from "../config/external-routes";

export const dataURLtoFile = (data_url: string, filename: string) => {
    const arr = data_url.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1)
        n -= 1 // to make eslint happy
    }
    return new File([u8arr], filename, { type: mime })
}

export const publicUrl = (path: string) => {
    return `${zynity_api.global_download_files}?path=${path}`;
}

export const getUrlExtension = (url: string) => {
    return url.split('.').pop();
}
