import React from 'react'

export const MemberTeamsColumn = ({row}: any) => {

    if(row.teams.length < 1) {
        return (<div className="text-muted">No active Team association</div>)
    }

    return (
        <div>
            {
                row.teams.map((field: any) => (
                    <div className="mt-1 mb-1">{field.TeamName}</div>
                ))
            }
        </div>
    )
}
