import React, {useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons'
import './zMail.scss'
import moment from 'moment'
import { zynity_api } from '../../../../../../config/external-routes'
import {getUserOrganizations} from "../../../../../../store/user_organizations";

export const ZMail = ({z_mail, org_id, team_id}) => {

    const user = useSelector(state => state.auth)
    const dispatch = useDispatch();
    const [removeBtnDisabled, setremoveBtnDisabled] = useState(false)

    const deleteZMail = (z_mail_id) => {

        setremoveBtnDisabled(true);

        let zmail_url = zynity_api.user_organization_team_z_mail
        .replace('{user_id}', user.id)
        .replace('{organization_id}', org_id)
        .replace('{team_id}', team_id)
        .replace('{z_mail_id}', z_mail_id);

        axios.defaults.headers.common = {'Authorization': `Bearer ${user.access_token}`}
        axios.delete(zmail_url)
        .then((response) => {
            setremoveBtnDisabled(false);
            dispatch( getUserOrganizations() )
        }).catch((err) => {
            setremoveBtnDisabled(false);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Try again.'
              })
        })
    }

    return (
        <div className="col-12 mb-1 mt-2">
            <div className="rounded-pill px-2 org-team-z-mail-collapse-item d-flex align-items-center">
                <div className="rounded-circle alias_circle">{z_mail.from_initials}</div>
                <span className="z-mail-from-name">{z_mail.from_name}</span>
                {z_mail.message}
                <span className="z-mail-date">{moment(z_mail.dateCreated).format('MMM D, YYYY')}</span>
                <button className="z-mail-trash-icon" disabled={removeBtnDisabled} onClick={() => {deleteZMail(z_mail.id)}}>
                    {
                        removeBtnDisabled ?
                        <FontAwesomeIcon className="spinner" icon={faSpinner} />
                        :
                        <FontAwesomeIcon icon={faTrash} />
                    }
                    
                </button>
            </div>
        </div>
    )
}
