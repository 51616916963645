import React from 'react'
import {Header} from "../../../../header/Header";
import {FacilitatorHeader} from "../FacilitatorHeader";
import {Loading} from "../../../../ui/loading/Loading";
import {useSelector} from "react-redux";
import DataTable from "react-data-table-component";
import {SummaryCell} from "./SummaryCell";
import {Link} from "react-router-dom";

export const ManageTools = () => {
    const organization_team = useSelector((state:any) => state.organization_team);

    if(organization_team.isLoading) {
        return (
            <>
                <Header />
                <Loading />
            </>
        )
    }

    const services = organization_team.data.services

    const columns = [
        {
            name: 'SKU',
            selector: (row: { SKU: any; }) => row.SKU,
            maxWidth: '10px'
        },
        {
            name: 'Tool/Level',
            selector: (row: { Name: any;}) => `${row.Name}`,
            maxWidth: '200px'
        },
        {
            name: 'Billing Cycle',
            selector: (row: { BillingCycle: any; }) => row.BillingCycle == undefined ? '' : row.BillingCycle,
            maxWidth: '150px'
        },
        {
            name: 'Base Fee',
            selector: (row: { BillingCycle: any; Fee:any; FeeY:any }) => `$${row.BillingCycle == 'Monthly' ? row.Fee : row.FeeY}`,
            maxWidth: '100px'
        },
        {
            name: 'Member Fee',
            selector: (row: { BillingCycle: any; FeeMember:any; FeeMemberY:any }) => `$${row.BillingCycle == 'Monthly' ? row.FeeMember : row.FeeMemberY}`,
            maxWidth: '100px'
        },
        {
            name: 'Basis',
            selector: (row: { Basis: any; }) => `${row.Basis}`,
            maxWidth: '200px'
        },
        {
            name: 'Summary',
            cell: (row: any) => <SummaryCell row={row}/>,
            allowOverflow: true,
            width: '300px'
        }
    ];


    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-2 mb-5">
                    <div className="col-12 col-md-9">
                        <div className="row">
                            <FacilitatorHeader page_name={'Manage Team Suite'} />
                        </div>
                    </div>
                    <div className="col-md-3 col-12 mt-3">
                        <Link to="suite-selection" className='btn btn-teal'>Suite Selection</Link>
                    </div>
                    <div className="col-12 mt-3">
                        <DataTable
                            columns={columns}
                            data={services}
                        />
                    </div>
                    {
                        services.length > 0 &&
                        <div className="col-12 mt-2 container" style={{backgroundColor: '#F6F6F6', borderBottom: 'solid 2px black'}}>
                            <div className="row mt-3">
                                <div className="col">Includes</div>
                                <div className="col-11">
                                    <div dangerouslySetInnerHTML={{__html: services[0].Description}}/>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </>
    )
}
