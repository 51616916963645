import React, {useEffect} from 'react'
import {TeamHeader} from "../header/TeamHeader";
import {useDispatch, useSelector} from "react-redux";
import {getCoachNotes, setCoachNoteOpen} from "../../../../store/coaches/teams/coach_notes";
import {useParams, useLocation} from "react-router-dom";
import {Loading} from "../../../ui/loading/Loading";
import {CoachNotesContainer} from "./CoachNotesContainer";
import {constants} from "../../../../config/constants";
import {getOrganizationTeam} from "../../../../store/user_organizations/teams/team";
import {setRoute} from "../../../../store/routes";

export const CoachNotes = () => {
    const { pathname } = useLocation();
    document.title = `Coach Notes | ${constants.website_title}`
    const coach_notes = useSelector((state:any) => state.coach_notes)
    const {organization_id, team_id} = useParams();
    const dispatch = useDispatch();
    useEffect( () => {
        urlHandled()
        dispatch( getCoachNotes(team_id) );
        dispatch( getOrganizationTeam({organization_id, team_id}) );
        dispatch(setCoachNoteOpen(0))
    }, [organization_id, team_id])

    const urlHandled = () => {
        dispatch(setRoute(pathname))
        window.history.replaceState(null, "Coach Notes", "/coach-notes")
    }

    return (
        <>
            <TeamHeader suffixRoute='/coach/notes' />
            {
                coach_notes.isLoading ? <Loading />
                    : <CoachNotesContainer coach_notes={coach_notes} />
            }
        </>
    )
}
