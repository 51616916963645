import { createSlice } from '@reduxjs/toolkit'

export interface TeamState {
    isLoading: boolean,
    data: object,
}

const initialState: TeamState = {
    isLoading: true,
    data: null,
}

export const organizationTeamSlice = createSlice({
    name: 'user_organizations_team',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        setOrganizationTeam: (state, action) => {
            state.data = action.payload
            state.isLoading = false
        }
    },
})

// Action creators are generated for each case reducer function
export const { startLoading, setOrganizationTeam } = organizationTeamSlice.actions
