import React from 'react'

export const TeamMembersColumn = ({row}: any) => {
    if(row.members.length < 1) {
        return (<div className="text-muted">No active members</div>)
    }
    return (
        <div>
            {
                row.members.map((field: any) => {
                    let facilitator_icon = <></>;
                    if(field.isFacilitator == 'Yes') {
                        facilitator_icon = <span title="Facilitator" style={{fontSize: '18px;', color: 'LightSteelBlue', marginLeft: '3px'}}>&#128736;</span>;
                    }
                    return <div className="mt-1 mb-1">{field.FirstName} {field.LastName}{facilitator_icon}</div>
                })
            }
        </div>
    )
}
