import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {faCommentDots, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import moment from "moment/moment";
import {zynity_api} from "../../../../config/external-routes";
import {getAuthorInitials, getAuthorKnowAs} from "../../../../helpers/tools";
import {useSelector} from "react-redux";

export const _Comments = ({datum, handleAddComment, handleEditComment, clickRemoveComment}:any) => {
    const authenticated_user = useSelector((state: any) => state.auth);
    const team_obj = useSelector((state: any) => state.organization_team);

    return (
        <>
            <div className="col-2 col-lg-auto p-0 border-right" style={{display: 'contents'}}>Comments
                { typeof handleAddComment == 'function' && <i title='Add Comment' className='mx-1 color-teal clickable badge bg-teal p-1' style={{height: 'fit-content'}} onClick={() => {handleAddComment(datum.id)}}><FontAwesomeIcon icon={faPlus}/></i>}
            </div>
            {
                (datum?.comments == undefined || datum?.comments?.length <= 0) ?
                    <div className="col-10 col-lg-auto"><span className="text-muted">none</span></div>
                    :
                    <div className="col-10 col-lg-10">
                        {
                            datum.comments.map((comment: { idAuthor: number; dateCreated: moment.MomentInput; id: any; comment: any; comments: any[]; links: any[]; uploads: any[]; }) => (
                                <div className="row mt-1">
                                    <div className="col-12 d-flex align-items-center">
                                        <div className="rounded-circle" style={{backgroundColor: 'teal', padding: '6px', width: '30px', color: 'white', display: 'inline', textAlign: 'center', fontSize: '12px', fontFamily: 'sans-serif'}}>
                                            {getAuthorInitials(comment.idAuthor, team_obj)}
                                        </div>
                                        <span className='mx-1'>{getAuthorKnowAs(comment.idAuthor, team_obj)}</span>
                                        <span className='text-muted'>{moment(comment.dateCreated).format('DD MMM YYYY')}</span>
                                        {
                                            (typeof handleEditComment == 'function' && comment.idAuthor == authenticated_user.id) && <i title='Edit' className="fa fa-pencil-square-o color-teal mx-1" onClick={() => {handleEditComment(datum.id, comment.id)}}></i>
                                        }
                                        {
                                            typeof handleAddComment == 'function' && <i title='Add Reply' className=" color-teal mx-1" onClick={() => {handleAddComment(datum.id, comment.id)}}><FontAwesomeIcon icon={faCommentDots}/></i>
                                        }
                                        {
                                            (typeof clickRemoveComment == 'function' && comment.idAuthor == authenticated_user.id) && <i title='Remove' className="color-teal mx-1" onClick={() => {clickRemoveComment(datum.id, comment.id)}}><FontAwesomeIcon icon={faTrashAlt}/></i>
                                        }

                                    </div>
                                    <div className="col-12" dangerouslySetInnerHTML={{__html: comment.comment}}/>
                                    {
                                        comment.links.map((link: { links: string; }) => (
                                            <div><i className='fa fa-link'></i> <a className='color-teal' href={link.links} target='_blank'>{link.links}</a></div>
                                        ))
                                    }
                                    {
                                        comment.uploads.map((uploads: {
                                            displayName: any;
                                            fileName: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; }) => (
                                            <div><i className='fa fa-arrow-circle-down'></i> <a className='color-teal' href={`${zynity_api.global_download_files}?path=${uploads.fileName}&force_download=true&custom_name=${uploads.displayName}`}>{uploads.displayName}</a></div>
                                        ))
                                    }
                                    <hr className='mt-2' style={{border: 0, borderTop: '1px solid #d1d1d1'}}/>

                                    {
                                        comment.comments.map((reply) => (
                                            <div className="row mt-1 mx-4">
                                                <div className="col-12 d-flex align-items-center">
                                                    <div className="rounded-circle" style={{backgroundColor: 'teal', padding: '6px', width: '30px', color: 'white', display: 'inline', textAlign: 'center', fontSize: '12px', fontFamily: 'sans-serif'}}>
                                                        {getAuthorInitials(reply.idAuthor, team_obj)}
                                                    </div>
                                                    <span className='mx-1'>{getAuthorKnowAs(reply.idAuthor, team_obj)}</span>
                                                    <span className='text-muted'>{moment(reply.dateCreated).format('DD MMM YYYY')}</span>

                                                    {
                                                        (typeof handleEditComment == 'function' && reply.idAuthor == authenticated_user.id) &&
                                                        <i title='Edit' className="fa fa-pencil-square-o color-teal mx-1"
                                                           onClick={() => {handleEditComment(datum.id, comment.id, reply.id)}}></i>
                                                    }

                                                    <i title='Add Reply' className="color-teal mx-1"
                                                       onClick={() => {handleAddComment(datum.id, reply.id)}}><FontAwesomeIcon icon={faCommentDots}/></i>

                                                    {
                                                        (typeof clickRemoveComment == 'function' && reply.idAuthor == authenticated_user.id) &&
                                                        <i title='Edit' className="color-teal mx-1"
                                                           onClick={() => {clickRemoveComment(datum.id, reply.id)}}><FontAwesomeIcon icon={faTrashAlt}/></i>
                                                    }
                                                </div>
                                                <div className="col-12" dangerouslySetInnerHTML={{__html: reply.comment}}/>
                                                {
                                                    reply.links.map((link: { links: string; }) => (
                                                        <div><i className='fa fa-link'></i> <a className='color-teal' href={link.links} target='_blank'>{link.links}</a></div>
                                                    ))
                                                }
                                                {
                                                    reply.uploads.map((uploads: {
                                                        displayName: any;
                                                        fileName: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; }) => (
                                                        <div><i className='fa fa-arrow-circle-down'></i> <a className='color-teal' href={`${zynity_api.global_download_files}?path=${uploads.fileName}&force_download=true&custom_name=${uploads.displayName}`}>{uploads.displayName}</a></div>
                                                    ))
                                                }
                                                <hr className='mt-2' style={{border: 0, borderTop: '1px solid #d1d1d1'}}/>

                                                {
                                                    reply.comments.map((reply2: { idAuthor: number; dateCreated: moment.MomentInput; id: any; comment: any; links: any[]; uploads: any[]; }) => (
                                                        <div className="row mt-1 mx-4">
                                                            <div className="col-12 d-flex align-items-center">
                                                                <div className="rounded-circle" style={{backgroundColor: 'teal', padding: '6px', width: '30px', color: 'white', display: 'inline', textAlign: 'center', fontSize: '12px', fontFamily: 'sans-serif'}}>
                                                                    {getAuthorInitials(reply2.idAuthor, team_obj)}
                                                                </div>
                                                                <span className='mx-1'>{getAuthorKnowAs(reply2.idAuthor, team_obj)}</span>
                                                                <span className='text-muted'>{moment(reply2.dateCreated).format('DD MMM YYYY')}</span>
                                                                {
                                                                    (typeof handleEditComment == 'function' && reply2.idAuthor == authenticated_user.id) &&
                                                                    <i title='Edit' className="fa fa-pencil-square-o color-teal mx-1"
                                                                       onClick={() => {handleEditComment(datum.id, comment.id, reply.id, reply2.id)}}></i>
                                                                }
                                                                {
                                                                    (typeof clickRemoveComment == 'function' && reply2.idAuthor == authenticated_user.id) &&
                                                                    <i title='Edit' className="color-teal mx-1"
                                                                       onClick={() => {clickRemoveComment(datum.id, reply2.id)}}><FontAwesomeIcon icon={faTrashAlt}/></i>
                                                                }
                                                            </div>
                                                            <div className="col-12" dangerouslySetInnerHTML={{__html: reply2.comment}}/>
                                                            {
                                                                reply2.links.map((link: { links: string; }) => (
                                                                    <div><i className='fa fa-link'></i> <a className='color-teal' href={link.links} target='_blank'>{link.links}</a></div>
                                                                ))
                                                            }
                                                            {
                                                                reply2.uploads.map((uploads: {
                                                                    displayName: any;
                                                                    fileName: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; }) => (
                                                                    <div><i className='fa fa-arrow-circle-down'></i> <a className='color-teal' href={`${zynity_api.global_download_files}?path=${uploads.fileName}&force_download=true&custom_name=${uploads.displayName}`}>{uploads.displayName}</a></div>
                                                                ))
                                                            }
                                                            <hr className='mt-2' style={{border: 0, borderTop: '1px solid #d1d1d1'}}/>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
            }
        </>
    )
}
