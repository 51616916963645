import { createSlice } from '@reduxjs/toolkit'

export interface ServicesState {
    isLoading: boolean,
    data: object
}

const initialState: ServicesState = {
    isLoading: true,
    data: undefined
}

export const servicesSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        setServices: (state, action) => {
            state.data = action.payload
            state.isLoading = false
        }
    },
})

// Action creators are generated for each case reducer function
export const { startLoading, setServices } = servicesSlice.actions
