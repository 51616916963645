import React, {useEffect, useState} from 'react'
// @ts-ignore
import {Header} from "../header/Header";
import {Link} from "react-router-dom";
// @ts-ignore
import { zynity_website, zynity_api } from '../../config/external-routes';
import {Accordion} from "./dashboard_components/Accordion";
import axios from "axios";
import {useSelector} from "react-redux";

export const CoachDashboard = () => {
    const user = useSelector((state:any) => state.auth);
    const [loading, setLoading] = useState(true);
    const [organizationData, setOrganizationData] = useState([]);
    const yii_url = zynity_website.yii_website_url;

    const fetchData = async () => {
        setLoading(true);
        let coach_org_url = zynity_api.coaches_organizations.replace('{coach_id}', user.coach.id)
        const response = await axios.get(coach_org_url);
        setOrganizationData(response.data.data);
        setLoading(false);
    };

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <>
            <Header/>
            <div className="container">
                <div className="row mt-4 d-flex justify-content-between align-items-center">
                    <div className="col-12 col-md-5 submenu-title">
                        <h4>
                            Coach Dashboard <img className='d-inline' src={require('../../assets/images/coach_notes_icon.png')} width="65" alt=""/>
                        </h4>
                    </div>
                    <div className="col-12 col-md-7 text-end">
                        <Link to="/coach/certified-coach-profile-update"><button type="button" className="btn btn-sm btn-primary">Coach Profile</button></Link>
                        <Link to='/coach/agreement' ><button type="button" className="btn btn-sm btn-outline-primary mx-3">Certified Coach Agreement</button></Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 org-org-header" style={{backgroundColor: '#4682B4'}}>
                                <h5>Organization / Team</h5>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 p-0">
                                {
                                    organizationData.map(org => (
                                        <Accordion key={org.id} org={org} user={user} />
                                    ))
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
