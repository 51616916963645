// @ts-ignore
import React, { useState, useRef } from 'react'
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'

import 'react-image-crop/dist/ReactCrop.css'

// @ts-ignore
export function ModalUploadImage({aspect = 12/12, setImgBase64}) {
    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [scale, setScale] = useState(50)
    const [rotate, setRotate] = useState(0)

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        imgRef.current = e.currentTarget

        const { width, height } = e.currentTarget

        // This is to demonstate how to make and center a % aspect crop
        // which is a bit trickier so we use some helper functions.
        const crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                width,
                height,
            ),
            width,
            height,
        )

        setCrop(crop)
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                await canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale/100,
                    rotate,
                )

                setImgBase64(previewCanvasRef.current.toDataURL())
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    const handleScale = (e:any) => {
        setScale(e.currentTarget.value)
    }

    return (
        <>
            <div className="mb-3">
                <input className="form-control" type="file" id="formFile" accept="image/*" onChange={onSelectFile} />
            </div>
            {Boolean(imgSrc) && (
                <div className='text-center'>
                    <ReactCrop
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={aspect}
                    >
                        <img
                            alt="Crop me"
                            src={imgSrc}
                            style={{ transform: `scale(${scale/100}) rotate(${rotate}deg)` }}
                            onLoad={onImageLoad}
                        />
                    </ReactCrop>
                    <input type="range" className="form-range" id="customRange1" onChange={handleScale} value={scale} />
                </div>
            )}
            <div>
                <canvas
                    ref={previewCanvasRef}
                    style={{
                        display: 'none',
                        border: '1px solid black',
                        objectFit: 'contain',
                        width: completedCrop?.width || 0,
                        height: completedCrop?.height || 0,
                    }}
                />
            </div>
        </>
    )
}
