import React, {useEffect, useState} from 'react'
import {Header} from "../../../header/Header";
import './how_lead.scss'
import {useForm} from "../../../hooks/useForm";
import {zynity_api} from "../../../../config/external-routes";
import axios from "axios";
import {HowLeadForm} from "./HowLeadForm";
import {useSelector} from "react-redux";
import Swal from 'sweetalert2'

export const HowLead = () => {
    const user = useSelector((state:any) => state.auth);
    const [loading, setLoading] = useState(true);
    const [formData, setFormDatum, setFormData]: any = useForm({})

    let how_to_lead_url = zynity_api.users_how_lead.replace('{user_id}', user.id)

    const fetchData = async () => {
        setLoading(true);
        const response = await axios.get(how_to_lead_url);
        setFormData(response.data);
        setLoading(false);
    };

    useEffect(() => {
        fetchData()
    }, []);

    const handleSubmit = () => {
        axios.put(`${how_to_lead_url}/${formData.id}`, formData).then(response => {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Data Saved'
            })
        }).catch((e) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Error saving the form.'
            })
        })
    }

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-4 d-flex justify-content-between align-items-center">
                    <div className="col-12 text-center">
                        <h2 style={{fontFamily: 'Segoe UI",Arial,sans-serif', fontWeight: '400'}}>How do you lead and manage?</h2>
                        <div style={{width: '50%', height: '4px', backgroundColor: 'lightgreen', marginLeft: 'auto', marginRight: 'auto'}} />
                    </div>

                    <div className="col-12 col-md-8 mt-3">
                        <button style={{background: "teal", borderRadius: "12px", height: "40px", color: "white", padding: "6px 14px",
                                width: "95%", textAlign: 'left', border: 'none'}} data-bs-toggle="collapse" href="#collapseExample"
                                role="button" aria-expanded="false" aria-controls="collapseExample">
                            <div style={{display: "inline", fontSize: "20px"}}><b>In a Nutshell</b></div>
                            <div style={{marginLeft: "80px", textAlign: "right", display: "inline", fontSize: "12px"}}><em>click to open</em></div>
                        </button>
                        <div className="collapse" id="collapseExample">
                            <p>
                                This is written for leaders and managers who are passionately committed to improving their abilities. It introduces a process to help you on your journey.<br />
                                The book takes you through a fun and beneficial process to write your own ‘How I Lead and Manage’ document. A special web page¹ (no fee), along with this book, guides you, at your own pace, in a simple, step-by-step process. The output is your own treatise² of leadership.<br />
                                Your organization’s performance will improve. Your relationships and interactions will be more effective. New levels of respect and trust will be gained. You’ll establish a more defined leading and managing system allowing your organization to thrive.<br />
                                It’s not about learning new things. You already know significantly more about leading and managing than you apply. This process helps you organize what you already know so you apply your knowledge more effectively.<br />
                                Your treatise² becomes the solid foundation you add upon to continue clarifying and refining how you lead and manage. Your new understandings and insights about yourself and leadership will improve your life.<br />
                                This will inspire those around you to join in the same journey. Collaborating with others helps everyone. Enjoy! <br />

                                <span style={{lineHeight: '12px', fontSize: '12px'}}>
									1. <a href="https://zynity.com/" title="Zynity" target="_blank" style={{color: '#99cc00'}}>www.Zynity.com</a> go to the link <i><b>How You Lead</b></i> to get started when you’re ready.<br />
									2. Treatise; a written work dealing formally and systematically with a subject.
								</span>
                            </p>
                        </div>

                        <h5 className='mt-4'><i>Everyone has a personal way to lead and manage.</i></h5>
                        <h5 className='mt-4'><i>Expressing it in a clear and simple manner is necessary for others to buy into it, fully engage, contribute within it, and help continue refining and improving it.</i></h5>
                        <h5 className='mt-4'><i>If you and your leading & managing team want to improve, creating your documents should be a part of your plan and process.</i></h5>

                        <div className='mt-2'>
                            <iframe width="90%" height="366" src="https://www.youtube.com/embed/mngqM8Y0Kgw"
                                frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen={true}
                                style={{margin: 0, padding: 0, border: 'thin solid black'}}/>
                        </div>

                        <h5 className='mt-4'><i>We look forward to helping you and your organization be all you want to be!</i></h5>

                        <div className='mt-4 d-block d-md-inline'>
                            <b>Already have an account?</b>
                            <a href={`${process.env.REACT_APP_ZYNITY_YII_URL}/index.php/site/login`}><button type="button" className="btn btn-success mx-2">Login</button></a>
                        </div>

                        <div className='mt-4 d-block d-md-inline mx-0 mx-md-3'>
                            <b>If you are new</b>
                            <a href={`${process.env.REACT_APP_ZYNITY_YII_URL}/index.php/people/register`}><button type="button" className="btn btn-success mx-2">Register</button></a>
                        </div>

                        <div className='mt-4'>
                            <b>Following is a template for you to use as an outline and model
                                to define and document How You Lead and Manage:</b>
                        </div>

                        <a href="#instructions"><button type="button" className="btn btn-success">Instructions <i className="fa fa-arrow-down" aria-hidden="true"/></button></a>
                        <a href="#expected-results" className='mx-2'><button type="button" className="btn btn-success">Expected Results <i className="fa fa-arrow-down" aria-hidden="true"/></button></a>
                    </div>

                    <div className="col-12 col-md-4 mt-3">
                        <div id="f1_container">
                            <div id="f1_card" className="shadow">
                                <div className="front face">
                                </div>
                                <div className="back face center">
                                </div>
                            </div>
                        </div>

                        <div id="f1_container" className="mt-4">
                            <p className="mt-2 p-2">The purpose of this book is to help you use the template below to build your own How You Lead and Manage document.</p>
                            <div className='p-2 pt-0'>
                                <a href="https://amzn.to/2L9CDAO" target='_blank'><button type="button" className="btn btn-success">Printed version at Amazon</button></a>
                            </div>
                            <div className='p-2'>
                                <a href="https://amzn.to/2Jxp9hb" target='_blank'><button type="button" className="btn btn-success">Kindle version at Amazon</button></a>
                            </div>
                        </div>
                    </div>

                    {
                        loading === false && <HowLeadForm user={user} formData={formData} setFormDatum={setFormDatum} handleSubmit={handleSubmit} />
                    }

                    <div className="col-4"></div>

                    <span className='text-center mt-4'>© Copyright 2018 - 2022 Dwaine Canova All Rights Reserved</span>
                    <hr className='mt-3'/>

                    <div className="col-12 text-end">
                        <a href="#top"><button type="button" className="btn btn-success">Top <i className="fa fa-arrow-up" aria-hidden="true"/></button></a>
                    </div>
                    <div className="col-12" id="instructions">
                        <h5>Instructions for use:</h5>
                        <p>Step 1: Leaders should place their ideas in each of these sections and seek the advice and input of others to help refine the document.</p>
                        <p>Step 2: Leaders should have others on their managing team provide inputs to their document and also complete a document of their own.</p>
                        <p>Step 3: Leaders should meet with the others to review and edit their respective documents from time to time.</p>
                        <p>Step 4: Leaders may meet with other team member separately to get their insights about how to improve this document and their performance.</p>
                        <p>Step 5: Leaders should commit to implement the Framework for Leading™ concepts and the Zynity™ Online Tools. Continue editing the How You Lead and Manage document
                            and agree with others to track and then celebrate individual and team progress.</p>
                        <p>Individual and group exchanges will indicate there are opportunities for increased understanding and more effective communicating.</p>
                        <p>Key: Ongoing editing and improvement is key to immediate and long term success.</p>
                    </div>

                    <div className="col-12 text-end">
                        <a href="#top"><button type="button" className="btn btn-success">Top <i className="fa fa-arrow-up" aria-hidden="true"/></button></a>
                    </div>
                    <div className="col-12" id="expected-results">
                        <h5>Expected Results:</h5>
                        <p>Working to make the How You Lead and Manage document better over time is a good way to demonstrate your commitment to your personal development and organization's performance. Your Key Performance Metrics (KPMs) will get better if you improve the environment within which they are created. Improving the environment entails improving the foundation upon which the environment is built. A key strength of the foundation is the quality of its organized leading and managing framework.</p>
                        <p>When an organization uses the Zynity Leadership™ online tools and concepts, the ratings for each of these questions will increase quarter over quarter. The goal is to get the ratings nearer 10 for all respondents in the company. This will indicate that a Top-Level leading and managing framework is in place and having a significant impact. Organizations that ‘Do’ this will have a decided advantage.</p>
                        <p>Do it together. Get you and your team ‘working together better’ as you build your own How You Lead and Manage documents along with the framework. It can all be done in only one hour or less each week. You’ll notice many worthwhile changes within a short time. All will be motivated by the ongoing improvements to continue evolving. In a few months you’ll be a whole new team. The outcomes will quickly justify the process and complaints will be few.</p>
                    </div>
                    <span className='text-center mt-4 mb-5'>© Copyright 2018 - 2022 Dwaine Canova All Rights Reserved</span>
                </div>
            </div>
        </>
    )
}
