import React from 'react'

export const FormOrganizationDetails = ({data, setFormDatum, onSubmit, readonly, customColumns = false, colClass = 'col-12 col-md-6'}:any) => {

    // @ts-ignore
    let formParsedValues = {
        "date": {
            "value": data.date,
            "type": "text",
            "name": "date",
            "human_name": "Date",
            "required": true,
            "invalid_feedback": "Please add a valid date"
        },
        "name": {
            "value": data.name,
            "type": "text",
            "name": "name",
            "human_name": "Name",
            "required": true,
            "invalid_feedback": "Please add the organization name"
        },
        "url": {
            "value": data.url,
            "type": "text",
            "name": "url",
            "human_name": "Web Address",
            "required": false,
            "invalid_feedback": null
        },
        "email": {
            "value": data.email,
            "type": "email",
            "name": "email",
            "human_name": "Email",
            "required": false,
            "invalid_feedback": null
        },
        "address": {
            "value": data.address,
            "type": "text",
            "name": "address",
            "human_name": "Address",
            "required": false,
            "invalid_feedback": null
        },
        "address2": {
            "value": data.address2,
            "type": "text",
            "name": "address2",
            "human_name": "Address 2",
            "required": false,
            "invalid_feedback": null
        },
        "city": {
            "value": data.city,
            "type": "text",
            "name": "city",
            "human_name": "City",
            "required": false,
            "invalid_feedback": null
        },
        "state": {
            "value": data.state,
            "type": "text",
            "name": "state",
            "human_name": "State",
            "required": false,
            "invalid_feedback": null
        },
        "zip": {
            "value": data.zip,
            "type": "text",
            "name": "zip",
            "human_name": "Zip",
            "required": false,
            "invalid_feedback": null
        },
        "country": {
            "value": data.country,
            "type": "text",
            "name": "country",
            "human_name": "Country",
            "required": false,
            "invalid_feedback": null
        },
        "phone": {
            "value": data.phone,
            "type": "tel",
            "name": "phone",
            "human_name": "Phone",
            "required": false,
            "invalid_feedback": null
        },
        "phone2": {
            "value": data.phone2,
            "type": "tel",
            "name": "phone2",
            "human_name": "Phone 2",
            "required": false,
            "invalid_feedback": null
        },
        "type": {
            "value": data.type,
            "type": "text",
            "name": "type",
            "human_name": "Type",
            "required": false,
            "invalid_feedback": null
        },
        "focus": {
            "value": data.focus,
            "type": "text",
            "name": "focus",
            "human_name": "Focus",
            "required": false,
            "invalid_feedback": null
        },
        "employees": {
            "value": data.employees,
            "type": "text",
            "name": "employees",
            "human_name": "Employees",
            "required": false,
            "invalid_feedback": null
        },
        "locations": {
            "value": data.locations,
            "type": "text",
            "name": "locations",
            "human_name": "Locations",
            "required": false,
            "invalid_feedback": null
        },
        "referred_by": {
            "value": data.referred_by,
            "type": "text",
            "name": "referred_by",
            "human_name": "Referred By",
            "required": false,
            "invalid_feedback": null
        },
        "status": {
            "value": data.status,
            "type": "dropdown",
            "name": "status",
            "human_name": "Status",
            "required": false,
            "invalid_feedback": null,
            "dropdownOptions": [
                {
                    "value": "Active",
                    "name": "Active"
                },
                {
                    "value": "Inactive",
                    "name": "Inactive"
                }
            ]
        },
        "notes": {
            "value": data.notes,
            "type": "text",
            "name": "notes",
            "human_name": "Notes",
            "required": false,
            "invalid_feedback": null
        }
    }

    if(customColumns != false) {
        formParsedValues = customColumns;
    }

    // @ts-ignore
    return (
        <form id="dataFormDetails" onSubmit={onSubmit} noValidate>
            <div className="row">
                {
                    Object.keys(formParsedValues).map((row) => (
                        <div className={colClass}>
                            <div className="mb-3">
                                <label
                                    htmlFor="date"
                                    className="form-label"
                                >{formParsedValues[row].human_name}
                                    {formParsedValues[row].required && <span style={{color: 'red'}}>*</span>}
                                </label>
                                {
                                    formParsedValues[row].type === 'dropdown' ?
                                        (<select
                                                className="form-select"
                                                id={formParsedValues[row].name}
                                                name={formParsedValues[row].name}
                                                placeholder={formParsedValues[row].human_name}
                                                autoComplete="off"
                                                value={formParsedValues[row].value}
                                                onChange={setFormDatum}
                                                disabled={readonly}
                                            >
                                                {
                                                    formParsedValues[row].dropdownOptions.map((option) => (
                                                        <option value={option.value}>{option.name}</option>
                                                    ))
                                                }
                                            </select>
                                        )
                                        :
                                        (
                                            <input
                                                type={formParsedValues[row].type}
                                                className="form-control"
                                                id={formParsedValues[row].name}
                                                name={formParsedValues[row].name}
                                                placeholder={formParsedValues[row].human_name}
                                                autoComplete="off"
                                                required={formParsedValues[row].required}
                                                value={formParsedValues[row].value}
                                                onChange={setFormDatum}
                                                readOnly={readonly}
                                                disabled={readonly}
                                            />
                                        )
                                }
                                {
                                    formParsedValues[row].invalid_feedback &&
                                    <div className="invalid-feedback">
                                        {formParsedValues[row].invalid_feedback}
                                    </div>
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </form>
    )
}
