import React, {useState} from 'react'
import {zynity_api} from "../../../../../config/external-routes";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Swal from 'sweetalert2'
import {getCnAData} from "../../../../../store/user_organizations/teams/tools/connect_and_align";

export const MemberAccordion = ({member, closeModal}:any) => {
    const dispatch = useDispatch();
    const user = useSelector((state:any) => state.auth)
    const organization_team = useSelector((state:any) => state.organization_team);
    const [showHiddenInfoDiv, setShowHiddenInfoDiv] = useState(false);
    const [showHiddenZMailDiv, setShowHiddenZMailDiv] = useState(false);
    const [message, setMessage] = useState('');
    const [sending, setSending] = useState(false);

    const handleInfoDivClick = () => {
        setShowHiddenInfoDiv(!showHiddenInfoDiv);
    };

    const handleZMailDivClick = () => {
        setShowHiddenZMailDiv(!showHiddenZMailDiv);
    };

    const sendZMailHandled = () => {
        let organization_id = organization_team.data.idOrganization
        let team_id = organization_team.data.id
        let url = zynity_api.user_organization_team_z_mail_post
            .replace('{user_id}', user.id)
            .replace('{organization_id}', organization_team.data.idOrganization)
            .replace('{team_id}', organization_team.data.id);

        let payload = {
            assigned_to: member?.People_id == undefined ? member.id : member.People_id,
            message: message
        }

        setSending(true)
        axios.defaults.headers.common = {'Authorization': `Bearer ${user.access_token}`}
        axios.post(`${url}`, payload)
            .then((response) => {
                setSending(false)
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'ZMail sent!',
                })
                closeModal()
                dispatch(getCnAData({organization_id, team_id}))
            }).catch((error) => {
                setSending(false)
                throw error;
            })
    }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center">
                <div
                    style={{color: 'teal', cursor: 'pointer'}}
                    onClick={handleInfoDivClick}
                >
                    {`${member.FirstName} ${member.LastName}`}
                    <span style={{color: '#333'}}>▿</span>
                    <img style={{width: '25px'}} data-toggle="tooltip" className="team-icons mx-2" src={require('../../../../../assets/images/FacilitatorIcon.png')}></img>
                </div>
                <div onClick={handleZMailDivClick}>
                    <img style={{width: '25px', cursor: 'pointer'}} data-toggle="tooltip" className="team-icons" title="show Z-mail" src={require('../../../../../assets/images/ZmailIcon.png')} alt="Z-mail"></img>
                </div>
            </div>

            {showHiddenInfoDiv && (
                <div
                    style={{
                        backgroundColor: 'floralwhite',
                        marginLeft: '20px',
                        textAlign: 'center'
                    }}
                >
                    {`${member.EmailAddress} ${member.CellPhone == undefined || member.CellPhone.trim() != '' ? member.Phone : member.CellPhone}`}
                </div>
            )}

            {showHiddenZMailDiv && (
                <div>
                    <div
                        style={{
                            textAlign: 'center'
                        }}
                    >
                    <textarea
                        style={{
                            width: '100%',
                            border: 'none',
                            backgroundColor: '#f6f6f6',
                            padding: '4px'
                        }}
                        value={message} onChange={(e) => {setMessage(e.target.value)}}
                        name="" id="" rows={3} placeholder={`Hey ${member.FirstName}...`}></textarea>
                    </div>
                    <div className="d-flex flex-row-reverse">
                        <button type="button" className="btn btn-sm btn-teal" onClick={sendZMailHandled}>{sending ? 'Sending...' : `Send msg to ${member.FirstName}`}</button>
                    </div>
                </div>
            )}
        </div>
    )
}
