import {startLoading, setData, setArchiveData, startLoadingFullData, setFullData} from "./cnASlice";
import {setRemember} from "./cnARememberSlice";
import axios from "axios";
import {zynity_api} from "../../../../../config/external-routes";

export const updateCnAData = (data:any, user_id:any, tool:any) => {
    return async (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        const cna_data = state().tool_cna;

        let index_user_data = cna_data.data.findIndex((item: { user_id: any; }) => item.user_id == user_id)
        if (index_user_data === -1) {
            return false
        }

        /*let index_tool_data = cna_data.data[index_user_data][tool.toLowerCase()];
        let updated_data = cna_data.data[index_user_data][tool.toLowerCase()]*/

        // Create a deep copy of the state object and its nested properties.
        const updatedDataArray = {
            ...cna_data,
            data: cna_data.data.map((item: any, index: number) => {
                if (index === index_user_data) {
                    // Update the tool-specific data for the user.
                    return {
                        ...item,
                        [tool.toLowerCase()]: data,
                    };
                }
                return item;
            }),
        };

        dispatch( setData(updatedDataArray.data) )
    }
}

export const getCnAData = ({organization_id, team_id}: any) => {
    return async (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        dispatch(startLoading())
        dispatch(startLoadingFullData())

        //Get Archive Data
        const user = state().auth
        const pastDate = state().tool_cna.pastDate;
        axios.defaults.headers.common = {'Authorization': `Bearer ${user.access_token}`}

        //Get Active Data
        axios.get(zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id) + '/tools/CnA?pastDate=' + pastDate)
            .then((response) => {
                console.log("result 1")
                dispatch( setData(response.data) )
            }).catch((err) => {
            console.log('Error get cna Data 1', err);
        })

        axios.get(zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id) + '/tools/CnA?pastDate=' + pastDate + '&full_data=true')
            .then((response) => {
                console.log("result 1")
                dispatch( setFullData(response.data) )
            }).catch((err) => {
            console.log('Error get cna Data 2', err);
        })

        let url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id) + '/tools/CnA?pastDate=' + pastDate;
        axios.get(`${url}&content_status=Archive&full_data=true`)
            .then((response) => {
                console.log("result 1")
                dispatch( setArchiveData(response.data) )
            }).catch((err) => {
            console.log('Error get cna Archive Data 3', err);
        })
    }
}

export interface ICollabRemember {
    organization_id: number,
    team_id: number,
    view: 'team'|'member',
    user_id: number,
    tab_name: 'tomis'|'deliverables'|'activities'|'messages',
    open: boolean,
    selected_item_id: number,
}

export interface ICollabPanelViewRemember {
    organization_id: number,
    team_id: number,
    view: 'team'|'member',
    user_id: null|number
}

export const collabSetRemember = ({organization_id, team_id, view, user_id, tab_name, selected_item_id}: ICollabRemember) => {
    return async (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        const remember = state().tool_cna_remember.remember;
        console.log("CNA ON REMEMBER", remember)

        //Clear/Reset Remember
        let remember_new = JSON.parse(JSON.stringify(remember));

        let organizationData = remember_new.find((org: { organization_id: number; team_id:number; view:'team'|'member' }) => (
            org.organization_id === organization_id && org.team_id === team_id && org.view === view
        ));
        if(!organizationData) {
            organizationData = {
                organization_id: organization_id,
                team_id: team_id,
                view: view,
                open: true,
                users: []
            };
            remember_new.push(organizationData);
        }

        //Remove old selected users if view is member.
        if(view == 'member') {
            organizationData.users = [];
        }

        // Find or create user data
        let userData = organizationData.users.find((user: { id: number; }) => user.id === user_id);
        if (!userData) {
            userData = {
                id: user_id,
                tabs: []
            };
            organizationData.users.push(userData);
        }

        // Add or update tab data
        let tabData = userData.tabs.find((tab: { name: string; }) => tab.name === tab_name);
        if (!tabData) {
            tabData = {
                name: tab_name,
                open: true,
                selected_item_id: selected_item_id
            };
            userData.tabs.push(tabData);
        }

        //Set all the other tabs to open = false and let the selected one as open = true
        userData.tabs.forEach((tab: { name: string; open: boolean; }) => {
            if (tab.name === tab_name) {
                tab.open = true;
            } else {
                tab.open = false;
            }
        });


        let organizationDataAllViews = remember_new.filter((org: { organization_id: number; team_id:number;}) => (
            org.organization_id == organization_id && org.team_id == team_id
        ));

        //Set all the other views as close except the new one
        organizationDataAllViews.forEach((orgTeam: { view: string; open: boolean; }) => {
            if (orgTeam.view === view) {
                orgTeam.open = true;
            } else {
                orgTeam.open = false;
            }
        });


        console.log("remember_new", remember_new)
        dispatch(setRemember(remember_new))
    }
}

export const collabSetPanelViewRemember = ({organization_id, team_id, view, user_id}: ICollabPanelViewRemember) => {
    return async (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        const remember = state().tool_cna_remember.remember;

        //Clear/Reset Remember
        let remember_new = JSON.parse(JSON.stringify(remember));

        let organizationData = remember_new.find((org: { organization_id: number; team_id:number; view:'team'|'member' }) => (
            org.organization_id === organization_id && org.team_id === team_id && org.view === view
        ));
        if(!organizationData) {
            organizationData = {
                organization_id: organization_id,
                team_id: team_id,
                view: view,
                open: true,
                users: []
            };
            remember_new.push(organizationData);
        }

        if(view == 'member') {
            // Find or create user data
            let userData = organizationData.users.find((user: { id: number; }) => user.id === user_id);
            if (!userData) {
                organizationData.users = [];
                userData = {
                    id: user_id,
                    tabs: []
                };
                organizationData.users.push(userData);
            }
        }


        let organizationDataAllViews = remember_new.filter((org: { organization_id: number; team_id:number;}) => (
            org.organization_id == organization_id && org.team_id == team_id
        ));

        //Set all the other views as close except the new one
        organizationDataAllViews.forEach((orgTeam: { view: string; open: boolean; }) => {
            if (orgTeam.view === view) {
                orgTeam.open = true;
            } else {
                orgTeam.open = false;
            }
        });
        dispatch(setRemember(remember_new))
    }
}
