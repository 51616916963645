import React, {useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import ModalDialog from "react-bootstrap/ModalDialog";
import Draggable from 'react-draggable';
import './search.scss'
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import {setCnASearchParams} from "../../../../../store/user_organizations/teams/tools/connect_and_align/cnASearchSlice";

class DraggableModalDialog extends React.Component {
    render() {
        return <Draggable handle=".draggableHandler"><ModalDialog {...this.props} />
        </Draggable>
    }
}

export const SearchModal = ({showCreateModal, handleCloseModal}:any) => {
    const dispatch = useDispatch();
    const team_obj = useSelector((state: any) => state.organization_team);
    const [q, setQ] = useState('');
    const [qError, setQError] = useState('');
    const [selectedMemberDD, setSelectedMemberDD] = useState({
        value: 'Members (All)',
        label: 'Members (All)',
        initials: '',
    });
    const [selectedTypes, setSelectedTypes] = useState(['all', 'tomis', 'deliverables', 'activities', 'messages', 'comments', 'links_and_documents']);
    const today = moment().format('YYYY-MM-DD');
    const [rangeStartSelected, setRangeStartSelected] = useState();
    const [rangeEndSelected, setRangeEndSelected] = useState();
    const [includeSelected, setIncludeSelected] = useState(['active']);
    const handleIncludeChange = (value:any) => {
        setIncludeSelected(prevIncludeSelected => {
            if (prevIncludeSelected.includes(value)) {
                // Remove the value if it's already selected
                return prevIncludeSelected.filter(item => item !== value);
            } else {
                // Add the value if it's not selected
                return [...prevIncludeSelected, value];
            }
        });
    };

    const handleTypeChange = (value:any) => {
        setSelectedTypes(prevSelectedTypes => {
            if (prevSelectedTypes.includes(value)) {
                // Remove the value if it's already selected
                return prevSelectedTypes.filter(type => type !== value);
            } else {
                // Add the value if it's not selected
                return [...prevSelectedTypes, value];
            }
        });
    };

    const handleSelectAll = () => {
        const allTypes = ['all', 'tomis', 'deliverables', 'activities', 'messages', 'comments', 'links_and_documents'];
        if (selectedTypes.length === allTypes.length) {
            // If all options are selected, deselect all
            setSelectedTypes([]);
        } else {
            // Otherwise, select all options
            setSelectedTypes(allTypes);
        }
    };

    const allOptions = [
        { id: 'chType1', value: 'all', label: '~select all' },
        { id: 'chType2', value: 'tomis', label: 'ToMIs' },
        { id: 'chType3', value: 'deliverables', label: 'Deliverables' },
        { id: 'chType4', value: 'activities', label: 'Activities' },
        { id: 'chType5', value: 'messages', label: 'Messages' },
        { id: 'chType6', value: 'comments', label: 'Comments' },
        { id: 'chType7', value: 'links_and_documents', label: 'Links and Docs' }
    ];

    const getSelectMembersOption = () => {
        let team_options = team_obj.data.members.map((member:any) => {

            let know_as = member.KnownAs;
            if(know_as == '' || know_as == undefined) {
                know_as = member.EmailAddress.includes('@') ? member.EmailAddress.split('@')[0] : member.EmailAddress;
            }

            return {
                value: member.id,
                label: know_as,
                initials: getAuthorInitials(member.id),
            }
        })

        return [
            {
                value: 'Members (All)',
                label: 'Members (All)',
                initials: '',
            },
            ...team_options
        ]
    }

    const getAuthorInitials = (people_id: number) => {
        let author = team_obj?.data?.members?.find((member: { id: number; }) => (member.id === people_id))
        if(author === undefined) {
            return '--';
        }

        if(author.FirstName == '' || author.FirstName == undefined) {
            return author.EmailAddress.substring(0, 2).toUpperCase()
        }

        return `${author.FirstName.substring(0, 1)}${author.LastName.substring(0, 1)}`;
    }

    const formatMemberOptionLabel = ({ value, label, initials }:any) => (
        <div className='d-flex align-items-center' style={{width: '150px'}}>
            {
                initials.trim() != '' &&
                <div className="rounded-circle" style={{backgroundColor: 'teal', height: '25px', padding: '3px', width: '26px', color: 'white', display: 'inline', textAlign: 'center', fontSize: '12px', fontFamily: 'sans-serif'}}
                >
                    {initials}
                </div>
            }

            <div className='mx-1'>{label}</div>
        </div>
    );

    const selectSearchMemberHandled = (member_id:any) => {
        if(member_id == 'Members (All)') {
            setSelectedMemberDD({
                value: 'Members (All)',
                label: 'Members (All)',
                initials: '',
            })
        } else {
            let member = team_obj?.data?.members?.find((member: { id: number; }) => (member.id == member_id))
            setSelectedMemberDD({
                value: member.id,
                label: member.KnownAs,
                initials: getAuthorInitials(member.id),
            })
        }

    }

    const handleSubmitSearch = () => {

        if(q.trim() == '') {
            setQError('Please add a word to search')
            return false;
        }

        let search_params = {
            q: q,
            member: selectedMemberDD,
            types: selectedTypes,
            date_start: rangeStartSelected,
            date_end: rangeEndSelected,
            include: includeSelected,
        }

        dispatch(setCnASearchParams(search_params))

        handleCloseModal()
    }

    return (
        <Modal show={showCreateModal} onHide={handleCloseModal}
               dialogAs={DraggableModalDialog}
        >
            <Modal.Header closeButton className='bg-teal draggableHandler' style={{cursor: 'grab'}}>
                <Modal.Title>Search</Modal.Title>
            </Modal.Header>
            <Modal.Body className='row d-flex align-items-center'>
                <div className="row">
                    <div className="col-12">
                        <div className="mb-3">
                            <input type="text" required
                                   onChange={(e) => {setQ(e.target.value); setQError('')}} value={q} className={`form-control ${qError.trim() != '' ? 'is-invalid' : ''}`}
                                   placeholder="Search keyword or phrase" />
                            {
                                qError.trim() != '' &&
                                <div className="invalid-feedback">
                                    {qError}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-3">
                            <label htmlFor="inputPassword5" className="form-label fw-bold">Filter by:</label>
                            <Select
                                value={selectedMemberDD}
                                options={getSelectMembersOption()}
                                formatOptionLabel={formatMemberOptionLabel}
                                onChange={(e:any) => selectSearchMemberHandled(e.value)}
                                isSearchable={false}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="mb-3">
                            <label htmlFor="inputPassword5" className="form-label fw-bold">Type:</label>
                            <div className="radio_inputs mx-3">
                                {allOptions.map(option => (
                                    <div className="form-check" key={option.id}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={option.id}
                                            value={option.value}
                                            checked={selectedTypes.includes(option.value)}
                                            onChange={() => option.value === 'all' ? handleSelectAll() : handleTypeChange(option.value)}
                                        />
                                        <label className="form-check-label" htmlFor={option.id}>
                                            {option.label}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="mb-3">
                            <label htmlFor="inputPassword5" className="form-label fw-bold mb-0">Date Range:</label>
                            <div id="emailHelp" className="form-text mt-0">Leave blank to include all.</div>

                            <div className="row align-items-center mt-2">
                                <div className="col-3">
                                    <label htmlFor="startInput" className="col-form-label">Start</label>
                                </div>
                                <div className="col-auto">
                                    <input type="date" id="startInput" className="form-control"
                                           value={rangeStartSelected}
                                           onChange={(e) => setRangeStartSelected(e.target.value)}
                                           max={today}
                                    />
                                </div>
                            </div>

                            <div className="row align-items-center mt-2">
                                <div className="col-3">
                                    <label htmlFor="endInput" className="col-form-label">End</label>
                                </div>
                                <div className="col-auto">
                                    <input type="date" id="endInput" className="form-control"
                                           value={rangeEndSelected}
                                           onChange={(e) => setRangeEndSelected(e.target.value)}
                                           max={today}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="inputPassword5" className="form-label fw-bold mb-0">Include:</label>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="includeActive"
                                    value="active"
                                    checked={includeSelected.includes('active')}
                                    onChange={(e) => handleIncludeChange(e.target.value)}
                                />
                                <label className="form-check-label" htmlFor="includeActive">
                                    Active
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="includeArchive"
                                    value="archive"
                                    checked={includeSelected.includes('archive')}
                                    onChange={(e) => handleIncludeChange(e.target.value)}
                                />
                                <label className="form-check-label" htmlFor="includeArchive">
                                    Archive
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cancel
                </Button>
                <Button className={'btn btn-teal'} onClick={handleSubmitSearch}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
