import React, {useState, useEffect} from 'react'
import { Header } from '../../header/Header'
import { useSelector } from 'react-redux'
import { constants } from '../../../config/constants'
import { zynity_api, zynity_website } from '../../../config/external-routes'
import { getRequest } from '../../../helpers/getRequest'
import { Loading } from '../../ui/loading/Loading'
import { CountsCard } from './CountsCard'
import { Link } from 'react-router-dom'


export const ZAdmin = () => {
    document.title = `Z-Admin | ${constants.website_title}`
    const user = useSelector(state => state.auth);
    const yii_url = zynity_website.yii_website_url;

    const [adminCounts, setAdminCounts] = useState(false);

    useEffect(() => {
        const total_counts_call = getRequest(zynity_api.admin_get_counts, user.access_token);
        total_counts_call.then((response) => {
            setAdminCounts(response)
        })
    }, [])

    return (
        <>
            <Header />
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 user-main-tittle">
                        <h4>
                            Zynity Administration
                        </h4>
                    </div>
                </div>



                <div className="row mt-4 row justify-content-evenly">
                    <div className="col-12 col-md-5">
                        {
                            adminCounts == false
                            ? <Loading centerPage={true} />
                            : <CountsCard adminCounts={adminCounts} />
                        }
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="row">
                            <div className="col-12 mt-3 mt-md-0">
                                <Link to='/admin/services' ><button type="button" className="btn btn-primary">Manage Services</button></Link>
                            </div>
                            <div className="col-12 mt-3">
                                <Link to='/admin/people' ><button type="button" className="btn btn-primary">Manage Members</button></Link>
                            </div>
                            <div className="col-12 mt-3">
                                <Link to='/admin/organizations' ><button type="button" className="btn btn-primary">Manage Organizations</button></Link>
                            </div>
                            <div className="col-12 mt-3">
                                <Link to='/admin/coaches' ><button type="button" className="btn btn-primary">Manage Coaches</button></Link>
                            </div>
                            <div className="col-12 mt-3">
                                <Link to='/admin/content-editor/UserNews' ><button type="button" className="btn btn-primary">Member News</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
