import React from 'react'
import {MemberAccordion} from "./MemberAccordion";
import {useSelector} from "react-redux";

export const MembersContainer = ({closeModal}:any) => {
    const organization_team = useSelector((state:any) => state.organization_team);
    if(organization_team.isLoading == true) {
        return <></>
    }

    return (
        <>
            <h5 className='m-0 p-0' style={{borderBottom: 'thin solid gray'}}>Members</h5>
            {
                organization_team.data.members.map((member:any) => (
                    <MemberAccordion member={member} closeModal={closeModal} />
                ))
            }

            <h5 className='m-0 p-0' style={{borderBottom: 'thin solid gray'}}>Coaches</h5>
            {
                organization_team.data.coaches.map((member:any) => (
                    <MemberAccordion member={member} closeModal={closeModal} />
                ))
            }

            <h5 className='m-0 p-0' style={{borderBottom: 'thin solid gray'}}>Viewers</h5>
            {
                organization_team.data.viewers.map((member:any) => (
                    <MemberAccordion member={member} closeModal={closeModal} />
                ))
            }
        </>
    )
}
