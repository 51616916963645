import {startLoading, setOrganizationTeam} from "./organizationTeamSlice";
import axios from "axios";
import {zynity_api} from "../../../../config/external-routes";

export const getOrganizationTeam = ({organization_id, team_id}: any) => {
    return async (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        dispatch(startLoading())

        const user = state().auth
        axios.defaults.headers.common = {'Authorization': `Bearer ${user.access_token}`}
        await axios.get(zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id))
            .then((response) => {
                dispatch( setOrganizationTeam(response.data) )
            }).catch((err) => {
            console.log('Error getOrganizationTeam', err);
        })
    }
}
