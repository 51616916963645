import React from 'react'
import {useSelector} from "react-redux";

export const AssignOrgCoachColumn = ({row, organization_coaches_list, assignOrgCoachMethod}: any) => {
    console.log("organization_coaches_list", organization_coaches_list, row);

    let organization_coach_found = organization_coaches_list.filter((item:any) => item.id == row.id).length > 0;

    if(organization_coach_found === true) {
        return (
            <span className="bg-warning btn-sm">Assigned</span>
        )
    }

    return (
        <button type="button" className="btn btn-teal btn-sm" id={`button_assign_coach_${row.id}`}
                onClick={() => assignOrgCoachMethod(row.id)}>Assign</button>
    )
}
