import React from 'react'
import {Header} from "../../../../header/Header";
import {FacilitatorHeader} from "../FacilitatorHeader";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Swal from 'sweetalert2'
import {zynity_api} from "../../../../../config/external-routes";
import {getOrganizationTeam} from "../../../../../store/user_organizations/teams/team";
import {useNavigate} from "react-router-dom";

export const RemoveTeam = () => {
    const navigate = useNavigate();
    const router = useSelector((state:any) => state.router);
    const organization_id = router.selected_organization_id;
    const team_id = router.selected_team_id;
    const organization_team = useSelector((state:any) => state.organization_team);
    let org_team_url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);


    let removeTeamHandle = () => {

        let linkDelete = `${org_team_url}/facilitator_dashboard/remove_team`;
        Swal.fire({
            title: `Are you sure that you want to delete the team: ${organization_team.data.TeamName}`,
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Team Deleted!', '', 'success')
                const response = axios.delete(linkDelete);
                response.then((success) => {
                    navigate('/')
                });
            }
        })
    }

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-2 mb-5">
                    <div className="col-12 col-md-9">
                        <div className="row">
                            <FacilitatorHeader page_name={'Remove Team'} />
                        </div>
                    </div>

                    <div className="col-12 mt-2">
                        <div>Removing this Team will make it no longer accessable or included in the monthly billing.</div>
                        <div>It will remain in the Zynity database for a period of time and can be reactivated if requested by contactin</div>
                    </div>

                    <div className="col-12 mt-3">
                        <button type="button" className="btn btn-teal" onClick={removeTeamHandle}>Remove {organization_team.data.TeamName}</button>
                    </div>
                </div>
            </div>
        </>
    )
}
