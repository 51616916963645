import React from 'react'
import {Link} from "react-router-dom";

export const CoachNameColumn = ({row, organization_id}: any) => {
    return (
        <div className="dropdown mt-1 mb-1">
                            <span className="dropdown-toggle"  id={row.id + 1}
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                {row.FirstName} {row.LastName}
                            </span>

            <div className="dropdown-menu p-4 text-muted" style={{width: '370px'}} aria-labelledby={row.id + 1}>
                <button style={{float: 'right'}} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                <div dangerouslySetInnerHTML={{__html: row.Welcome}}/>
                <div className="text-center mt-1 mb-1">{row.City}, {row.State}, {row.Country}</div>

                <div className='text-center mt-2'>
                    <Link to={`/organizations/${organization_id}/coaches/${row.id}`} target="_blank">
                        <button type="button" className="btn btn-primary btn-sm">view complete profile</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}
