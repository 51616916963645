import React, {useEffect, useState} from 'react'
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {constants} from "../../../../config/constants";

export const FormServiceDetails = ({data, setFormDatum, onSubmit, readonly}) => {
	const [description, setDescription] = useState(data.Description)
	useEffect(() => {
		setFormDatum({
			target: {
				name: 'Description',
				value: description
			}
		})
	}, [description])

	return (
		<form id="personalDataForm" onSubmit={onSubmit} noValidate>
			<div className="row">
				<div className="col-12 col-md-6">
					<div className="mb-3">
						<label
							htmlFor="SKU"
							className="form-label"
						>SKU<span style={{color: 'red'}}>*</span>
						</label>
						<input
							type="text"
							className="form-control"
							id="SKU"
							name="SKU"
							placeholder="SKU"
							autoComplete="off"
							required
							value={data.SKU}
							onChange={setFormDatum}
							readOnly={readonly}
							disabled={readonly}
						/>
						<div className="invalid-feedback">
							Please add SKU.
						</div>
					</div>
				</div>
				<div className="col-12 col-md-6">
					<div className="mb-3">
						<label
							htmlFor="Name"
							className="form-label"
						>Name<span style={{color: 'red'}}>*</span>
						</label>
						<input
							type="text"
							className="form-control"
							id="Name"
							name="Name"
							placeholder="Name"
							autoComplete="off"
							required
							value={data.Name}
							onChange={setFormDatum}
							readOnly={readonly}
							disabled={readonly}
						/>
						<div className="invalid-feedback">
							Please add Name.
						</div>
					</div>
				</div>
				<div className="col-12 col-md-6">
					<div className="mb-3">
						<label
							htmlFor="Fee"
							className="form-label"
						>App Base Fee<br></br><i className="FontSize14">(paid monthly)</i><span style={{color: 'red'}}>*</span>
						</label>
						<input
							type="number"
							className="form-control"
							id="Fee"
							name="Fee"
							placeholder="App Fee (M)"
							autoComplete="off"
							value={data.Fee}
							onChange={setFormDatum}
							readOnly={readonly}
							disabled={readonly}
						/>
						<div className="invalid-feedback">
							Please add valid Fee.
						</div>
					</div>
				</div>
				<div className="col-12 col-md-6">
					<div className="mb-3">
						<label htmlFor="FeeY" className="form-label">
								App Base Fee<br></br>
								<i className="FontSize14">(paid annual)</i><span style={{color: 'red'}}>*</span>
						</label>
						<input
							type="number"
							className="form-control"
							id="FeeY"
							name="FeeY"
							placeholder="App Fee (A)"
							autoComplete="off"
							value={data.FeeY}
							onChange={setFormDatum}
							readOnly={readonly}
							disabled={readonly}
						/>
						<div className="invalid-feedback">
							Please add valid Fee.
						</div>
					</div>
				</div>
				<div className="col-12 col-md-6">
					<div className="mb-3">
						<label
							htmlFor="FeeMember"
							className="form-label"
						>
							Member Fee<br></br>
							<i className="FontSize14">(paid monthly)</i><span style={{color: 'red'}}>*</span>
						</label>
						<input
							type="number"
							className="form-control"
							id="FeeMember"
							name="FeeMember"
							placeholder="Member Fee"
							autoComplete="off"
							value={data.FeeMember}
							onChange={setFormDatum}
							readOnly={readonly}
							disabled={readonly}
						/>
						<div className="invalid-feedback">
							Please add valid Trial Period.
						</div>
					</div>
				</div>
				
				<div className="col-12 col-md-6">
					<div className="mb-3">
						<label
							htmlFor="FeeMemberY"
							className="form-label"
						>Member Fee<br></br>
							<i className="FontSize14">(paid annual)</i><span style={{color: 'red'}}>*</span>
						</label>
						<input
							type="number"
							className="form-control"
							id="FeeMemberY"
							name="FeeMemberY"
							placeholder="Member Fee"
							autoComplete="off"
							value={data.FeeMemberY}
							onChange={setFormDatum}
							readOnly={readonly}
							disabled={readonly}
						/>
						<div className="invalid-feedback">
							Please add valid Trial Period.
						</div>
					</div>
				</div>
				<div className="col-12 col-md-6">
					<div className="mb-3">
						<label
							htmlFor="Basis"
							className="form-label"
						>Basis
						</label>
						<input
							type="text"
							className="form-control"
							id="Basis"
							name="Basis"
							placeholder="Basis"
							autoComplete="off"
							value={data.Basis}
							onChange={setFormDatum}
							readOnly={readonly}
							disabled={readonly}
						/>
					</div>
				</div>

				<div className="col-12 col-md-6">
					<div className="mb-3">
						<label
							htmlFor="TrialPeriod"
							className="form-label"
						>Trial Period
						</label>
						<input
							type="number"
							className="form-control"
							id="TrialPeriod"
							name="TrialPeriod"
							placeholder="Trial Period"
							autoComplete="off"
							value={data.TrialPeriod}
							onChange={setFormDatum}
							readOnly={readonly}
							disabled={readonly}
						/>
						<div className="invalid-feedback">
							Please add valid Trial Period.
						</div>
					</div>
				</div>
				<div className="col-12 col-md-6">
					<div className="mb-3">
						<label
							htmlFor="Notes"
							className="form-label"
						>Notes
						</label>
						<input
							type="text"
							className="form-control"
							id="Notes"
							name="Notes"
							placeholder="Notes"
							autoComplete="off"
							value={data.Notes}
							onChange={setFormDatum}
							readOnly={readonly}
							disabled={readonly}
						/>
						<div className="invalid-feedback">
							Please add valid Notes.
						</div>
					</div>
				</div>

				<div className="col-12">
					<div className="mb-3">
						<label
							htmlFor="Notes"
							className="form-label"
						>Description
						</label>
						{
							readonly ?
								<div className="content ql-editor" dangerouslySetInnerHTML={{__html: description}}/>
								:
								<ReactQuill
									theme="snow"
									value={description}
									onChange={setDescription}
									modules={constants.quillDefaultModules}
									formats={constants.quillDefaultFormats}
								/>
						}
					</div>
				</div>
			</div>
		</form>
	)
}
