import {startLoading, setCoachNotes} from "./coachNotesSlice";
import axios from "axios";
import {zynity_api} from "../../../../config/external-routes";

export const getCoachNotes = (team_id:any) => {
    return async (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        dispatch(startLoading())

        const user = state().auth
        let coach_id = user.coach.id;
        axios.defaults.headers.common = {'Authorization': `Bearer ${user.access_token}`}
        axios.get(zynity_api.coach_notes_by_team.replace('{coach_id}', coach_id).replace('{team_id}', team_id))
            .then((response) => {
                dispatch( setCoachNotes(response.data) )
            }).catch((err) => {
            console.log('Error', err);
        })
    }
}
