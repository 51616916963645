import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import {setRoute} from "../../../../../store/routes";
import {collabSetRemember, setDatumIdOpen} from "../../../../../store/user_organizations/teams/tools/connect_and_align";
import {
    setCnASearchHideResults
} from "../../../../../store/user_organizations/teams/tools/connect_and_align/cnASearchSlice";

export const SearchResultsByTool = ({user_data, user_archived_data, tool}: any) => {
    const team_obj = useSelector((state: any) => state.organization_team);
    const dispatch = useDispatch();
    const tool_cna_search = useSelector((state: any) => state.tool_cna_search)
    let filteredData:any = [];
    let cnaMemberData = user_data[tool].data;
    let cnaMemberDataArchived = user_archived_data[tool].data;

    if(tool_cna_search.search_params.include.includes('active')) {
        filteredData = [...cnaMemberData];
    }

    if(tool_cna_search.search_params.include.includes('archive')) {
        filteredData = [...filteredData, ...cnaMemberDataArchived];
    }

    const removeHtmlTags = (str:any) => {
        if (!str) return '';
        return str.replace(/<\/?[^>]+(>|$)/g, '');
    };

    const searchInComments = (comments:any, query:any) => {
        return comments.some((comment:any) => {
            const commentText = comment.comment ? removeHtmlTags(comment.comment).toLowerCase() : '';
            const nestedCommentsMatch = comment.comments ? searchInComments(comment.comments, query) : false;
            return commentText.includes(query) || nestedCommentsMatch;
        });
    };

    const searchInLinks = (links:any, query:any) => {
        return links.some((link:any) => {
            const commentText = link.links ? removeHtmlTags(link.links).toLowerCase() : '';
            return commentText.includes(query);
        });
    };

    const searchInUploads = (uploads:any, query:any) => {
        return uploads.some((upload:any) => {
            const commentText = upload.fileName ? removeHtmlTags(upload.fileName).toLowerCase() : '';
            return commentText.includes(query);
        });
    };

    //Filter Q
    if(tool_cna_search?.search_params?.q != undefined && tool_cna_search.search_params.q.trim() != '') {
        filteredData = filteredData.filter((item:any) => {
            const title = item.title ? item.title.toLowerCase() : '';
            const description = item.description ? item.description.toLowerCase() : '';
            const query = tool_cna_search?.search_params?.q.toLowerCase();

            const findInComments = tool_cna_search?.search_params?.types != undefined && tool_cna_search?.search_params?.types.includes('comments')
            const commentsMatch = item.comments && searchInComments(item.comments, query);

            const findInLinksComments = tool_cna_search?.search_params?.types != undefined && tool_cna_search?.search_params?.types.includes('links_and_documents')
            const linksMatch = item.links && searchInLinks(item.links, query);
            const uploadsMatch = item.links && searchInUploads(item.links, query);

            return title.includes(query) || description.includes(query) || (findInComments && commentsMatch) || (findInLinksComments && (linksMatch || uploadsMatch));
        });
    }

    //Filter Date Range
    if(tool_cna_search?.search_params?.date_start != undefined || tool_cna_search?.search_params?.date_end != undefined) {
        filteredData = filteredData.filter((item:any) => {
            const dateCreated = moment(item.dateCreated);
            const startDate = tool_cna_search.search_params.date_start;
            const endDate = tool_cna_search.search_params.date_end;
            return (!startDate || dateCreated.isSameOrAfter(startDate)) &&
                (!endDate || dateCreated.isSameOrBefore(endDate));
        });
    }


    if(filteredData.length < 1) {
        return (
            <div className="row">
                <div className="col-4 text-muted">
                    No results
                </div>
            </div>
        )
    }

    const navigateToTool = (datum_id:number, tab:string, member_id:any) => {
        const route = `/organizations/${team_obj.data.idOrganization}/teams/${team_obj.data.id}/CnA/${datum_id}`
        dispatch(setRoute(route))
        dispatch( setDatumIdOpen(datum_id) )

        let rememberData:any = {
            organization_id: team_obj.data.idOrganization,
            team_id: team_obj.data.id,
            view: 'member',
            user_id: parseInt(member_id),
            tab_name: tab.toLowerCase(),
            selected_item_id: datum_id,
        }

        dispatch(collabSetRemember(rememberData))
        dispatch(setCnASearchHideResults())
    }


    return filteredData.map((item:any) => (
        <>
            <div className="row clickable teal-on-hover"
                 onClick={() => navigateToTool(item.id, tool, item.idAuthor)}
            >
                <div className="col-4">
                    <div>{item.title}</div>
                </div>
                <div className="col-4">
                    <div>{item?.description != undefined ? item.description.replace(/<\/?[^>]+(>|$)/g, '') : ''}</div>
                </div>
                <div className="col-4">
                    <div><span className={'text-muted'}>Created:</span> {moment(item.dateCreated).format('DD MMM YYYY')}</div>
                </div>
            </div>
            <hr className={'text-muted mx-1 mt-0 mb-0'}/>
        </>
    ))
}
