import React from 'react'
// @ts-ignore
import {Header} from "../header/Header";
import {Link} from "react-router-dom";

export const CoachAgreement = () => {
    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-4 d-flex justify-content-between align-items-center">
                    <div className="col-12 text-end">
                        <Link to="/coach/certified-coach-profile-update"><button type="button" className="btn btn-sm btn-outline-primary">Coach Profile</button></Link>
                        <Link to="/coach"><button type="button" className="btn btn-sm btn-outline-primary mx-3">Coach Dashboard</button></Link>
                    </div>
                </div>
            </div>
            <div className={'container'}>
                <div className="row d-flex justify-content-center">
                    <div className="col-12 col-md-10 mt-3" style={{textAlign: 'center', textJustify: 'inter-word'}}>
                        <h2 className={"text-muted"}>Zynity, LLC</h2>
                        <p className="mt-5">CERTIFIED COACH PARTICIPATION AGREEMENT   ~   Dated March 24, 2022</p>
                        <p className="mt-5" style={{fontSize: '10px'}}>Version 19.05.07</p>

                        <div style={{textAlign: 'justify', textJustify: 'inter-word'}}>
                            <p>This Zynity™ Certified Coach Participation Agreement is entered between Zynity, LLC, an Arizona Limited Liability Corporation in good standing (“Company”) and a registered Certified Coach (hereinafter referred to as “Coach”).</p>

                            <ol type={'A'} style={{paddingLeft: '20px'}}>
                                <li>Company is a web-based, SaaS offering of a leadership methodology and online tools for executive leadership teams of organizations. Company is dedicated to qualifying and monitoring its Certified Coaches to ensure that all its products, services, websites and software offerings are of the highest standards and quality.</li>
                                <li>Coach desires to become a “Certified Coach” for the purpose of using and supporting users of the following products, services and/or software as a part of their normal consulting, coaching and or mentoring business: The suite of leadership tools and services known as Zynity Leadership Tools™, hereinafter referred to as “Offering”. The “Offering” may change as new items or variations or edits are added to the website and its related capabilities. Company is committed to inform Coach of changes of consequence before or as nearly as possible to release date. Coach will provide the “Offering” solely through the official Zynity, LLC website and its related service providers. Coach will provide implementation consulting, coaching and mentoring as needed via in-person, telephone and Internet connections with its clients/Zynity™ Subscribers and all its registered users. Coach will not offer another product or service to its clients that are deemed a direct competitor to the Zynity™ “Offering”. Coach is an independent contractor and will receive no fees or commissions from Zynity, LLC. The Coach is solely certified and qualified to help other organizations use the Zynity Tools™. Coach may receive fees from other organizations that are Subscribers of Company’s services for helping Subscribers better use the Company’s services. There may be more than one Certified Coach per Subscriber Organization. The Subscriber Organization specifies who they are in their profile at the www.Zynity.com website and can change that designation at any time.</li>
                                <ol type={'1'}>
                                    <li>Prior to signing this Coach Participation Agreement, Coach will provide Company with a full statement and explanation of its products or services that it normally provides to its clients. It is expected the Coach will use the Zynity Tools™ to extend and increase the sale of the services they normally provide. The Coach will use Zynity Tools™ as an additional sales tool to increase their business and to better deliver their other services.</li>
                                    <li>Coach shall also provide Company with its applicable warranty plans, replacement plans, Software licensing agreements, Quality Assurance production plan and logo and/or branding license etc. This will be used to assess if there are any potential conflicts that need clarifying before going forward.</li>
                                    <li>If Coach’s primary offering is a product, Coach must have scalable manufacturing capabilities to meet expected demand and provide a facility walk through and demonstration of its manufacturing facilities and capabilities. If a facility walk-through and demonstration is required by the Company, the Coach shall pay the necessary travel cost expenses for the visit.</li>
                                    <li>If applicable, Coach’s customer care or support site shall be visited by Company to establish that necessary customer processes and procedures are scalable and are in place. If a site visit is required, the Coach shall pay the necessary travel cost expenses for the visit.</li>
                                    <li>As a Zynity™ Certified Coach. Coaches enjoy a non-exclusive license to market your product(s) or service(s) along with the Zynity™ brand. Prior to its use, you agree to submit to the Company for approval a detailed explanation of how you plan on using the logo and Zynity™ brand accompanied by a sample of your marketing materials or use of the Zynity™ logo.</li>
                                    <li>Once Coach’s relationship has been qualified and confirmed by the Zynity™ steering committee, a press release will be prepared by Company and approved by the Coach for joint release to announce the certification and relationship.</li>
                                    <li>Coach agrees to publicly acknowledge its Offering as a Zynity™ Certified Coach, which may include placing a Zynity™ logo in their website. Coach will also place a link to www.Zynity.com on their website. In addition, it may be mutually beneficial from time to time to work together to jointly display and present at tradeshows and seminars.</li>
                                    <li>However, Zynity, LLC does not in any way certify your normal (non-Zynity™) consulting practices, processes or results in any way. Certification from Zynity, LLC is restricted to imply that you have been through the Zynity, LLC training and continuous education requirements and that you are capable of consulting, coaching and mentoring in the use of Zynity, LLC tools and services only.</li>
                                    <li>Coach will be invited to attend continuing education courses and events to become qualified to work with each individual Zynity tool™ and then stay current with new updates in the use and capabilities of the tools. Company is the sole determining entity of this being accomplished. If Company determines Coach is not current, they will send a notification via email exactly what is required for Coach to comply and how much time is available for them to regain full compliance. During this period, the Coach will be considered out of compliance and listed as “Provisional”.</li>
                                    <li>Coach will be listed in the Certified Coach Directory on the Zynity.com website. In that listing the Zynity Tools™ for which the Coach is certified will be specifically identified. This will provide integrity for clients / Subscribers and motivate Coach to stay current with the tools. An organization may be registered as a Certified Coach, but the organization will not have a listing of the Zynity Tools™ for which it is certified as an organization cannot be certified for the implementation of individual tools. Only the individuals within the organization can have a listing of the Zynity Tools™ for which they are certified.</li>
                                    <li>Coach will be given access to other Zynity™ Coaches for networking, discussion of opportunities and may apply to participate on the Certified Coach Advisory Committee.</li>
                                    <li>Once approved as a Certified Coach, Coach will be subject to a monthly participation fee of $300.00 per month, ($3,600.00 annually) or a discounted annual fee of $3,000.00 at the option of the Coach either of which would be payable to Company.</li>
                                    <li>Coach will receive no commissions from the Zynity.com services fees earned for which their clients are Subscribers.</li>
                                    <li>Coach will be able to use at their own expense sales support materials such as the books published by Dwaine Canova, brochures, flyers and other samples that may be made available in PDF form and printed from the Zynity.com website. Coach should also benefit from the public relations and marketing activities conducted by Zynity, LLC in their area and nationally.</li>
                                    <li>Coach is not given an area of any kind for its exclusive use or coverage. The Coach’s relationships must be worked out directly with each client. If there is another competing Zynity™ Certified Coach, the client will determine which one to select as their designated Certified Coach.</li>
                                    <li>Neither the Coach nor the Subscriber will be charged for the Coach’s participation with and use of the tools in conjunction with any of their clients. This presumes the client / Subscriber has designated the Coach as the Certified Coach for their organization. If the Coach is removed by the Client / Subscriber, then the Coach will no longer have access or receive reports from the Client / Subscriber.</li>
                                    <li>Coach will receive reports about the Client / Subscriber’s use of the Zynity Tools™ if they are the designated Certified Coach on the Zynity.com website.</li>
                                    <li>Ethics and Compliance</li>
                                    <ol type={'a'} className={'mt-4'}>
                                        <li>Distribution of Material
                                            <br/>
                                            <br/>
                                            You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information related to Zynity™ software and products without our prior written consent. However, you may obtain permission from us to display or reproduce material from the site by writing to the Zynity™ Certified Coach Committee.
                                        </li>
                                        <br/>
                                        <li>Restrictions of Use
                                            <br/>
                                            <br/>
                                            As a condition of your use of the Zynity, LLC services, you will not be allowed to use it for any purpose that is unlawful or prohibited by these terms, conditions, and notices. You may not use the Zynity, LLC services in any manner which could damage, disable, overburden, or impair the website and service or interfere with other party's use of Zynity Tools™. You may not attempt to gain unauthorized access to the service or access related websites, accounts, computer systems or networks connected to Zynity.com through hacking, password mining or any other means. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available through the service. Your access to Zynity.com may be terminated immediately, with or without notice from Zynity, LLC if you fail to comply with all terms and conditions stated.
                                            <br/>
                                            <br/>
                                            Software Available Through Zynity.com
                                            <br/>
                                            <br/>
                                            All software that is made available to view and/or download in connection with Zynity.com, ("Software") is owned or controlled by Company and/or its licensors, affiliates and suppliers and is protected by copyright laws, patents and international treaty provisions. Your use of the Software is governed by the terms of the Subscription Agreement, which accompanies the use of the Zynity.com website. Zynity.com accepts no responsibility or liability in connection with any Software owned or controlled by third parties.
                                            <br/>
                                            <br/>
                                            LIABILITY DISCLAIMER
                                            <br/>
                                            <br/>
                                            THE COMPANY AND AND/OR ITS RESPECTIVE LICENSORS, AFFILIATES AND SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, LACK OF VIRUSES OR OTHER HARMFUL COMPONENTS AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED WITHIN ZYNITY.COM FOR ANY PURPOSE. ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. COMPANY AND/OR ITS RESPECTIVE LICENSORS, AFFILIATES AND SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, WORKMANLIKE EFFORT, TITLE AND NON-INFRINGEMENT. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
                                            <br/>
                                            <br/>
                                            YOU SPECIFICALLY AGREE THAT THE COMPANY SHALL NOT BE RESPONSIBLE FOR UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, ANY MATERIAL OR DATA SENT OR RECEIVED OR NOT SENT OR RECEIVED, OR ANY TRANSACTIONS ENTERED INTO THROUGH ZYNITY.COM. YOU SPECIFICALLY AGREE THAT ZYNITY.COM IS NOT RESPONSIBLE FOR ANY CONTENT SENT USING AND/OR INCLUDED IN ZYNITY.COM BY ANY THIRD PARTY. YOU FURTHER AGREE THAT THE COMPANY AND/OR ITS RESPECTIVE LICENSORS, AFFILIATES AND SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES TO ZYNITY.COM AND/OR MAY DISCONTINUE ANY PART OF THE ZYNITY.COM SERVICE AT ANY TIME.
                                            <br/>
                                            <br/>
                                            IN NO EVENT SHALL THE COMPANY AND/OR ITS RESPECTIVE LICENSORS, AFFILIATES AND SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE ZYNITY.COM SERVICE, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE ZYNITY.COM SERVICE, OR OTHERWISE ARISING OUT OF THE USE OF THE ZYNITY.COM SERVICE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF THE COMPANY OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF THIS LIMITATION OF LIABILITY OR THE EXCLUSION OF WARRANTY SET FORTH ABOVE IS HELD INAPPLICABLE OR UNENFORCEABLE FOR ANY REASON, THEN COMPANY’ S MAXIMUM LIABILITY FOR ANY TYPE OF DAMAGES SHALL BE LIMITED TO YOUR PARTICIPATION FEE. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE ZYNITY.COM SERVICE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE ZYNITY.COM SERVICE AND HAVE RETURNED TO YOU THE UNUSED PRORATA PORTION OF YOUR PARTICIPATION FEE ON A PRO-RATED BASIS.
                                        </li>
                                    </ol>
                                    <br/>
                                    <li>Upon Thirty (30) days written notification, either party may cancel this Zynity™ Certified Coach agreement. In doing so, termination of this agreement does not cancel any other non-Coach agreement that may already be in place between the parties.</li>
                                    <li>A Certified Coach may be removed from Zynity certification and from the website at any time by Zynity, LLC. Zynity will investigate the issues or incident that has led to the Certified Coach being considered for decertification. Zynity will remove them as a Certified Coach from the organization and will notify the organization that decertification has occurred. Client organization has the right to continue working with the decertified individual, but the decertified individual will have access to the website and the client organization will incur a normal user expense fee.</li>
                                    <li>Reasons for decertification are not limited in any way but may be anything deemed inappropriate by the Certified Coach Review Committee. Once determined, it will be communicated to the decertified Individual.</li>
                                    <li>Company reserves the right to make changes to this Agreement as it deems necessary. Coach will be given 30-days notice prior to changes going into effect.</li>
                                    <li>This agreement represents the entire understanding of the Company and Coach and will be effective on the date this agreement is signed by both parties as indicated below.</li>
                                </ol>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
