import React, {useEffect, useState} from 'react'
import {Header} from "../../../header/Header";
import {OrganizationAdminHeader} from "../OrganizationAdminHeader";
import {useParams} from "react-router-dom";
import {zynity_api} from "../../../../config/external-routes";
import axios from "axios";
import FilterComponent from "../../../ui/FilterComponent";
import {TeamMembersColumn} from "./TeamMembersColumn";
import DataTable from "react-data-table-component";
import {TeamServicesColumn} from "./TeamServicesColumn";

export const ManageTeams = () => {
    const {organization_id} = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(15);
    const [filterText, setFilterText] = React.useState(null);

    let organization_teams_url = zynity_api.organization_teams.replace('{organization_id}', organization_id);

    const fetchTeams = async (page: any) => {
        setLoading(true);
        let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
        const response = await axios.get(`${organization_teams_url}?page=${page}&limit=${perPage}${filter}`);

        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePageChange = (page: any) => {
        fetchTeams(page);
    };

    const handlePerRowsChange = async (newPerPage: React.SetStateAction<number>, page: React.SetStateAction<number>) => {
        setLoading(true);
        let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
        const response = await axios.get(`${organization_teams_url}?page=${page}&limit=${newPerPage}${filter}`);

        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            setFilterText('');
        };

        const handleFilter = (e: { target: { value: any; }; }) => {
            setFilterText(e.target.value)
        }

        return (
            <FilterComponent onFilter={handleFilter} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText]);

    const columns = [
        {
            name: 'ID',
            selector: (row: { id: any; }) => row.id,
            maxWidth: '10px'
        },
        {
            name: 'Team Name',
            selector: (row: { TeamName: any; }) => row.TeamName,
            maxWidth: '250px'
        },
        {
            name: 'Members',
            cell: (row: any) => <TeamMembersColumn row={row} />,
            maxWidth: '250px'
        },
        {
            name: 'Subscriptions',
            cell: (row: any) => <TeamServicesColumn row={row} />,
            allowOverflow: true
        }
    ];

    useEffect(() => {
        fetchTeams(1)
    }, []);

    useEffect(() => {
        if(filterText !== null) {
            const timeOutId = setTimeout(() => fetchTeams(1), 500);
            return () => clearTimeout(timeOutId);
        }
    }, [filterText]);

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-2 mb-5">
                    <div className="col-12 col-md-9">
                        <div className="row">
                            <OrganizationAdminHeader page_name='Manage Teams' />
                        </div>
                    </div>
                    <div className="col-12">
                        <DataTable
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationPerPage={perPage}
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
