import React, {useEffect, useState} from 'react'
import {Header} from "../../../../../header/Header";
import {FacilitatorHeader} from "../../FacilitatorHeader";
import {Link} from "react-router-dom";
import {AlreadyWithSuite} from "./advanced/AlreadyWithSuite";
import {useDispatch, useSelector} from "react-redux";
import {WithoutSuite} from "./advanced/WithoutSuite";
import {BillingCycle} from "./BillingCycle";
import {zynity_api} from "../../../../../../config/external-routes";
import axios from "axios";
import Swal from "sweetalert2";
import {getOrganizationTeam} from "../../../../../../store/user_organizations/teams/team";

export const SuiteSelection = () => {
    const dispatch = useDispatch();
    const router = useSelector((state:any) => state.router);
    let organization_id = router.selected_organization_id;
    let team_id = router.selected_team_id;
    const organization_team = useSelector((state:any) => state.organization_team);
    const team = organization_team.data
    const services = team.services
    const user =  useSelector((state: any) => state.auth);

    const executive_zkus = ['Z01A', 'Z01B', 'Z01C'];
    const strategies_zkus = ['Z02A'];
    const stakeholders_zkus = ['Z03A', 'Z03B', 'Z03C'];
    const projects_zkus = ['Z05A'];
    const coach_plan_zkus = ['Z05CP'];

    let org_team_url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);

    axios.defaults.headers.common = {'Authorization': `Bearer ${user.access_token}`}

    const submitSuiteSelectionHandler = (selectedSuite:string) => {
        let payload = {
            suite: selectedSuite,
        }

        axios.post(`${org_team_url}/facilitator_dashboard/suite_selection/add_suite`, payload)
            .then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Suite Selection Updated!',
                })
                dispatch(getOrganizationTeam({organization_id , team_id}) )
            }).catch((err) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Try again.'
            })
        })
    }

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-2 mb-5">
                    <div className="col-12 col-md-9">
                        <div className="row">
                            <FacilitatorHeader page_name={'Select Team App'} />
                        </div>
                    </div>
                    <div className="col-md-3 col-12 mt-3">
                        <Link to="/facilitator-dashboard/manage-tools" className='btn btn-teal'>‹ Back</Link>
                    </div>

                    <div className="col-12 mx-4 mt-2">
                        <p>
                            The following Apps are available for your team's use.<br/>
                            Note: Only one App is used per team.  If you are new to a App always start with the Basic level.<br/>
                            Choose Monthly or Yearly billing cycle.<br/>
                            Select an App option then press the Save button.
                        </p>
                    </div>

                    {
                        services.length > 0 ?
                            <AlreadyWithSuite submitSuiteSelectionHandler={submitSuiteSelectionHandler} />
                            :
                            <WithoutSuite submitSuiteSelectionHandler={submitSuiteSelectionHandler} />
                    }

                    {
                        team.Suite != undefined && team.Suite.toLowerCase() == 'executive' &&
                        <BillingCycle skus={executive_zkus} app_name={'COLLABORATION'} />
                    }

                    {
                        team.Suite != undefined && team.Suite.toLowerCase() == 'strategies' &&
                        <BillingCycle skus={strategies_zkus} app_name={'STRATEGIES'} />
                    }

                    {
                        team.Suite != undefined && team.Suite.toLowerCase() == 'stakeholders' &&
                        <BillingCycle skus={stakeholders_zkus} app_name={'STAKEHOLDERS'} />
                    }

                    {
                        team.Suite != undefined && team.Suite.toLowerCase() == 'projects' &&
                        <BillingCycle skus={projects_zkus} app_name={'Highlights App'} />
                    }

                    {
                        team.Suite != undefined && team.Suite.toLowerCase() == 'coachplan' &&
                        <BillingCycle skus={coach_plan_zkus} app_name={'Coach Plan'} disableOptions={true} />
                    }
                </div>
            </div>
        </>
    )
}
