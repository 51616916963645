import React from 'react'
import {useSelector} from "react-redux";
import {SearchResultsByTool} from "./SearchResultsByTool";

export const SearchResultsByUser = ({member}:any) => {
    const tool_cna_search = useSelector((state: any) => state.tool_cna_search)
    const team_obj = useSelector((state: any) => state.organization_team);
    const cna = useSelector((state: any) => state.tool_cna)
    let cna_data = cna.data;
    let cna_archived_data = cna.archived_data;

    let user_data = cna_data.find((data:any) => data.user_id == member.id)
    let user_archived_data = cna_archived_data.find((data:any) => data.user_id == member.id)


    const getAuthorInitials = (people_id: number) => {
        let author = team_obj?.data?.members?.find((member: { id: number; }) => (member.id === people_id))
        if(author === undefined) {
            return '--';
        }

        if(author.FirstName == '' || author.FirstName == undefined) {
            return author.EmailAddress.substring(0, 2).toUpperCase()
        }

        return `${author.FirstName.substring(0, 1)}${author.LastName.substring(0, 1)}`;
    }


    return (
        <div className="col-12">
            <div className='d-flex align-items-center mb-1'>
                <div className="rounded-circle" style={{backgroundColor: 'teal', padding: '5px', color: 'white', display: 'inline', textAlign: 'center', fontSize: '12px', fontFamily: 'sans-serif'}}
                >
                    {getAuthorInitials(member.id)}
                </div>

                <div className='mx-1'>{member.FirstName}</div>
            </div>

            {
                (tool_cna_search.search_params.types.includes('all') || tool_cna_search.search_params.types.includes('tomis')) &&
                <>
                    <h6 style={{textDecoration: "underline"}} className={'mt-2'}>ToMIs™</h6>
                    <SearchResultsByTool user_data={user_data} user_archived_data={user_archived_data} tool={'tomis'} />
                </>
            }

            {
                (tool_cna_search.search_params.types.includes('all') || tool_cna_search.search_params.types.includes('deliverables')) &&
                <>
                    <h6 style={{textDecoration: "underline"}} className={'mt-2'}>Deliverables</h6>
                    <SearchResultsByTool user_data={user_data} user_archived_data={user_archived_data} tool={'deliverables'} />
                </>
            }

            {
                (tool_cna_search.search_params.types.includes('all') || tool_cna_search.search_params.types.includes('activities')) &&
                <>
                    <h6 style={{textDecoration: "underline"}} className={'mt-2'}>Activities</h6>
                    <SearchResultsByTool user_data={user_data} user_archived_data={user_archived_data} tool={'activities'} />
                </>
            }

            {
                (tool_cna_search.search_params.types.includes('all') || tool_cna_search.search_params.types.includes('messages')) &&
                <>
                    <h6 style={{textDecoration: "underline"}} className={'mt-2'}>Messages</h6>
                    <SearchResultsByTool user_data={user_data} user_archived_data={user_archived_data} tool={'messages'} />
                </>
            }
            <hr/>
        </div>
    )
}
