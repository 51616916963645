import React, {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import {Header} from "../../../header/Header";
import {OrganizationAdminHeader} from "../OrganizationAdminHeader";
import {useParams} from "react-router-dom";
import {zynity_api, zynity_website} from "../../../../config/external-routes";
import axios from "axios";
import FilterComponent from "../../../ui/FilterComponent";
import {TeamMembersColumn} from "../teams/TeamMembersColumn";
import {TeamServicesColumn} from "../teams/TeamServicesColumn";
import DataTable from "react-data-table-component";
import {Button, Modal} from "react-bootstrap";
import {useForm} from "../../../hooks/useForm";

export const ManageAdministrators = () => {
    const {organization_id} = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(15);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterText, setFilterText] = React.useState(null);

    const [showAddAdminModal, setShowAddAdminModal] = useState(false);
    const [newAdminForm, setNewAdminDatumForm,setNewAdminForm] = useForm({email: ''});

    let organization_administrators_url = zynity_api.organization_administrators.replace('{organization_id}', organization_id);

    const fetchAdministrators = async (page: any) => {
        setLoading(true);
        setCurrentPage(page);
        let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
        const response = await axios.get(`${organization_administrators_url}?page=${page}&limit=${perPage}${filter}`);

        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePageChange = (page: any) => {
        fetchAdministrators(page);
    };

    const handlePerRowsChange = async (newPerPage: React.SetStateAction<number>, page: React.SetStateAction<number>) => {
        setLoading(true);
        setCurrentPage(page);
        let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
        const response = await axios.get(`${organization_administrators_url}?page=${page}&limit=${newPerPage}${filter}`);

        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            setFilterText('');
        };

        const handleFilter = (e: { target: { value: any; }; }) => {
            setFilterText(e.target.value)
        }

        return (
            <FilterComponent onFilter={handleFilter} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText]);

    const columns = [
        {
            name: 'ID',
            selector: (row: { id: any; }) => row.id,
            maxWidth: '10px'
        },
        {
            name: 'Team Name',
            selector: (row: { FirstName: any, LastName:any }) => `${row.FirstName} ${row.LastName}`,
            maxWidth: '250px'
        },
        {
            name: 'Email',
            selector: (row: { EmailAddress: any; }) => row.EmailAddress,
            maxWidth: '250px'
        },
        {
            name: 'Action',
            cell: (row: any) => <button type="button" className="btn btn-teal btn-sm"
                                        onClick={() => removeOrgAdmin(row.idPerson, `${row.FirstName} ${row.LastName}`)}>Remove</button>
        }
    ];

    const removeOrgAdmin = (admin_id: any, admin_name: any) =>
    {
        console.log("going to delete: ",admin_id )
        Swal.fire({
            title: `Confirm Removing ${admin_name}`,
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Deleted!', '', 'success')
                const response = axios.delete(`${organization_administrators_url}/${admin_id}`);
                response.then((success) => {
                    fetchAdministrators(currentPage)
                });
            }
        })
    }

    useEffect(() => {
        fetchAdministrators(1)
    }, []);

    useEffect(() => {
        if(filterText !== null) {
            const timeOutId = setTimeout(() => fetchAdministrators(1), 500);
            return () => clearTimeout(timeOutId);
        }
    }, [filterText]);

    console.log(data);

    const handleClose = () => setShowAddAdminModal(false);

    const addAdminSubmit = () => {
        if(newAdminForm.email == '') {
            Swal.fire('Invalid Email!', '', 'error')
            return false;
        }

        document.getElementById(`btnAddAdminSubmit`).innerHTML = "Adding Administrator...";
        const response = axios.post(`${organization_administrators_url}`, newAdminForm);
        response.then((success) => {
            Swal.fire("New Administrator Added!", '', 'success')
            fetchAdministrators(currentPage)
            setShowAddAdminModal(false)
        }).catch((err) => {
            if(err.response?.data.error !== undefined) {
                Swal.fire(err.response.data?.error, '', 'error')
                document.getElementById(`btnAddAdminSubmit`).innerHTML = "Add Administrator";
            } else {
                Swal.fire('Internal error occurs, please try again', '', 'error')
            }
        });
    }

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-2 mb-5">
                    <div className="col-12 col-md-9">
                        <div className="row">
                            <OrganizationAdminHeader page_name='Manage Administrators' />
                        </div>
                    </div>

                    <div className="col-12 col-md-3 align-self-center text-end">
                        <button type="button" className="btn btn-primary" onClick={() => setShowAddAdminModal(true)}>Add Administrator</button>

                        <Modal show={showAddAdminModal} onHide={handleClose} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>Add Administrator</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="text-muted">Enter the email address of the person you would like to add as an Organization Administrator.
                                    An email notification will be sent to the email you enter (you are copied).
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-6 data-table-container">
                                        <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                        <input type="email" name="email" className="form-control" id="exampleInputEmail1" value={newAdminForm.email} onChange={setNewAdminDatumForm} />
                                    </div>
                                </div>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={addAdminSubmit} id="btnAddAdminSubmit">
                                        Add Administrator
                                    </Button>
                                </Modal.Footer>
                            </Modal.Body>
                        </Modal>
                    </div>

                    <div className="col-12">
                        <DataTable
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationPerPage={perPage}
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
