import React, {useState} from 'react'
import {Header} from "../../../header/Header";
import {useDispatch, useSelector} from "react-redux";
import {useParams, useNavigate, Link} from "react-router-dom";
import 'animate.css';
import {zynity_api, zynity_website} from "../../../../config/external-routes";
import { Button, Modal } from 'react-bootstrap';
import {MemberAccordion} from "./members/MemberAccordion";
import {MembersContainer} from "./members/MembersContainer";
import {getOrganizationTeam} from "../../../../store/user_organizations/teams/team";
import {setRouteSelectedOrgId, setRouteSelectedTeamId} from "../../../../store/routes";
import {Loading} from "../../../ui/loading/Loading";
import {
    getCnAData,
    setData,
    setDatumIdOpen,
    setDatumIdOpenData
} from "../../../../store/user_organizations/teams/tools/connect_and_align";

export const TeamHeader = ({suffixRoute = ''}: any) => {
    const dispatch = useDispatch();
    const yii_url = zynity_website.yii_website_url;
    const {organization_id, team_id} = useParams();
    const [showMaintrax, setShowMaintrax] = useState(false);
    const user_organizations_obj = useSelector((state:any) => state.user_organizations);
    const user_organizations = user_organizations_obj.data?.data
    let organization = user_organizations.filter((item:any) => item.id == organization_id)[0];
    let organization_teams = organization.teams;
    let selected_team = organization_teams.filter((team:any) => team.id == team_id)[0];
    const navigate = useNavigate();
    const [showParticipantsModal, setShowParticipantsModal] = useState(false);
    const organization_team = useSelector((state:any) => state.organization_team);

    if(organization_team.isLoading === true) {
        return <Loading />
    }

    let team_services = organization_team.data.services[0];

    const goToYiiTools = (org_id:any, team_id:any) => {
        const form = document.createElement('form');
        form.method = 'post';
        form.action = `${zynity_website.yii_website_url}/userHome/index`;
        const orgIdInput = document.createElement('input');
        orgIdInput.type = 'hidden';
        orgIdInput.name = 'orgId';
        orgIdInput.value = String(org_id);

        const teamIdInput = document.createElement('input');
        teamIdInput.type = 'hidden';
        teamIdInput.name = 'teamId';
        teamIdInput.value = String(team_id);

        const teamActionInput = document.createElement('input');
        teamActionInput.type = 'hidden';
        teamActionInput.name = 'teamAction';
        teamActionInput.value = String("goToTools");

        form.appendChild(orgIdInput);
        form.appendChild(teamIdInput);
        form.appendChild(teamActionInput);
        document.body.appendChild(form);
        form.submit();
        return false
    }

    const handleChangeOrganization = async ({target}:any) => {
        let new_org_id = target.value;
        let organization = user_organizations.filter((item:any) => item.id == new_org_id)[0];

        let first_team = organization.teams[0];
        let new_team_id = first_team.id;

        //Suites available for React Version
        if(first_team.Suite == 'Executive') {
            console.log("Changed?");
            dispatch(setDatumIdOpen(0));
            dispatch(setDatumIdOpenData(undefined));
            navigate(`/organizations/${new_org_id}/teams/${new_team_id}${suffixRoute}`)
            return false
        }

        //Suites available only in Yii version
        return goToYiiTools(new_org_id, new_team_id)
    }

    const handleChangeOrgTeam = ({target}:any) => {
        let new_team_id = target.value;
        let selected_team = organization_teams.find((team:any) => team.id == new_team_id)

        if(selected_team.Suite == 'Executive') {
            dispatch(setDatumIdOpen(0));
            dispatch(setDatumIdOpenData(undefined));
            dispatch( getOrganizationTeam({organization_id, team_id: new_team_id}) );
            dispatch(getCnAData({organization_id, team_id: new_team_id}))
            navigate(`/organizations/${organization_id}/teams/${new_team_id}/CnA`, {replace: true})
        } else {
            //Suites available only in Yii version
            return goToYiiTools(organization_id, new_team_id)
        }
    }

    const toogleMaintrax = () => {
        if(showMaintrax === true) {
            setShowMaintrax(false);
        } else {
            setShowMaintrax(true);
        }
    }

    const openParticipantsModal = () => {
        setShowParticipantsModal(true)
    }

    const closeParticipantsModal = () => {
        setShowParticipantsModal(false)
    };

    const handleClickFacilitatorLink = (selectedTeamId: string) => {
        const form = document.getElementById(`facilitatorForm${selectedTeamId}`) as HTMLFormElement;
        if (form) {
            form.submit();
        }
    };

    const goToFacilitatorDashboardHandled = (org_id:string, team_id:string) => {
        dispatch(getOrganizationTeam({organization_id:org_id , team_id}) );
        dispatch(setRouteSelectedOrgId(org_id))
        dispatch(setRouteSelectedTeamId(team_id))
        navigate('/facilitator-dashboard')
    }

    return (
        <>
            <Header />
            <div className="row">
                <div className="col-12">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-center">
                        <ul className="navbar-nav d-flex align-items-center">
                            {
                                organization.image != null &&
                                <li className="nav-item mx-2 mt-2 mt-lg-0">
                                    <img style={{width: '120px', maxWidth: '120px', padding: 0}} src={zynity_api.download_file + organization.image} alt="Organization Image"/>
                                </li>
                            }
                            <li className="nav-item mx-2 mt-2 mt-lg-0">
                                <select className="form-select" style={{fontWeight: 'bold'}} aria-label="Default select example"
                                value={organization_id}
                                        onChange={handleChangeOrganization}
                                >
                                    {
                                        user_organizations.map((organization:any) => (
                                            <option key={organization.id} value={organization.id}>{organization.name}</option>
                                        ))
                                    }
                                </select>
                            </li>
                            <li className="nav-item mx-2 mt-2 mt-lg-0">
                                <select className="form-select" style={{fontWeight: 'bold'}} aria-label="Default select example"
                                        value={team_id}
                                        onChange={handleChangeOrgTeam}
                                >
                                    {
                                        organization_teams.map((team:any) => (
                                            <option key={team.id} value={team.id}>{team.TeamName}</option>
                                        ))
                                    }
                                </select>
                            </li>
                            <li className="nav-item mx-2 mt-2 mt-lg-0">
                                <button type="button" className="btn btn-teal btn-sm rounded-pill"
                                        onClick={openParticipantsModal}
                                >Participants <span
                                    className="badge bg-light text-dark">{selected_team.total_members}</span></button>

                                <Modal show={showParticipantsModal} onHide={closeParticipantsModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className='text-muted fs-5'>Participants - Executive Team Team</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <MembersContainer closeModal={closeParticipantsModal} />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={closeParticipantsModal}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                            </li>
                            <li className="nav-item mx-2 mt-2 mt-lg-0">
                                <button type="button" className="btn btn-teal btn-sm rounded-pill" onClick={toogleMaintrax}>Main Trax</button>
                            </li>

                            {
                                selected_team.is_facilitator &&
                                <li className="nav-item mx-2 mt-2 mt-lg-0">
                                    <img
                                        onClick={() => goToFacilitatorDashboardHandled(organization_id, team_id)}
                                        data-toggle="tooltip"
                                        style={{width: '40px'}}
                                        className="team-icons clickable"
                                        src={require('../../../../assets/images/FacilitatorIcon.png')}
                                        alt="Facilitator Icon"
                                    />
                                </li>
                            }

                            {
                                selected_team.is_coach &&
                                <Link to="/coach">
                                    <img src={require('../../../../assets/images/coach_notes_icon.png')} width="40"></img>
                                </Link>
                            }

                        </ul>
                    </nav>
                </div>
                {
                    showMaintrax === true &&
                    <div className="col-12">
                        <div className="row justify-content-md-center">
                            <div className="col-4">
                                <div className="row justify-content-md-center">
                                    <div className="col-12">
                                        <div className='animate__animated animate__bounceIn text-center' style={{padding: '0 15px', marginBottom: '10px', borderRadius: '12px', backgroundColor: 'white'}}
                                        >Purpose: <div dangerouslySetInnerHTML={{__html: selected_team.Purpose}}/></div>
                                    </div>

                                    <div className="col-12 d-flex justify-content-around">
                                        {
                                            (team_services.SKU == 'Z01B' || team_services.SKU == 'Z01C') &&
                                            <>
                                                {
                                                    selected_team.CoreValues &&
                                                    <div className='animate__animated animate__bounceIn text-center' style={{padding: '0 15px', marginBottom: '10px', borderRadius: '12px'}}
                                                    >Core Values: <div dangerouslySetInnerHTML={{__html: selected_team.CoreValues}}/></div>
                                                }
                                            </>
                                        }

                                        {
                                            /*team_services.SKU == 'Z01C' &&
                                            <>
                                                {
                                                    selected_team.FutureIssues &&
                                                    <div className='animate__animated animate__bounceIn text-center' style={{padding: '0 15px', marginBottom: '10px', borderRadius: '12px'}}
                                                    >Core Values: <div dangerouslySetInnerHTML={{__html: selected_team.FutureIssues}}/></div>
                                                }
                                            </>*/
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
