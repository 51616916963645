import React, {useState, useEffect} from 'react'
import {Header} from "../../../header/Header";
import {OrganizationAdminHeader} from "../OrganizationAdminHeader";
import axios from "axios";
import {zynity_api} from "../../../../config/external-routes";
import FilterComponent from "../../../ui/FilterComponent";
import DataTable from "react-data-table-component";
import {useParams} from "react-router-dom";
import {MemberTeamsColumn} from "./MemberTeamsColumn";
import {MemberExtraDataColumn} from "./MemberExtraDataColumn";

export const ManageMembers = () => {
    const {organization_id} = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(15);
    const [filterText, setFilterText] = React.useState(null);

    let organization_member_url = zynity_api.organization_members.replace('{organization_id}', organization_id)

    const fetchMembers = async (page: any) => {
        setLoading(true);
        setCurrentPage(page);
        let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
        const response = await axios.get(`${organization_member_url}?page=${page}&limit=${perPage}${filter}`);

        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePageChange = (page: any) => {
        fetchMembers(page);
    };

    const handlePerRowsChange = async (newPerPage: React.SetStateAction<number>, page: React.SetStateAction<number>) => {
        setLoading(true);
        setCurrentPage(page);
        let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
        const response = await axios.get(`${organization_member_url}?page=${page}&limit=${newPerPage}${filter}`);

        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            setFilterText('');
        };

        const handleFilter = (e: { target: { value: any; }; }) => {
            setFilterText(e.target.value)
        }

        return (
            <FilterComponent onFilter={handleFilter} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText]);

    const columns = [
        {
            name: 'ID',
            selector: (row: { id: any; }) => (
                <a>{row.id}</a>
            ),
            maxWidth: '10px'
        },
        {
            name: 'Name',
            selector: (row: { FirstName: any; LastName: any; }) => `${row.FirstName} ${row.LastName}`,
            maxWidth: '300px'
        },
        {
            name: 'Email',
            selector: (row: { EmailAddress: any; }) => row.EmailAddress,
            maxWidth: '300px'
        },
        {
            name: 'Team(s)',
            cell: (row: any) => <MemberTeamsColumn row={row} />,
            allowOverflow: true
        },
        {
            name: 'Extra Data',
            cell: (row: any) => <MemberExtraDataColumn row={row} />,
            allowOverflow: true
        }
    ];

    useEffect(() => {
        fetchMembers(1)
    }, []);

    useEffect(() => {
        if(filterText !== null) {
            const timeOutId = setTimeout(() => fetchMembers(1), 500);
            return () => clearTimeout(timeOutId);
        }
    }, [filterText]);

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-2 mb-5">
                    <div className="col-12 col-md-9">
                        <div className="row">
                            <OrganizationAdminHeader page_name='Manage Members' />
                        </div>
                    </div>

                    <div className="col-12">
                        <DataTable
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationPerPage={perPage}
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
