import React from 'react'
import {Document, Page, Text, View, StyleSheet, Image, Svg, Rect} from '@react-pdf/renderer';
import moment from "moment";

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        justifyContent: 'center'
    },
    section: {
        margin: 10,
        marginTop: 0,
        marginBottom: 0,
        padding: 10,
        paddingTop: 10,
        paddingBottom: 10,
        flexGrow: 1,
        textAlign: "center",
        fontSize: "14px"
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        marginLeft: 0,
        color: 'teal',
        textAlign: 'left'
    },
    subtitle2: {
        fontSize: 14,
        marginBottom: '5px',
        textAlign: 'left'
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    }
});

export const TeamAssessmentPdf = ({data, formData, totalScore}: any) => {
    return (
        <Document>
            <Page size="A4" style={{...styles.page, marginTop: 0, padding: 20}}>
                <View style={styles.section}>
                    <View style={{...styles.page, marginTop: '-15px'}}>
                        <Image style={{width: '100px'}} src={require('../../../assets/images/zynity_logo.png')} />
                    </View>

                    <View style={{...styles.page, marginTop: '-7px'}}>
                        <View style={styles.section}>
                            <Text>{data.assessment.title}</Text>
                        </View>
                    </View>
                    <View style={{...styles.page, marginTop: '-5px', marginBottom: '-10px'}}>
                        <View style={styles.section}>
                            <Text>{data.user_name}</Text>
                        </View>
                        <View style={{...styles.section, textAlign: 'right'}}>
                            <Text style={{textAlign: 'right'}}>{moment().format('MMMM DD, YYYY')}</Text>
                        </View>
                    </View>
                    <View style={styles.page}>
                        <View style={styles.section}>
                            <Text style={{color: 'teal', textAlign: 'left'}}>{data.assessment.subtitle}</Text>
                            <Text style={{color: '#414141', fontSize: '10px', marginTop: '5px'}}>{data.assessment.instructions}</Text>
                        </View>
                    </View>

                    <View style={{borderWidth: "2px", borderColor: '#737373', borderStyle: "solid", marginTop: '5px', marginBottom: '20px'}}>
                        <View style={styles.page}>
                            <View style={styles.section}>
                                <Text style={styles.subtitle}>{data.assessment.assessment_title}</Text>
                            </View>
                        </View>

                        {
                            data.assessment.data.data.map((row: any) => (
                                <>
                                    {
                                        row.type == 'range' ?
                                            (
                                                <View  style={{...styles.page, marginBottom: 0, marginTop: '-17px'}}>
                                                    <View style={{...styles.section, marginBottom: 0, marginTop: 0}}>
                                                        <Text style={{...styles.subtitle2, marginBottom: '3px', marginTop: 0, fontSize: 12, marginRight: '50px'}}>{row.field.replace('reply', '')}.- {row.label}</Text>
                                                        <Svg viewBox="0 0 100 3" style={{width: "100%"}}>
                                                            <Rect
                                                                width="100"
                                                                height="1"
                                                                fill="#d8d8d8"
                                                                x={0} y={0}
                                                            />
                                                            <Rect
                                                                width={(formData[row.field]*100) / 5}
                                                                height="1"
                                                                fill="#1875E3"
                                                                x={0} y={0}
                                                            />
                                                        </Svg>
                                                    </View>
                                                    <View style={{...styles.section, textAlign: 'center', marginTop: 0}}>
                                                        <Text style={{ borderRadius: '50%', textAlign: 'center', color: '#1875E3', fontWeight: 'bold'}}>{formData[row.field]} Pts.</Text>
                                                    </View>
                                                </View>
                                            ) :
                                            (
                                                <>
                                                    <View style={{...styles.page, marginBottom: 0}}>
                                                        <View style={{...styles.section, marginBottom: 0}}>
                                                            <Text style={{...styles.subtitle2, margin: 0}}>Comments</Text>
                                                        </View>
                                                    </View>
                                                    <View style={{borderWidth: "2px", borderColor: '#ababab', borderStyle: "solid", marginTop: 0, marginLeft: '20px', marginRight: '20px'}}>
                                                        <Text style={styles.text}>{formData[row.field]}</Text>
                                                    </View>
                                                </>
                                            )
                                    }
                                </>
                            ))
                        }
                        <View style={styles.page}>
                            <View style={{...styles.section, marginTop: 0, marginBottom: 0}}>
                                <Text style={{...styles.subtitle, fontWeight: 900}}>TOTAL SCORE: {totalScore}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{...styles.page}}>
                        <View style={{...styles.section, marginTop: '-15px', marginBottom: '-15px'}}>
                            <Text style={{fontSize: '10px'}}>Questionnaire © Copyright 2018-{moment().year()} by Dwaine Canova All Rights Reserved</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}
