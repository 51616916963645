import React from 'react'
import {Header} from "../../header/Header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {OrganizationAdminHeader} from "./OrganizationAdminHeader";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

export const AdminHome = () => {
    const {organization_id} = useParams();
    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-2 mb-5">
                    <div className="col-12 col-md-9">
                        <div className="row">
                            <OrganizationAdminHeader page_name='Administrator' />

                            <div className="col-12">
                                <Link to={`/organizations/${organization_id}`}><button type="button" className="btn btn-teal">Administrator Home</button></Link>
                                <p className='mt-2' style={{fontSize: '17px', fontFamily: 'Open Sans, sans-serif', lineHeight: '1.8em'}}>The Organization Administrator has the duty to oversee and assist team members in your organization as they experience using the tools and features at Zynity.com. This page, which only you and other Administrators (which you assign) have access to, allows you to perform the following functions and have a clear view of all the teams and team members active within the Zynity system.</p>
                            </div>

                            <div className="col-12">
                                <Link to={`/organizations/${organization_id}/profile`}><button type="button" className="btn btn-teal">Organization Profile</button></Link>
                                <p className='mt-2' style={{fontSize: '17px', fontFamily: 'Open Sans, sans-serif', lineHeight: '1.8em'}}>This profile contains the information about your organization. The email address is of particular importance as it is our primary channel for communicating with you. Your monthly billing will also be sent to the email address listed here.</p>
                            </div>

                            <div className="col-12">
                                <Link to={`/organizations/${organization_id}/members`}><button type="button" className="btn btn-teal">Manage Members</button></Link>
                                <p className='mt-2' style={{fontSize: '17px', fontFamily: 'Open Sans, sans-serif', lineHeight: '1.8em'}}>This is a listing of all individuals in your organization and other invited guests engaged with using the Zynity system. Information in this report is entered and edited in other places in the system. Changes to an individuals information or participation can not be edited in this report. Information for individuals is made in their profile and their participation information is entered and adjusted at the team level.</p>
                            </div>

                            <div className="col-12">
                                <Link to={`/organizations/${organization_id}/teams`}><button type="button" className="btn btn-teal">Manage Teams</button></Link>
                                <p className='mt-2' style={{fontSize: '17px', fontFamily: 'Open Sans, sans-serif', lineHeight: '1.8em'}}>In this report you can view all teams created within your organization. It lists the team members and the selected tool they are using. Teams are not created here. Teams can be created by any user from their personal Home Page. The person who creates a team becomes the Team Facilitator and can designate others as a Facilitator. Team Facilitators can add members to the team and select the tool for teams they manage. Your organization will pay monthly fees for any teams created by its members. Teams are removed by the Facilitator.</p>
                            </div>

                            <div className="col-12">
                                <Link to={`/organizations/${organization_id}/coaches`}><button type="button" className="btn btn-teal">Assign Coaches</button></Link>
                                <p className='mt-2' style={{fontSize: '17px', fontFamily: 'Open Sans, sans-serif', lineHeight: '1.8em'}}>Certified Coaches are trained in the Zynity Leadership methodology and application of online tools. They provide helpful guidance to get started and make the most of the Zynity experience.
                                    Coaches are assigned to individual Teams by the Team Facilitator. The Coach must first be accepted by the organization Administrator to be available. That is done here. Add or remove Coaches to your Org using this application.</p>
                            </div>

                            <div className="col-12">
                                <button type="button" className="btn btn-teal">Review Invoices</button>
                                <p className='mt-2' style={{fontSize: '17px', fontFamily: 'Open Sans, sans-serif', lineHeight: '1.8em'}}>Invoices are generated each month 10 days prior to your sign up anniversary date. Payment of the invoice will be automatically processed 10 days after the invoice has been created. You will receive a copy of the invoice at the email listed for the Oganization Profile. You must complete the authorization for monthly payment by clicking on the following link.</p>
                            </div>

                            <div className="col-12">
                                <Link to={`/organizations/${organization_id}/administrators`}><button type="button" className="btn btn-teal">Assign Administrators</button></Link>
                                <p className='mt-2' style={{fontSize: '17px', fontFamily: 'Open Sans, sans-serif', lineHeight: '1.8em'}}>You can assign additional Organization Administrators who will be granted access to this page and be able to perform all of the related functions.</p>
                            </div>

                            <div className="col-12">
                                <Link to={`/organizations/${organization_id}/remove`}><button type="button" className="btn btn-teal">Remove Organization</button></Link>
                                <p className='mt-2' style={{fontSize: '17px', fontFamily: 'Open Sans, sans-serif', lineHeight: '1.8em'}}>Organizations are removed by request sent to the Zynity Webmaster. Use this function to send the request. You will be copied on the email.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 mt-2">
                        <div className="card">
                            <div className="card-header text-center" style={{backgroundColor: 'teal', color: 'white', fontWeight: 'bold'}}>
                                Organization Menu
                            </div>
                            <ul className="list-group list-group-flush">
                                <Link to={`/organizations/${organization_id}`} className="list-group-item list-group-item-action">Administrator Home</Link>
                                <Link to={`/organizations/${organization_id}/profile`} className="list-group-item list-group-item-action">Organization Profile</Link>
                                <Link to={`/organizations/${organization_id}/members`} className="list-group-item list-group-item-action">Manage Members</Link>
                                <Link to={`/organizations/${organization_id}/teams`} className="list-group-item list-group-item-action">Manage Teams</Link>
                                <Link to={`/organizations/${organization_id}/coaches`} className="list-group-item list-group-item-action">Assign Coaches</Link>
                                <a href="#" className="list-group-item list-group-item-action">Review Invoices</a>
                                <Link to={`/organizations/${organization_id}/administrators`} className="list-group-item list-group-item-action">Assign Administrators</Link>
                                <Link to={`/organizations/${organization_id}/remove`} className="list-group-item list-group-item-action">Remove Organization</Link>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

