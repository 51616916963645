import React from 'react'

export const AssessmentForm = ({onChange, data, values}: any) => {
    return (
        <>
            <div className="row">
                {
                    data.map((row: any) => (
                        <div className="col-12">
                            <label htmlFor="customRange1" className="form-label">{row.field.replace('reply', '')}.- {row.label}</label>
                            {
                                row.type == 'range' ?
                                    (
                                        <div className='d-flex align-items-center'>
                                            <input min={0} max={5} name={row.field} type="range" onChange={onChange}
                                                   style={{width: '95%'}} value={values[row.field]}/>
                                            <span
                                                className="badge rounded-pill bg-primary badge-total-notifications mx-2"
                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                title="New Content">{values[row.field]}</span>
                                        </div>
                                    )
                                    :
                                    (
                                        <div className="form-floating">
                                            <textarea name={row.field} className="form-control" placeholder="Leave a comment here"
                                                      onChange={onChange}
                                                      id="floatingTextarea2" style={{height: '100px'}}
                                                      value={values[row.field]}/>
                                            <label htmlFor="floatingTextarea2">{row.placeholder}</label>
                                        </div>
                                    )

                            }
                        </div>
                    ))
                }
            </div>
        </>
    )
}
