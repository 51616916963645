import React from 'react'
import ReactQuill from "react-quill";
// @ts-ignore
import {constants} from "../../../../config/constants";

export const TextAreaInput = ({key_name, value, setFormDatum, placeholder = ''}: any) => {

    const handleChange = (html: any, delta:any, source:any) => {
        if(source != 'api') {
            setFormDatum({
                target: {
                    name: key_name,
                    value: html
                }
            })
        }
    }

    return (
        <>
            <ReactQuill
                key={key_name}
                theme="snow"
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                modules={constants.quillDefaultModules}
                formats={constants.quillDefaultFormats}
            />
        </>
    )
}
