import React, {useState} from 'react'
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {setPastDate} from "../../../../../store/user_organizations/teams/tools/connect_and_align";

export const MonthSelector = () => {
    const dispatch = useDispatch();
    const cna = useSelector((state:any) => state.tool_cna)
    const selectedMonth = cna.pastDate
    // Create an array with the months of the last 2 years
    const currentDate = moment();
    const currentYear = currentDate.year();
    const currentMonth = currentDate.month() + 1; // January is 0
    let months = [];

    for (let year = currentYear - 1; year <= currentYear; year++) {
        for (let month = 1; month <= 12; month++) {
            if (year === currentYear && month > currentMonth) {
                break;
            }
            const label = moment({ year, month: month - 1 }).format("MMMM - YYYY");
            const value = moment({ year, month: month - 1 }).endOf("month").format("YYYY-MM-DD");
            months.push({ value: value, label });
        }
    }

    months = months.slice().reverse()

    const handleChange = (event:any) => {
        // setSelectedMonth(event.target.value);
        dispatch(setPastDate(event.target.value));
    };

    return (
        <li className="nav-item mx-2 mt-2 mt-lg-0">
            <select id="months" className="form-select" value={selectedMonth} onChange={handleChange}>
                {months.map((month) => (
                    <option key={month.value} value={month.value}>
                        {month.label}
                    </option>
                ))}
            </select>
        </li>
    )
}
