import React, {useEffect} from 'react'
import {setRoute} from "../../../../store/routes";
import {useLocation, useParams} from "react-router-dom";
import {constants} from "../../../../config/constants";
import {useDispatch, useSelector} from "react-redux";
import {TeamHeader} from "../header/TeamHeader";
import {CnAContainer} from "./CnAContainer";
import {getCnAData, setDatumIdOpen} from "../../../../store/user_organizations/teams/tools/connect_and_align";
import {getOrganizationTeam} from "../../../../store/user_organizations/teams/team";
import {Loading} from "../../../ui/loading/Loading";
import {zynity_website} from "../../../../config/external-routes";
// @ts-ignore
import Cookies from 'js-cookie'

export const CnA = () => {
    let access_token = Cookies.get(constants.zynity_api_access_token_cookie_name);
    if(access_token == undefined) {
        window.location.replace(zynity_website.yii_website_logout_url);
    }
    document.title = `Collaborate & Align | ${constants.website_title}`
    let {organization_id, team_id, datum_id} = useParams();

    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const cna = useSelector((state:any) => state.tool_cna)
    const organization_team = useSelector((state:any) => state.organization_team);

    useEffect(() => {
        dispatch( getOrganizationTeam({organization_id, team_id}) );
        dispatch(getCnAData({organization_id, team_id}))
        if(datum_id != undefined) {
            dispatch( setDatumIdOpen(datum_id) );
        }
        urlHandled()
    }, [cna.pastDate, organization_id])

    const urlHandled = () => {
        dispatch(setRoute(pathname))
        let org_team_id = `${organization_id}_${team_id}`;
        window.history.replaceState(null, "CnA", "/collab?i=" + btoa(org_team_id))
    }

    if(organization_team.isLoading === true) {
        return <Loading />
    }

    return (
        <>
            <TeamHeader suffixRoute='/collab' />
            <CnAContainer />
        </>
    )
}
